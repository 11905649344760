import styled from "styled-components";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import NexaTypography from "../../../../shared/NexaTypography";

interface Props {
  onSelect(suggestion: AutocompleteAirport): void;
  suggestions: AutocompleteAirport[];
}

const Suggestions = ({ onSelect, suggestions }: Props) => {
  return (
    <Container>
      {suggestions.map((suggestion, index) => (
        <Suggestion key={index} onClick={() => onSelect(suggestion)}>
          <Title>{suggestion.name}</Title>
          <NexaTypography>{suggestion.region}</NexaTypography>
        </Suggestion>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow: auto;
`;

const Suggestion = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 10px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid;
  }
`;

const Title = styled(NexaTypography)`
  font-family: ${({ theme }) => theme.heavyFont};
`;

export default Suggestions;
