const Checkmark = ({ stroke = "#11100d" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.73" height="9.061" viewBox="0 0 11.73 9.061">
      <path
        id="Stroke_5_Copy"
        data-name="Stroke 5 Copy"
        d="M10.5-.471l-7,8-4-4"
        transform="translate(0.85 0.8)"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Checkmark;
