import { FormikErrors, FormikTouched } from "formik";
import { forwardRef, lazy, Suspense, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesType as FlightType,
} from "../../../../types/FlightsSearchFormValues";
import useMedia from "../../../../hooks/useMedia";
import { AutocompleteAirport } from "../../../../types/Autocomplete";
import { AutocompleteValue } from "./types";
import { useTranslation } from "../../../../i18n";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
const DesktopAutocomplete = lazy(() => import("./DesktopAutocomplete"));
const MobileAutocomplete = lazy(() => import("./MobileAutocomplete"));

interface Props {
  boundsIndexes: number[];
  direction: "departure" | "destination";
  errors: FormikErrors<FlightsSearchFormValues>;
  touched: FormikTouched<FlightsSearchFormValues>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  values: FlightsSearchFormValues;
  onOptionSelect?(): void;
  scrollControlled?: boolean;
}

const AirportAutocompleteField = forwardRef<HTMLInputElement, Props>(
  (
    {
      boundsIndexes,
      direction,
      errors,
      touched,
      onChange,
      values,
      onOptionSelect,
      scrollControlled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMaxMobile = useMedia(theme.breakpoints.maxMobile);

    const inputLabel = useMemo(
      () =>
        direction === "departure"
          ? t("tix_search_form_departure")
          : t("tix_search_form_destination"),
      [direction, t]
    );

    const inputTitleLabel = useMemo(
      () =>
        direction === "departure"
          ? t("tix_search_form_departure_title")
          : t("tix_search_form_destination_title"),
      [direction, t]
    );

    const selectSuggestion = useCallback(
      (suggestion: AutocompleteAirport, selectedByHand?: boolean) => {
        if (direction === "departure") {
          onChange({
            [`bounds[${boundsIndexes[0]}].departureCode`]: suggestion.code,
            [`bounds[${boundsIndexes[0]}].departureName`]: suggestion.name,
            ...(values.type === FlightType.Return
              ? {
                  [`bounds[${boundsIndexes[1]}].destinationCode`]: suggestion.code,
                  [`bounds[${boundsIndexes[1]}].destinationName`]: suggestion.name,
                }
              : {}),
          });
        } else {
          onChange({
            [`bounds[${boundsIndexes[0]}].destinationCode`]: suggestion.code,
            [`bounds[${boundsIndexes[0]}].destinationName`]: suggestion.name,
            ...(values.type === FlightType.Return
              ? {
                  [`bounds[${boundsIndexes[1]}].departureCode`]: suggestion.code,
                  [`bounds[${boundsIndexes[1]}].departureName`]: suggestion.name,
                }
              : {}),
          });
        }
        const event =
          direction === "departure"
            ? WidgetGATriggerEvents.FlightDeparuteAirportSelected
            : WidgetGATriggerEvents.FlightDestinationAirportSelected;
        dispatchCustomEvent({
          widgetEvent: event,
          details: {
            suggestion,
          },
        });
        if (selectedByHand && !!onOptionSelect) onOptionSelect();
      },
      [boundsIndexes, direction, onChange, values.type, onOptionSelect]
    );

    const value = useMemo(
      () =>
        direction === "departure"
          ? ({
              code: values.bounds[boundsIndexes[0]]?.departureCode,
              name: values.bounds[boundsIndexes[0]]?.departureName,
            } as AutocompleteValue)
          : ({
              code: values.bounds[boundsIndexes[0]]?.destinationCode,
              name: values.bounds[boundsIndexes[0]]?.destinationName,
            } as AutocompleteValue),
      [boundsIndexes, direction, values.bounds]
    );
    const error = useMemo(
      () =>
        direction === "departure"
          ? touched.bounds?.[boundsIndexes[0]]?.departureCode &&
            (errors.bounds?.[boundsIndexes[0]] as any)?.departureCode
          : touched.bounds?.[boundsIndexes[0]]?.destinationCode &&
            (errors.bounds?.[boundsIndexes[0]] as any)?.destinationCode,
      [boundsIndexes, direction, errors.bounds, touched.bounds]
    );

    return (
      <Container>
        <Suspense fallback={null}>
          {isMaxMobile ? (
            <MobileAutocomplete
              direction={direction}
              error={error}
              inputLabel={inputLabel}
              inputTitleLabel={inputTitleLabel}
              onSelect={selectSuggestion}
              value={value}
              ref={ref}
              scrollControlled={scrollControlled}
            />
          ) : (
            <DesktopAutocomplete
              direction={direction}
              error={error}
              inputLabel={inputLabel}
              inputTitleLabel={inputTitleLabel}
              onSelect={selectSuggestion}
              value={value}
              ref={ref}
            />
          )}
        </Suspense>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    width: 225px;
  }
`;

export default AirportAutocompleteField;
