import { BackendModule, InitOptions, ReadCallback, Services, TOptions } from "i18next";
import { getLabels } from "../api/getLabels";
import { I18NNamespace } from "../enums/I18N";
import { chunkArray } from "../utils/arrayUtils";

const MAX_REQUEST_LABELS_COUNT = 50;

class ContentApiBackend implements BackendModule {
  labels: string[] = [];
  type: "backend" = "backend";

  init(services: Services, backendOptions: TOptions, i18nextOptions: InitOptions) {
    this.labels = backendOptions.labels;
  }

  async read(language: string, namespace: I18NNamespace, callback: ReadCallback) {
    try {
      const labelsPromises = chunkArray(this.labels, MAX_REQUEST_LABELS_COUNT).map((labels) =>
        getLabels(
          language.toLowerCase(),
          labels,
          namespace === I18NNamespace.Mobile,
          window.location.origin
        )
      );
      const labelsResults = await Promise.all(labelsPromises);
      const fetchedLabels = labelsResults.reduce((acc, labels) => ({ ...acc, ...labels }), {});
      callback(null, fetchedLabels);
    } catch (e) {
      console.error(e);
    }
  }

  async create(languages: string[], namespace: string, key: string, fallbackValue: string) {}
}

export default ContentApiBackend;
