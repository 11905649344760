// HOME/from.svg

const FlightFrom = ({ width = 21.484, height = 14.989 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.484 14.989"
    >
      <g id="Group_2" data-name="Group 2" transform="translate(-374.865 -326.448)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M6.263-.5a.5.5,0,0,1,.282.087L13.5,4.345l4.868-1.4a2.04,2.04,0,0,1,2.535,1.39,1.992,1.992,0,0,1-.182,1.531,2.018,2.018,0,0,1-1.231.961L2.426,11.474a.5.5,0,0,1-.571-.245L-.44,6.985a.5.5,0,0,1-.02-.435A.5.5,0,0,1-.13,6.264l1.55-.418a.5.5,0,0,1,.268,0L5,6.8,7.9,5.959l-4.6-4.764a.5.5,0,0,1,.2-.823l2.6-.848A.5.5,0,0,1,6.263-.5Zm7.153,5.891a.5.5,0,0,1-.282-.087L6.184.552l-1.6.523L9.2,5.862a.5.5,0,0,1-.221.828L5.136,7.8a.5.5,0,0,1-.276,0L1.546,6.848.74,7.065,2.546,10.4,19.23,5.86a1.024,1.024,0,0,0,.624-.487,1,1,0,0,0,.091-.768,1.036,1.036,0,0,0-1-.745,1.052,1.052,0,0,0-.3.043l-5.1,1.468A.5.5,0,0,1,13.417,5.391Z"
          transform="translate(375.365 326.948)"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M18.985.55H0v-1H18.985Z"
          transform="translate(375.865 340.887)"
        />
      </g>
    </svg>
  );
};

export default FlightFrom;
