import { AvailableFont } from "../enums/AvailableFonts";
import NexaRegularV2Woff2 from "../fonts/NexaRegular/3652d7df-f4e3-4d89-bb8d-4e8f646b5e6f.woff2";
import NexaHeavyV2Woff2 from "../fonts/NexaHeavy/fb572dbc-f1ee-44ca-883e-088c704f543e.woff2";
import DinRoundPro from "../fonts/DinRoundPro/DinRoundPro.woff2";
import DinRoundProBlack from "../fonts/DinRoundPro-Black/DinRoundPro-Black.woff2";

const getFontSrc = (font: AvailableFont): string => {
  switch (font) {
    case AvailableFont.DinRoundProRegular:
      return `url(${process.env.REACT_APP_DOMAIN_PATH_PREFIX}${DinRoundPro}) format("woff2")`;
    case AvailableFont.DinRoundProBlack:
      return `url(${process.env.REACT_APP_DOMAIN_PATH_PREFIX}${DinRoundProBlack}) format("woff2")`;
    case AvailableFont.NexaHeavy:
      return `url(${process.env.REACT_APP_DOMAIN_PATH_PREFIX}${NexaHeavyV2Woff2}) format("woff2")`;
    case AvailableFont.NexaRegular:
      return `url(${process.env.REACT_APP_DOMAIN_PATH_PREFIX}${NexaRegularV2Woff2}) format("woff2")`;
    case AvailableFont.ObjectivHeavy:
      return `url("https://use.typekit.net/af/7bfe96/00000000000000007735fd7f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/7bfe96/00000000000000007735fd7f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/7bfe96/00000000000000007735fd7f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");`;
    case AvailableFont.ObjectivRegular:
      return `url("https://use.typekit.net/af/fdbde9/00000000000000007735fd85/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/fdbde9/00000000000000007735fd85/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/fdbde9/00000000000000007735fd85/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");`;
    default:
      return "";
  }
};

export const getFontFaceAsString = (font: AvailableFont): string => {
  switch (font) {
    case AvailableFont.DinRoundProRegular:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.DinRoundProRegular)};
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
          font-weight: 400;
        }`;
    case AvailableFont.DinRoundProBlack:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.DinRoundProBlack)};
          font-display: auto;
          font-style: normal;
          font-stretch: normal;
          font-weight: 900;
        }`;
    case AvailableFont.NexaRegular:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.NexaRegular)};
        }`;
    case AvailableFont.NexaHeavy:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.NexaHeavy)};
        }`;
    case AvailableFont.ObjectivRegular:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.ObjectivRegular)};
          font-display:auto;
          font-style:normal;
          font-weight:400;
          font-stretch:normal;
        }`;
    case AvailableFont.ObjectivHeavy:
      return `
        @font-face {
          font-family: "${font}";
          src: ${getFontSrc(AvailableFont.ObjectivHeavy)};
          font-display:auto;
          font-style:normal;
          font-weight:700;
          font-stretch:normal;
        }`;
    default:
      return "";
  }
};

export const addFontsToDocumentHead = () => {
  let cssToAdd = "";
  Object.values(AvailableFont).forEach((value) => {
    cssToAdd = `${cssToAdd}${getFontFaceAsString(value)}\n`;
  });
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.appendChild(document.createTextNode(cssToAdd));
  head.appendChild(style);
};
