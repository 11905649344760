import { CommunicationType } from "../enums/CommunicationType";
import { LayoutType } from "../enums/LayoutType";
import { SearchWidgetType } from "../enums/SearchWidgetType";
import { ThemeType } from "../enums/ThemeType";

export const DEFAULT_FLIGHTS_SRP_PATH = "/flights/search";
export const DEFAULT_HOTELS_SRP_PATH = "/hotels/search";
export const DEFAULT_LANG = "NL";
export const DEFAULT_WIDGET_TYPE = SearchWidgetType.Combined;
export const DEFAULT_THEME = ThemeType.Vliegtickets;
export const DEFAULT_LAYOUT = LayoutType.External;
export const DEFAULT_COMMUNICATION_TYPE = CommunicationType.Standalone;
export const DEFAULT_MOBILE_TOP_OFFSET = "0px";
