import { format } from "date-fns";

export function isEqual(value: any, other: any, options?: { compareWithDateFormat?: string }) {
  if ((value === undefined && other !== undefined) || (value !== undefined && other === undefined))
    return false;
  if (value.toString() !== other.toString()) return false;
  if (["[object Object]"].indexOf(value.toString()) < 0 && compare(value, other, options) === false)
    return false;

  const otherKeys = Object.keys(other);
  const valueKeys = Object.keys(value);
  if (valueKeys.length !== otherKeys.length) return false;
  if (!valueKeys.every((v) => otherKeys.includes(v))) return false;

  for (let key in value) {
    if (compare(value[key], other[key], options) === false) {
      return false;
    }
  }
  return true;
}

function compare(item1: object, item2: object, options?: { compareWithDateFormat?: string }) {
  if (item1 === undefined || item1 === null || item2 === undefined || item2 === null) {
    return item1 === item2;
  }
  if (Array.isArray(item1) && Array.isArray(item2)) {
    if (item1.length !== item2.length) return false;
    return !item1.some((item, index) => !isEqual(item, item2[index]));
  }
  if (["[object Object]"].indexOf(item1.toString()) >= 0) {
    if (!isEqual(item1, item2)) return false;
  } else {
    if (item1.toString() === "[object Function]") {
      if (item1.toString() !== item2.toString()) return false;
    } else if (item1 instanceof Date && item2 instanceof Date) {
      if (options?.compareWithDateFormat) {
        return (
          format(item1, options?.compareWithDateFormat) ===
          format(item2, options?.compareWithDateFormat)
        );
      }
      if (item1.getTime() !== item2.getTime()) return false;
    } else {
      if (item1.toString() !== item2.toString()) return false;
    }
  }
}
