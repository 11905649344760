import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FormikHelpers,
  useFormik,
  setNestedObjectValues,
  FormikErrors,
  getIn,
  setIn,
} from "formik";
import { CabinClassCode } from "../enums/CabinClassCode";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesBound,
  FlightsSearchFormValuesType,
} from "../types/FlightsSearchFormValues";
import { FlightsSearchCriteria } from "../types/FlightsSearchCriteria";
import { validationSchema } from "../components/FlightsSearchForm/validationSchema";
import { useRequest } from "../api";
import {
  getAutocompleteAirports,
  GetAutocompleteAirportsResponse,
} from "../api/actions/getAutocompleteAirports";
import { useAppAttributes } from "./useAppAttributes";
import { CommunicationType } from "../enums/CommunicationType";
import { WidgetDispatchEvents } from "../enums/WidgetEvents";
import { dispatchCustomEvent } from "../services/widgetEventsService";
import { useSearchFormEvents } from "./useSearchFormEvents";

type OnSubmit = (
  values: FlightsSearchFormValues,
  formikHelpers: FormikHelpers<FlightsSearchFormValues>
) => void | Promise<any>;

const EMPTY_BOUND: FlightsSearchFormValuesBound = {
  departureCode: "",
  departureName: "",
  departureDate: undefined,
  destinationCode: "",
  destinationName: "",
};

const EMPTY_FORM_VALUES = {
  type: FlightsSearchFormValuesType.Return,
  adults: 1,
  children: 0,
  infants: 0,
  bounds: [{ ...EMPTY_BOUND }, { ...EMPTY_BOUND }],
  cabinClass: CabinClassCode.Economy,
};

export interface SearchFormReturnType
  extends ReturnType<typeof useFormik<FlightsSearchFormValues>> {
  hasErrors: boolean;
  setValueToCriteria: (validate: boolean, searchCriteria?: FlightsSearchCriteria) => void;
  setFieldsValues: (valuesChanges: Record<string, any>, shouldValidate?: boolean) => Promise<void>;
}

export const useFlightsSearchForm = (
  onSubmit: OnSubmit,
  searchCriteria?: FlightsSearchCriteria,
  validateOnInit = true
): SearchFormReturnType => {
  const request = useRequest();
  const searchCriteriaRef = useRef<FlightsSearchCriteria>();
  const [isInitialized, setIsInitialized] = useState(false);
  const appAttributes = useAppAttributes();

  const {
    errors,
    setFieldTouched,
    setFieldValue,
    setTouched,
    setValues,
    touched,
    validateForm,
    ...formik
  } = useFormik<FlightsSearchFormValues>({
    initialValues: EMPTY_FORM_VALUES,
    onSubmit: (values, formikHelpers) => {
      searchCriteriaRef.current = new FlightsSearchCriteria(values);
      if (appAttributes.communicationType === CommunicationType.Event) {
        dispatchCustomEvent({ widgetEvent: WidgetDispatchEvents.OnSubmit });
      } else {
        onSubmit(values, formikHelpers);
      }
    },
    validationSchema,
  });

  const getAirportNames = useCallback(
    async (searchCriteria?: FlightsSearchCriteria) => {
      if (!searchCriteria) return {};
      try {
        const codes = searchCriteria.bounds
          .flatMap((bound) => [bound.departureCode, bound.destinationCode])
          .filter((v, i, a) => !!v && a.indexOf(v) === i);

        const autocompleteAirports = await Promise.all(
          codes.map((code) =>
            request<GetAutocompleteAirportsResponse>(
              getAutocompleteAirports({
                query: code,
              })
            )
          )
        );
        return autocompleteAirports
          .map(([airport]) => airport)
          .filter(Boolean)
          .reduce(
            (acc, airport) => ({
              ...acc,
              ...(airport ? { [airport.code]: airport.name } : {}),
            }),
            {}
          );
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [request]
  );

  const createValues = useCallback(
    async (searchCriteria?: FlightsSearchCriteria) => {
      const airportNames = await getAirportNames(searchCriteria);
      const values = await createValuesFromSearchCriteria(airportNames, searchCriteria);
      return values;
    },
    [getAirportNames]
  );

  const setValueToCriteria = useCallback(
    (validate: boolean, searchCriteria?: FlightsSearchCriteria) => {
      searchCriteriaRef.current = searchCriteria;
      createValues(searchCriteria)
        .then(async (values) => {
          setValues(values);
          if (searchCriteria && validate) {
            const errors = await validateForm(values);
            setTouched(setNestedObjectValues(errors, true));
          } else {
            setTouched({});
          }
          setIsInitialized(true);
        })
        .catch(() => {});
    },
    [createValues, setTouched, setValues, validateForm]
  );

  useEffect(() => {
    if (searchCriteria?.toQueryString() !== searchCriteriaRef.current?.toQueryString()) {
      setValueToCriteria(validateOnInit || isInitialized, searchCriteria);
    }
  }, [setValueToCriteria, searchCriteria, validateOnInit, isInitialized]);

  const syncSetFieldValue = useCallback(
    async (field: string, value: any, shouldValidate?: boolean) => {
      await setFieldValue(field, value, shouldValidate);
      setFieldTouched(field, !!shouldValidate);
    },
    [setFieldTouched, setFieldValue]
  );

  const setFieldsValues = useCallback(
    async (valuesChanges: Record<string, any>, shouldValidate?: boolean) => {
      const entries = Object.entries(valuesChanges);
      if (entries.length === 0) return;
      if (entries.length === 1) {
        syncSetFieldValue(entries[0][0], entries[0][1], shouldValidate);
        return;
      }
      setValues((currentValues) => {
        let newValues = { ...currentValues };
        entries.forEach(([key, value]) => {
          newValues = setIn(newValues, key, value);
        });
        return newValues;
      }, shouldValidate);
    },
    [setValues, syncSetFieldValue]
  );

  const hasErrors = useMemo(() => {
    return (
      !!errors.bounds &&
      (errors.bounds as FormikErrors<FlightsSearchFormValuesBound[]>).some((bound, index) => {
        return (
          (!!bound?.departureCode && getIn(touched, `bounds[${index}].departureCode`)) ||
          (!!bound?.departureDate && getIn(touched, `bounds[${index}].departureDate`)) ||
          (!!bound?.destinationCode && getIn(touched, `bounds[${index}].destinationCode`))
        );
      })
    );
  }, [errors.bounds, touched]);

  const baseForm = {
    ...formik,
    errors,
    setFieldTouched,
    setFieldValue: syncSetFieldValue,
    setTouched,
    setValues,
    touched,
    validateForm,
    setFieldsValues,
  };

  useSearchFormEvents({
    isInitialized,
    form: baseForm,
  });

  return {
    ...baseForm,
    hasErrors,
    setValueToCriteria,
  };
};

const createValuesFromSearchCriteria = async (
  airportNames: { [code: string]: string },
  searchCriteria?: FlightsSearchCriteria
): Promise<FlightsSearchFormValues> => {
  const type = searchCriteria?.bounds.length
    ? searchCriteria.bounds.length === 1
      ? FlightsSearchFormValuesType.OneWay
      : searchCriteria.bounds.length === 2 &&
        searchCriteria.bounds[0].departureCode === searchCriteria.bounds[1].destinationCode &&
        searchCriteria.bounds[1].departureCode === searchCriteria.bounds[0].destinationCode
      ? FlightsSearchFormValuesType.Return
      : FlightsSearchFormValuesType.MultiCity
    : EMPTY_FORM_VALUES.type;
  return {
    type,
    adults: searchCriteria?.adults || EMPTY_FORM_VALUES.adults,
    children: searchCriteria?.children || EMPTY_FORM_VALUES.children,
    infants: searchCriteria?.infants || EMPTY_FORM_VALUES.infants,
    bounds: searchCriteria?.bounds.length
      ? searchCriteria.bounds.map(
          (bound) =>
            ({
              ...bound,
              ...(airportNames[bound.departureCode]
                ? {
                    departureName: airportNames[bound.departureCode],
                  }
                : {
                    departureCode: "",
                    departureName: "",
                  }),
              ...(airportNames[bound.destinationCode]
                ? {
                    destinationName: airportNames[bound.destinationCode],
                  }
                : {
                    destinationCode: "",
                    destinationName: "",
                  }),
            } as FlightsSearchFormValuesBound)
        )
      : EMPTY_FORM_VALUES.bounds,
    cabinClass: searchCriteria?.cabinClass || EMPTY_FORM_VALUES.cabinClass,
  };
};
