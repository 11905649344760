import { AllDispatchedWidgetEvents } from "../enums/WidgetEvents";

interface CustomEventProps {
  widgetEvent: AllDispatchedWidgetEvents;
  details?: any;
}

export const dispatchCustomEvent = (data: CustomEventProps) => {
  dispatchEvent(new CustomEvent(data.widgetEvent, { detail: data.details }));
};
