import { Method } from "axios";

export interface ApiAction {
  data?: object;
  headers?: Record<string, string>;
  method: Method;
  name: ApiActionName;
  url: string;
}

export enum ApiActionName {
  GetAutocompleteAirlines = "getAutocompleteAirlines",
  GetAutocompleteAirports = "getAutocompleteAirports",
  GetAutocompleteHotels = "getAutocompleteHotels",
}
