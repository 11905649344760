import { forwardRef } from "react";
import { InputProps } from "react-autosuggest";
import styled from "styled-components";
import FlightFromIcon from "../../../../../customIcons/FlightFrom";
import FlightToIcon from "../../../../../customIcons/FlightTo";
import SwapIcon from "../../../../../customIcons/Swap";
import Button from "../../../../shared/Button";

type Direction = "departure" | "destination";

interface Props extends InputProps<any> {
  direction: Direction;
  error?: string;
  label: string;
  onSwap?(): void;
  placeholder?: string;
}

const InputComponent = forwardRef(
  ({ direction, error, label, onSwap, ...inputProps }: Props, ref) => {
    return (
      <FieldContainer
        direction={direction}
        hasError={!!error}
        onFocus={(event) => {
          const input = event.currentTarget?.querySelector("input");
          setTimeout(() => {
            input && input.select();
          });
        }}
      >
        {direction === "departure" ? <FlightFromIcon /> : <FlightToIcon />}
        <input ref={ref} aria-label={label} {...(inputProps as any)} />
        {onSwap && (
          <SwapButton
            color="transparent"
            onlyIcon
            onFocus={(event) => {
              event.stopPropagation();
            }}
            onClick={onSwap}
            tabIndex={-1}
          >
            <SwapIcon />
          </SwapButton>
        )}
      </FieldContainer>
    );
  }
);

const FieldContainer = styled.div<{ direction: Direction; hasError: boolean }>`
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    &::after {
      border-right: 1px solid ${({ theme }) => theme.defaultColor};
      content: "";
      height: 20px;
      position: absolute;
      right: 1px;
      top: 14px;
    }
  }

  & > input[type="text"] {
    /* && { */
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ hasError, theme }) => (hasError ? theme.errorColor : theme.defaultColor)};
    padding: ${({ direction }) => (direction === "departure" ? "0 42px 0 47px" : "0 20px 0 47px")};
    font-size: 14px;
    font-family: inherit;
    border-radius: 28px;
    border: none;
    height: 44px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    width: 100%;
    x-index: 1;

    &::placeholder {
      color: ${({ hasError, theme }) =>
        hasError ? theme.errorColor : theme.form.textField.placeholderColor};
      opacity: 1;
    }

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      border-radius: ${({ direction }) => (direction === "departure" ? "28px 0 0 28px " : "0")};
      height: 48px;
      padding: ${({ direction }) => (direction === "departure" ? "0 42px 0 50px" : "0 9px 0 43px")};
    }
    /* } */
  }

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: ${({ direction }) => (direction === "destination" ? "10px" : "20px")};
    }
  }
`;

const SwapButton = styled(Button)`
  border-radius: 0;
  height: 20px;
  max-width: unset;
  min-height: unset;
  min-width: unset;
  padding: 0 11px 0 13px;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  width: unset;
  z-index: 2;

  svg {
    stroke: unset;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    border-left: 1px solid ${({ theme }) => theme.defaultColor};
    right: 5px;
  }
`;

export default InputComponent;
