import qs from "qs";
import { useCallback, useState } from "react";
import { FlightsSearchCriteria } from "../types/FlightsSearchCriteria";

export function useFlightsSearchCriteria(listenOnChange = true) {
  // Params
  const getFlightsSearchCriteriaParams = useCallback((): FlightsSearchCriteria => {
    const locationParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const searchCriteria = new FlightsSearchCriteria(locationParams as { [key: string]: string });
    return searchCriteria;
  }, []);

  // State
  const [searchCriteria, setSearchCriteria] = useState(getFlightsSearchCriteriaParams());
  const [previousSearchCriteria, setPreviousSearchCriteria] = useState<FlightsSearchCriteria>();

  const changeSearchCriteria = useCallback((searchCriteria: FlightsSearchCriteria) => {
    setSearchCriteria((currentSearchCriteria) => {
      setPreviousSearchCriteria(currentSearchCriteria);
      return searchCriteria;
    });
  }, []);

  return { previousSearchCriteria, searchCriteria, setSearchCriteria: changeSearchCriteria };
}
