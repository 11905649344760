import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { IconDefinition as BrandIcon } from "@fortawesome/free-brands-svg-icons";
import { IconDefinition as SolidIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import ButtonLoader from "./ButtonLoader";
import { useAppAttributes } from "../../hooks/useAppAttributes";
import { LayoutType } from "../../enums/LayoutType";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  isLoading?: boolean;
  customRightIcon?: JSX.Element;
  customLeftIcon?: JSX.Element;
  rightIcon?: BrandIcon | SolidIcon;
  leftIcon?: BrandIcon | SolidIcon;
  rightIconSpin?: boolean;
  leftIconSpin?: boolean;
  bordered?: boolean;
  onlyIcon?: boolean;
  fontType?: "Regular" | "Heavy";
  size?: "small" | "medium";
  type?: "button" | "reset" | "submit";
  variant?: "standard" | "rectangle";
  color?: "primary" | "primaryInversed" | "white" | "transparent";
}

const Button: FC<Props> = ({
  children,
  customLeftIcon,
  customRightIcon,
  isLoading,
  leftIcon,
  leftIconSpin,
  rightIcon,
  rightIconSpin,
  disabled,
  bordered = false,
  onlyIcon = false,
  fontType = "Heavy",
  variant = "standard",
  type = "button",
  color = "primary",
  ...props
}) => {
  const appAttributes = useAppAttributes();
  return (
    <LayoutButtonContainer
      layout={appAttributes.layout}
      {...props}
      disabled={isLoading || disabled}
      bordered={bordered}
      fontType={fontType}
      type={type}
      color={color}
      variant={variant}
      onlyIcon={onlyIcon}
      isLoading={isLoading}
    >
      {isLoading ? (
        <ButtonLoader size={bordered ? 34 : 36} width={3} />
      ) : (
        <>
          {customLeftIcon ? (
            <CustomLeftIconWrapper>{customLeftIcon}</CustomLeftIconWrapper>
          ) : (
            leftIcon && <LeftIcon icon={leftIcon as IconDefinition} spin={leftIconSpin} />
          )}
          {children}
          {customRightIcon ? (
            <CustomRightIconWrapper>{customRightIcon}</CustomRightIconWrapper>
          ) : (
            rightIcon && <RightButton icon={rightIcon as IconDefinition} spin={rightIconSpin} />
          )}
        </>
      )}
    </LayoutButtonContainer>
  );
};

const ButtonContainer = styled.button<Props>`
  align-items: center;
  padding: ${({ isLoading }) => (isLoading ? "3px" : "14px")} 20px;
  // border-radius oversized to have alawsy perfectly rounded corners
  border-radius: ${({ variant }) => (variant === "rectangle" ? "0;" : "50px")};
  font-size: ${({ size }) => {
    switch (size) {
      case "small":
        return "16px";
      case "medium":
      default:
        return "20px";
    }
  }};
  letter-spacing: 0;
  line-height: 1;
  height: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  background: ${({ theme, color }) => {
    switch (color) {
      case "primary":
        return `${theme.form.button.primaryBackground};`;
      case "primaryInversed":
        return `${theme.defaultBackground};`;
      default:
        return `${color};`;
    }
  }};

  color: ${({ theme, color }) => {
    switch (color) {
      case "primary":
        return `${theme.form.button.primaryColor};`;
      case "primaryInversed":
        return `${theme.form.button.primaryBackground};`;
      default:
        return `${theme.defaultColor};`;
    }
  }};

  border: ${({ theme, bordered, color }) =>
    bordered
      ? `1px solid ${(() => {
          switch (color) {
            case "primary":
              return `${theme.defaultBackground};`;
            case "primaryInversed":
              return `${theme.form.button.primaryBackground};`;
            default:
              return `${theme.defaultColor};`;
          }
        })()};`
      : "none;"};

  svg {
    ${({ theme, color }) => {
      switch (color) {
        case "primary":
          return `stroke: ${theme.defaultBackground}; 
                fill: ${theme.defaultBackground}`;
        case "primaryInversed":
          return `stroke: ${theme.form.button.primaryBackground}; 
                fill: ${theme.form.button.primaryBackground}`;
        default:
          return `stroke: ${theme.defaultColor}; 
                fill: ${theme.defaultColor}`;
      }
    }};
  }

  ${({ onlyIcon }) =>
    onlyIcon
      ? `
    padding: 10px;
    max-width: 36px;
    max-height: 36px;
    `
      : ""}

  ${({ onlyIcon, isLoading }) => (onlyIcon && isLoading ? `padding: 0;` : "")}

  &:disabled {
    cursor: not-allowed;
    ${({ isLoading }) => (isLoading ? "opacity: 1;" : "opacity: 0.25;")};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover:not(:disabled) {
      ${({ theme, color }) => {
        switch (color) {
          case "primary":
            return `background: ${theme.form.button.primaryHoverBackground};`;
          case "primaryInversed":
            return `color: ${theme.form.button.primaryHoverBackground};`;
          case "transparent":
            return "";
          default:
            return `background: ${theme.form.button.defaultHoverBackground};`;
        }
      }};

      svg {
        &,
        & * {
          ${({ theme, color }) => {
            switch (color) {
              case "primaryInversed":
                return `stroke: ${theme.form.button.primaryHoverBackground};`;
            }
          }};
        }
      }
    }
  }
`;

const LayoutButtonContainer = styled(ButtonContainer)<{ layout?: LayoutType }>`
  font-family: ${({ theme, fontType, layout }) =>
    layout === LayoutType.External
      ? theme.regularFont
      : fontType === "Heavy"
      ? theme.regularFont
      : theme.heavyFont};
`;

const LeftIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
  font-size: 1.25em;
`;

const RightButton = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
  font-size: 1.25em;
`;

const CustomLeftIconWrapper = styled.div`
  display: inline-flex;
  margin-right: 0.5rem;
`;

const CustomRightIconWrapper = styled.div`
  display: inline-flex;
  margin-left: 0.5rem;
`;

export default Button;
