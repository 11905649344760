export enum Language {
  NL = "NL",
  EN = "EN",
  FR = "FR",
  DE = "DE",
  ES = "ES",
  PL = "PL",
  IT = "IT",
  PT = "PT",
  DA = "DA",
  EL = "EL",
  SV = "SV",
  FI = "FI",
  NB = "NB",
  TR = "TR",
}
