import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import HotelBedIcon from "../../../../customIcons/HotelBed";
import { Breakpoint } from "../../../../enums/Breakpoint";
import useMedia from "../../../../hooks/useMedia";
import Button from "../../../shared/Button";
import Fields from "./Fields";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { HotelsSearchParamsRoom } from "../../../../types/HotelsSearchParams";
import MobileModal from "../../../shared/MobileModal";
import { useTranslation } from "react-i18next";
import { InputKey, useWizardContext } from "../../WizardContext";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { useSearchFormWithFocusScroll } from "../../../../hooks/useSearchFormWithFocusScroll";

interface Props {
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onSubmit: () => void;
  values: HotelsSearchParamsRoom[];
}

const RoomsCountField = ({ onChange, onSubmit, values }: Props) => {
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const { isWizard, mobileModalAnimations, setInputRef, stopWizard } = useWizardContext();
  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();

  const [isOpened, setIsOpened] = useState(false);
  const [areChildrenAgeTouched, setAreChildrenAgeTouched] = useState(false);

  const areChildrenAgeValid = useMemo(() => {
    return values.every((room) =>
      room.childrenAge.every((childAge) => typeof childAge === "number")
    );
  }, [values]);
  const close = useCallback(() => {
    if (!isOpened) return;
    stopWizard();
    if (!areChildrenAgeValid) {
      setAreChildrenAgeTouched(true);
    } else {
      setIsOpened(false);
      setAreChildrenAgeTouched(false);
    }
  }, [areChildrenAgeValid, isOpened, stopWizard]);
  const dropdownClose = useCallback(() => {
    if (isMinTablet) {
      close();
    }
  }, [close, isMinTablet]);
  useOutsideClick(ref, dropdownClose);

  // Button label
  const label = useMemo(() => {
    const { adults, children } = values.reduce(
      (acc, room) => ({
        adults: acc.adults + room.adults,
        children: acc.children + room.childrenAge.length,
      }),
      {
        adults: 0,
        children: 0,
      }
    );
    return `${values.length} ${
      values.length > 1 ? t("tix_search_form_rooms") : t("tix_search_form_room")
    }, ${adults} ${adults > 1 ? t("tix_search_form_adults") : t("tix_search_form_adult")}${
      children > 0
        ? `, ${children} ${
            children > 1 ? t("tix_search_form_children") : t("tix_search_form_child")
          }`
        : ""
    }`;
  }, [t, values]);

  useEffect(() => {
    if (isOpened) {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.OpenHotelsExtendedSearch });
      handleScrollToSearchFormIfNeeded(dropdownRef.current);
    }
  }, [handleScrollToSearchFormIfNeeded, isOpened]);

  const submitAction = useMemo(() => (isWizard ? onSubmit : close), [close, onSubmit, isWizard]);

  return (
    <Container id="rooms-count-field" ref={ref}>
      <DropdownButton
        ref={setInputRef(InputKey.RoomCount)}
        onClick={() => (isOpened ? dropdownClose() : setIsOpened(true))}
        title={label}
        type="button"
      >
        <HotelBedIcon />
        <Label>{label}</Label>
      </DropdownButton>
      {isMinTablet ? (
        isOpened && (
          <Dropdown ref={dropdownRef}>
            <DropdownIndicator />
            <Fields
              areChildrenAgeTouched={areChildrenAgeTouched}
              onChange={onChange}
              values={values}
            />
            <ReadyButton onClick={submitAction}>
              {t(isWizard ? "tix_search_form_search_hotels" : "tix_search_form_ready")}
            </ReadyButton>
          </Dropdown>
        )
      ) : (
        <MobileModal
          action={{
            label: t(isWizard ? "tix_search_form_search_hotels" : "tix_search_form_continue"),
            onClick: submitAction,
          }}
          animations={mobileModalAnimations}
          headerTitle={t("tix_search_form_rooms_title")}
          headerIcon={<HotelBedIcon />}
          isOpened={isOpened}
          onClose={close}
          isWizard={isWizard}
        >
          <MobileFieldsContainer>
            <Fields
              areChildrenAgeTouched={areChildrenAgeTouched}
              onChange={onChange}
              values={values}
            />
          </MobileFieldsContainer>
        </MobileModal>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  margin-bottom: 10px;

  @media ${Breakpoint.MinDesktop} {
    margin-bottom: 9px;
    flex-basis: 31%;
    flex-shrink: 0;
    width: 31%;
  }
`;

const DropdownButton = styled.button`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border-radius: 28px;
  border: none;
  color: ${({ theme }) => theme.defaultColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 14px;
  height: 44px;
  padding: 0 20px 0 47px;
  position: relative;
  text-align: left;
  width: 100%;

  @media ${Breakpoint.MinTablet} {
    font-size: 14px;
    padding: 0 20px 0 50px;
  }

  @media ${Breakpoint.MinDesktop} {
    height: 48px;
    border-radius: 0 28px 28px 0;
    padding: 0 10px 0 40px;
  }

  svg {
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${Breakpoint.MinDesktop} {
      left: 10px;
    }
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.defaultColor};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MobileFieldsContainer = styled.div`
  padding: 0 20px;
`;

const Dropdown = styled.div`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 19px 20px 19px;
  position: absolute;
  right: unset;
  top: 62px;
  width: 259px;
  z-index: 10;

  @media ${Breakpoint.MinDesktop} {
    top: 66px;
  }
`;

const DropdownIndicator = styled.div`
  opacity: 1;
  position: absolute;
  top: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 14px 13px;
  border-color: transparent transparent ${({ theme }) => theme.defaultBackground} transparent;
  background: transparent;
  left: 50%;
  transform: translateX(-50%);
`;

const ReadyButton = styled(Button)`
  height: 48px;
`;

export default RoomsCountField;
