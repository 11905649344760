export const FlightsTabIcon = ({ width = 23, height = 23 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.133 23.133"
    >
      <g id="Group_731" data-name="Group 731" transform="translate(0.5 0.5)">
        <path
          id="Path_9"
          data-name="Path 9"
          d="M21.527.5h0a2.19,2.19,0,0,1,1.567.539,2.553,2.553,0,0,1,.477,2.244A5.269,5.269,0,0,1,22.08,6.026c-.485.485-1.767,1.713-2.912,2.806L21.185,20.36a.5.5,0,0,1-.076.363L19.533,23.09a.5.5,0,0,1-.861-.048l-4.746-9.226c-1.68,1.593-3.092,2.929-3.568,3.379l.265,3.876a.5.5,0,0,1-.093.327L9.066,23.425a.5.5,0,0,1-.873-.116L6.171,17.961.823,15.941a.5.5,0,0,1-.116-.873L2.735,13.6a.5.5,0,0,1,.327-.094l3.876.265c.45-.476,1.786-1.888,3.378-3.567L1.091,5.46A.5.5,0,0,1,1.042,4.6L3.408,3.022a.5.5,0,0,1,.364-.076L15.3,4.964c1.523-1.595,2.416-2.523,2.805-2.912A5.039,5.039,0,0,1,21.527.5ZM18,8.566c1.775-1.692,2.943-2.814,3.376-3.247,1.4-1.4,1.453-3.134,1.013-3.574a1.275,1.275,0,0,0-.86-.246,4.025,4.025,0,0,0-2.714,1.26c-.4.4-1.344,1.38-2.974,3.088a.5.5,0,0,1-.448.147L3.8,3.965,2.3,4.96l8.743,4.5a.5.5,0,0,1,.626.769h0l-.174.183c-2.162,2.279-3.979,4.2-4,4.221a.5.5,0,0,1-.4.155L3.174,14.52l-1.128.815L6.736,17.1a.5.5,0,0,1,.291.291L8.8,22.086l.813-1.127-.269-3.931a.5.5,0,0,1,.155-.4c.02-.019,2.032-1.921,4.38-4.148a.5.5,0,0,1,.789.591l4.5,8.755.993-1.492L18.256,9.422A.5.5,0,0,1,18,8.568h0Z"
          transform="translate(-1 -0.999)"
        />
        <path
          id="Line_28"
          data-name="Line 28"
          d="M0,4.565A.5.5,0,0,1-.362,4.41.5.5,0,0,1-.344,3.7L3.932-.362a.5.5,0,0,1,.707.018.5.5,0,0,1-.018.707L.345,4.427A.5.5,0,0,1,0,4.565Z"
          transform="translate(13.066 7.931)"
        />
        <path
          id="Line_29"
          data-name="Line 29"
          d="M0,5.065a.5.5,0,0,1-.344-.138.5.5,0,0,1-.018-.707L3.977-.344a.5.5,0,0,1,.707-.018A.5.5,0,0,1,4.7.345L.362,4.91A.5.5,0,0,1,0,5.065Z"
          transform="translate(10.138 4.503)"
        />
      </g>
    </svg>
  );
};

export default FlightsTabIcon;
