import { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
    visibility: visible;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
    visibility: visible;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0%);
    visibility: visible;
  }

  100% {
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    visibility: visible;
  }

  100% {
    transform: translateX(0);
    visibility: visible;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    visibility: visible;
  }

  100% {
    transform: translateX(-100%);
    visibility: hidden;
  }
`;

export const KEYFRAMES = {
  fadeIn,
  fadeOut,
  slideIn,
  slideOut,
};
