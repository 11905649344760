import { useCallback, useState } from "react";
import styled from "styled-components";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import CircleLoader from "../../../../shared/CircleLoader";
import TextField from "../../../../shared/TextField";
import Suggestions from "./Suggestions";
import { useAirportAutocompleteSuggestions } from "../../../../../hooks/useAirportAutocompleteSuggestions";

interface Props {
  label: string;
  onSelect(suggestion: AutocompleteAirport): void;
}

const MobileFields = ({ label, onSelect }: Props) => {
  const { fetchSuggestions, isLoading, suggestions } = useAirportAutocompleteSuggestions();
  const [query, setQuery] = useState("");
  const clear = useCallback(() => {
    setQuery("");
  }, [setQuery]);

  const handleChange = useCallback(
    (event) => {
      setQuery(event.target.value);
      if (event.target.value.length >= 3) {
        fetchSuggestions(event.target.value);
      }
    },
    [fetchSuggestions]
  );

  return (
    <Container>
      <InputContainer>
        <TextField
          autoFocus
          label={label}
          name="query"
          onChange={handleChange}
          onClear={clear}
          value={query}
        />
      </InputContainer>
      {isLoading ? (
        <LoaderContainer>
          <CircleLoader size={60} width={4} />
        </LoaderContainer>
      ) : (
        suggestions.length > 0 && <Suggestions onSelect={onSelect} suggestions={suggestions} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  flex-grow: 1;
`;

const InputContainer = styled.div`
  background: ${({ theme }) => theme.mobileModal.backgroundColor};
  padding: 0 20px 20px 20px;
`;

export default MobileFields;
