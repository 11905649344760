import styled from "styled-components";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import NexaTypography from "../../../../shared/NexaTypography";

interface Props extends AutocompleteAirport {}

const Suggestion = ({ name, region }: Props) => {
  return (
    <Container>
      <Title>{name}</Title>
      <Description>{region}</Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 9px 0;
`;

const Title = styled(NexaTypography)`
  font-family: ${({ theme }) => theme.heavyFont};
  height: 16px;
`;

const Description = styled(NexaTypography)`
  height: 16px;
`;

export default Suggestion;
