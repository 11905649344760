import { addDays, eachMonthOfInterval, nextMonday } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import DayPicker, {
  CaptionElementProps,
  DateUtils,
  DayModifiers,
  Modifier,
} from "react-day-picker";
import styled from "styled-components";
import { useDateFormatter } from "../../../hooks/useDateFormatter";

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

interface Props {
  canChangeMonth?: boolean;
  numberOfMonths?: number;
  disabledDays?: { before: Date; after: Date };
  initialMonth: Date;
  monthToShow: Date;
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  onDayClick(date: Date): void;
  captionElement?:
    | React.ReactElement<Partial<CaptionElementProps>>
    | React.ComponentClass<CaptionElementProps>
    | React.SFC<CaptionElementProps>;
}

const GenericDayPicker = ({
  canChangeMonth,
  numberOfMonths,
  disabledDays,
  fromDate,
  toDate,
  shouldShowRange,
  initialMonth,
  monthToShow,
  onDayClick,
  captionElement,
}: Props) => {
  const formatDate = useDateFormatter();
  const [enteredTo, setEnteredTo] = useState<Date>();

  const handleDayMouseEnter = useCallback(
    (date: Date) => {
      const isAfterDeparture = fromDate && DateUtils.isDayAfter(date, fromDate);
      if (isAfterDeparture) {
        setEnteredTo(date);
      }
    },
    [setEnteredTo, fromDate]
  );

  const handleDayClick = useCallback(
    (date: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return;
      }
      onDayClick(date);
    },
    [onDayClick]
  );

  const monthNames = useMemo(() => {
    const dates = eachMonthOfInterval({
      start: new Date(2019, 12, 1),
      end: new Date(2020, 12, 1),
    });
    return dates.map((date) => formatDate(date, "monthName")) as [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ];
  }, [formatDate]);

  const localeUtils = useMemo(
    () => ({
      formatDate: (date: Date) => formatDate(date, "date"),
      formatDay: (date: Date) => formatDate(date, "namedShortDate"),
      formatMonthTitle: (date: Date) => formatDate(date, ["monthName", "fullYear"]),
      formatWeekdayShort: (day: number) =>
        formatDate(addDays(nextMonday(new Date()), day - 1), "doubleLetterWeekday"),
      formatWeekdayLong: (day: number) =>
        formatDate(addDays(nextMonday(new Date()), day - 1), "weekDayName"),
      getFirstDayOfWeek: () => DayOfWeek.Monday,
      getMonths: () => monthNames,
      parseDate: (str: string) => new Date(str),
    }),
    [monthNames, formatDate]
  );

  const selectedDays = useMemo(() => {
    if (shouldShowRange) {
      let selectedDays: Modifier[] = fromDate ? [fromDate] : [];
      return fromDate && toDate
        ? [...selectedDays, { from: fromDate, to: toDate }]
        : fromDate && enteredTo
        ? [...selectedDays, { from: fromDate, to: enteredTo }]
        : selectedDays;
    } else {
      return fromDate;
    }
  }, [shouldShowRange, fromDate, toDate, enteredTo]);

  const modifiers = useMemo(
    () => (shouldShowRange ? { end: toDate, start: fromDate } : undefined),
    [fromDate, shouldShowRange, toDate]
  );

  return (
    <Container isOneWay={!shouldShowRange}>
      <DayPicker
        canChangeMonth={canChangeMonth}
        disabledDays={disabledDays}
        localeUtils={localeUtils}
        captionElement={captionElement}
        initialMonth={initialMonth}
        month={monthToShow}
        numberOfMonths={numberOfMonths}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
    </Container>
  );
};

const Container = styled.div<{ isOneWay: boolean }>`
  .DayPicker {
    color: ${({ theme }) => theme.defaultColor};
    font-family: ${({ theme }) => theme.regularFont};

    .DayPicker-NavBar {
      display: none;
    }

    &:not(.DayPicker--interactionDisabled) {
      .DayPicker-Months {
        .DayPicker-Month {
          .DayPicker-Body {
            .DayPicker-Week {
              .DayPicker-Day {
                &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--outside
                  ):hover {
                  background-color: ${({ theme }) =>
                    theme.form.calendar.hoverBackground} !important;
                }
              }
            }
          }
        }
      }
    }

    .DayPicker-Months {
      .DayPicker-Month {
        .DayPicker-Caption {
          font-family: ${({ theme }) => theme.heavyFont};
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          margin: 0;
          padding: 0;

          & > div {
            font-size: inherit;
            line-height: inherit;
          }
        }

        .DayPicker-Weekdays {
          .DayPicker-WeekdaysRow {
            .DayPicker-Weekday {
              color: ${({ theme }) => theme.defaultColor};
              font-size: 12px;
              box-sizing: border-box;
              vertical-align: middle;
              text-align: center;
            }
          }
        }

        .DayPicker-Body {
          .DayPicker-Week {
            .DayPicker-Day {
              border-radius: 0;
              box-sizing: border-box;
              font-family: ${({ theme }) => theme.heavyFont};
              font-size: 12px;

              &.DayPicker-Day--today {
                color: inherit;
                font-weight: unset;
              }

              &.DayPicker-Day--disabled {
                color: inherit;
                opacity: 25%;
              }

              &.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
                background-color: ${({ isOneWay, theme }) =>
                  isOneWay
                    ? theme.form.calendar.activeBackground
                    : theme.form.calendar.hoverBackground} !important;
                color: inherit;

                &.DayPicker-Day--start,
                &.DayPicker-Day--end {
                  background-color: ${({ theme }) =>
                    theme.form.calendar.activeBackground} !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GenericDayPicker;
