import { addDays, startOfToday } from "date-fns";
import * as Yup from "yup";
import { CabinClassCode } from "../../enums/CabinClassCode";
import { FlightsSearchFormValuesType as FlightType } from "../../types/FlightsSearchFormValues";

const MAX_NR_OF_PASSENGERS = 9;
const ALLOWED_DAYS_IN_FUTURE = 361;

export const validationSchema = Yup.object({
  type: Yup.mixed<FlightType>().oneOf(Object.values(FlightType), "tix_search_form_err_empty_fiels"),
  adults: Yup.number()
    .min(1, "tix_search_form_err_adults_gt_0")
    .test({
      message: "tix_search_form_err_passengers_amount_gt_9",
      test: function (adults) {
        const children: number = this.resolve(Yup.ref("children"));
        const infants: number = this.resolve(Yup.ref("infants"));
        return (adults as number) + children + infants <= MAX_NR_OF_PASSENGERS;
      },
    }),
  children: Yup.number(),
  infants: Yup.number().test({
    message: "tix_search_form_err_infants_more_than_adults",
    test: function (infants) {
      const adults: number = this.resolve(Yup.ref("adults"));
      return adults >= (infants as number);
    },
  }),
  bounds: Yup.array().of(
    Yup.object().shape({
      departureCode: Yup.string().required("tix_search_form_err_departure_is_empty"),
      departureName: Yup.string().required("tix_search_form_err_departure_is_empty"),
      departureDate: Yup.date()
        .required("tix_search_form_err_departure_date_is_empty")
        .min(startOfToday(), "tix_search_form_err_departure_date_is_from_past")
        .max(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE), "tix_search_form_err_latest_date"),
      destinationCode: Yup.string()
        .required("tix_search_form_err_destination_is_empty")
        .test({
          message: "tix_search_form_err_dep_des_the_same",
          test: function (destinationCode) {
            const departureCode: string = this.resolve(Yup.ref("departureCode"));
            return destinationCode !== departureCode;
          },
        }),
      destinationName: Yup.string()
        .required("tix_search_form_err_destination_is_empty")
        .test({
          message: "tix_search_form_err_dep_des_the_same",
          test: function (destinationName) {
            const departureName: string = this.resolve(Yup.ref("departureName"));
            return destinationName !== departureName;
          },
        }),
    })
  ),
  cabinClass: Yup.mixed<CabinClassCode>().oneOf(
    Object.values(CabinClassCode),
    "tix_search_form_err_empty_fiels"
  ),
  campaignId: Yup.mixed().oneOf([Yup.string(), Yup.number()]),
  searchId: Yup.string(),
  affiliateId: Yup.number(),
  preferredFlightId: Yup.string(),
});
