import { format, parse } from "date-fns";
import qs from "qs";
import { DEFAULT_ROOMS } from "../constants/hotelConstants";
import { HotelLocationType } from "../enums/HotelLocationType";
import { HotelsSearchParams, HotelsSearchParamsRoom } from "../types/HotelsSearchParams";
import { isEqual } from "../utils/isEqual";
import {
  HotelsSearchCriteriaConstructorParams,
  HotelsSearchCriteriaPossibleRoomTypes,
} from "./HotelsSearchParams";

const DATE_FORMAT = "yyyy-MM-dd";

export class HotelsSearchCriteria {
  checkInDate?: Date;
  checkOutDate?: Date;
  locationCode: string;
  locationType: HotelLocationType;
  rooms: HotelsSearchParamsRoom[];

  constructor(searchParams: Partial<HotelsSearchCriteriaConstructorParams>) {
    this.checkInDate = this.parseDateParam(searchParams.checkInDate);
    this.checkOutDate = this.parseDateParam(searchParams.checkOutDate);
    this.locationCode = searchParams.locationCode || "";
    this.locationType = searchParams.locationType as HotelLocationType;
    this.rooms = this.parseRooms(searchParams.rooms);
  }

  toQueryString(): string {
    const { checkInDate, checkOutDate, rooms, locationCode, locationType } = this;
    return qs.stringify(
      {
        ...(!!checkInDate ? { checkInDate: format(checkInDate, DATE_FORMAT) } : {}),
        ...(!!checkOutDate ? { checkOutDate: format(checkOutDate, DATE_FORMAT) } : {}),
        ...(locationCode ? { locationCode } : {}),
        ...(locationType ? { locationType } : {}),
        ...(rooms
          ? {
              rooms: rooms.map((room) => {
                return room.childrenAge.length > 0
                  ? { adults: room.adults, childrenAge: room.childrenAge.join(",") }
                  : { adults: room.adults };
              }),
            }
          : {}),
      },
      { encode: false }
    );
  }

  toSearchParams(): HotelsSearchParams {
    return {
      checkInDate: this.checkInDate,
      checkOutDate: this.checkOutDate,
      locationCode: this.locationCode,
      locationType: this.locationType,
      rooms: this.rooms,
    };
  }

  toFormData() {
    return {
      checkInDate: format(this.checkInDate!, DATE_FORMAT),
      checkOutDate: format(this.checkOutDate!, DATE_FORMAT),
      locationCode: this.locationCode,
      locationType: this.locationType,
      rooms: this.rooms,
    };
  }

  isEqual(obj: { [key: string]: any }): boolean {
    return isEqual(this, obj);
  }

  private parseDateParam(date?: string | Date) {
    if (date) {
      return date instanceof Date ? date : parse(date, DATE_FORMAT, new Date());
    }
  }

  private parseRooms(rooms?: HotelsSearchCriteriaPossibleRoomTypes[]) {
    return rooms
      ? rooms.map((room) => ({
          adults: Number(room.adults),
          childrenAge: this.parseChildrenAge(room.childrenAge),
        }))
      : DEFAULT_ROOMS;
  }

  private parseChildrenAge(childrenAge: string | number[]) {
    if (!childrenAge) return [];
    if (Array.isArray(childrenAge)) {
      return childrenAge.map(Number);
    }
    return childrenAge.split(",").map(Number);
  }
}
