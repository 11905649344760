// -.svg

const Minus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.246"
      height="12.246"
      viewBox="0 0 12.246 12.246"
    >
      <path
        id="Stroke_5"
        data-name="Stroke 5"
        d="M7.9,0,0,8"
        transform="translate(6.157 0.5) rotate(45)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};

export default Minus;
