import styled, { keyframes } from "styled-components";
import { Breakpoint } from "../../enums/Breakpoint";

interface Props {
  size?: number;
  width?: number;
  color?: string;
}

const ButtonLoader = ({ size = 80, width = 6, color }: Props) => {
  return (
    <Container size={size} width={width} color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  );
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<{ size: number; width: number; color?: string }>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => size - 16}px;
    height: ${({ size }) => size - 16}px;
    margin: 8px;
    border: ${({ width }) => width}px solid;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, color }) => (color ? color : theme.progressColor || "#00AFEF")}
      transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @media ${Breakpoint.MaxMobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    & div {
      margin: 0;
    }
  }
`;

export default ButtonLoader;
