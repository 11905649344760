import { addDays, startOfToday } from "date-fns";
import * as Yup from "yup";

export const ALLOWED_DAYS_IN_FUTURE = 365;
export const ALLOWED_DAYS_PERIOD = 30;

export const validationSchema = Yup.object({
  checkInDate: Yup.date()
    .required("tix_search_form_err_check_in_date_empty")
    .min(startOfToday(), "tix_search_form_err_check_in_date_from_past")
    .max(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE), "tix_search_form_err_latest_date"),
  checkOutDate: Yup.date()
    .required("tix_search_form_err_check_out_date_empty")
    .min(startOfToday(), "tix_search_form_err_check_out_date_from_past")
    .max(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE), "tix_search_form_err_latest_date")
    .when(
      "checkInDate",
      (checkInDate: string, yup) =>
        checkInDate &&
        yup.max(
          addDays(new Date(checkInDate), ALLOWED_DAYS_PERIOD),
          "tix_search_form_err_hotel_dates_max_period_exceeded"
        )
    ),
  locationCode: Yup.string().required("tix_search_form_err_hotel_location_code_empty"),
  locationName: Yup.string(),
  locationType: Yup.string().required("tix_search_form_hotel_location_code_empty"),
  rooms: Yup.array().of(
    Yup.object().shape({
      adults: Yup.number(),
      childrenAge: Yup.array().of(Yup.number()),
    })
  ),
});
