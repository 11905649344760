import { useCallback, useMemo, useState } from "react";
import { addDays, addMonths, getMonth, isBefore, isEqual, startOfMonth, subMonths } from "date-fns";
import GenericDayPicker from "../GenericDayPicker";
import DoubleMonthsSlider from "./components/DoubleMonthsSlider";
import styled from "styled-components";
import DoubleMonthDayPickerCaption from "./components/DoubleMonthDayPickerCaption";
import { useTranslation } from "../../../../i18n";
import { ALLOWED_DAYS_IN_FUTURE } from "../../../../constants/flightsConstants";

interface Props {
  disabledDays: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  onDayClick: (date: Date) => void;
  shouldShowRange?: boolean;
  initialMonth: Date;
  titleLabelKey?: string;
}

const DoubleMonthDayPicker = ({
  disabledDays,
  fromDate,
  toDate,
  shouldShowRange,
  initialMonth,
  onDayClick,
  titleLabelKey,
}: Props) => {
  const { t } = useTranslation();
  const [manuallySelectedMonth, setManuallySelectedMonth] = useState<Date | undefined>();

  const monthsShownOnTop = useMemo(() => {
    const startDate = startOfMonth(new Date());
    const endDate = startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
    let dateToAdd = startDate;
    const monthsToShow = [];
    while (isBefore(dateToAdd, endDate) || isEqual(dateToAdd, endDate)) {
      monthsToShow.push(dateToAdd);
      dateToAdd = addMonths(dateToAdd, 1);
    }
    return monthsToShow;
  }, []);

  const adjustShownMonthIsDateIsLastPossibleMonth = useCallback((date: Date) => {
    const lastAvailableMonth = startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
    const monthForGivenDate = startOfMonth(date);
    return isEqual(lastAvailableMonth, monthForGivenDate) ? subMonths(date, 1) : date;
  }, []);

  const adjustedMonthIfInTwoMonthSpan = useMemo(() => {
    if (!fromDate) return initialMonth;
    const fromMonthNumber = getMonth(fromDate);
    const initialMonthNumber = getMonth(initialMonth);
    return initialMonthNumber - fromMonthNumber === 1 ? fromDate : initialMonth;
  }, [fromDate, initialMonth]);

  const shownMonth = useMemo(() => {
    const month =
      manuallySelectedMonth || adjustedMonthIfInTwoMonthSpan || initialMonth || disabledDays.before;
    return adjustShownMonthIsDateIsLastPossibleMonth(month);
  }, [
    manuallySelectedMonth,
    adjustShownMonthIsDateIsLastPossibleMonth,
    disabledDays,
    initialMonth,
    adjustedMonthIfInTwoMonthSpan,
  ]);

  const selectDay = useCallback(
    (date: Date) => {
      const selectedDateMonth = getMonth(date);
      const monthFromShown = getMonth(shownMonth);
      const monthToSet =
        selectedDateMonth - monthFromShown === 1 ? addMonths(shownMonth, 1) : shownMonth;
      setManuallySelectedMonth(monthToSet);
      onDayClick(date);
    },
    [setManuallySelectedMonth, onDayClick, shownMonth]
  );

  return (
    <Container>
      {!!titleLabelKey && <TitleContainer>{t(titleLabelKey)}</TitleContainer>}
      <DoubleMonthsSlider
        months={monthsShownOnTop}
        monthForValue={shownMonth}
        setMonth={setManuallySelectedMonth}
      />
      <GenericDayPicker
        canChangeMonth={true}
        numberOfMonths={2}
        disabledDays={disabledDays}
        initialMonth={shownMonth}
        monthToShow={shownMonth}
        fromDate={fromDate}
        toDate={toDate}
        onDayClick={selectDay}
        shouldShowRange={shouldShowRange}
        captionElement={({ date }) => (
          <DoubleMonthDayPickerCaption
            givenMonth={date}
            leftmostMonth={shownMonth}
            setMonth={setManuallySelectedMonth}
          />
        )}
      />
    </Container>
  );
};

const TitleContainer = styled.div`
  margin-bottom: 34px;
  font-size: 18px;
  line-height: 100%;
  font-family: ${({ theme }) => theme.heavyFont};
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  > div {
    width: initial;
  }
  .DayPicker {
    .DayPicker-wrapper {
      padding-bottom: 0px;
    }
  }
  .DayPicker-Months {
    flex-wrap: nowrap;
    gap: 40px;
    .DayPicker-Month {
      margin: 0;
      height: fit-content;

      .DayPicker-Weekdays {
        margin: 0;
        .DayPicker-WeekdaysRow {
          .DayPicker-Weekday {
            line-height: 100%;
            padding: 20px 0px 10px 0px;
          }
        }
      }

      .DayPicker-Body {
        .DayPicker-Week {
          .DayPicker-Day {
            min-height: 40px;
            max-height: 40px;
            height: 40px;
            min-width: 40px;
            max-width: 40px;
          }
        }
      }
    }
  }
`;

export default DoubleMonthDayPicker;
