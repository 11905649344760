import { useRef, useEffect } from "react";

const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>(value);
  useEffect((): void => {
    if (ref.current !== value) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
};

export default usePrevious;
