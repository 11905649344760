const HotelBed = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.52" height="16" viewBox="0 0 23.518 16.5">
      <g id="Group_15" data-name="Group 15" transform="translate(0.5 0.5)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M21.267,2.37H1.251A1.219,1.219,0,0,1,0,1.185V0H22.518V1.185A1.219,1.219,0,0,1,21.267,2.37Z"
          transform="translate(0 11.851)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M20.516.677l1.668,2.257a1.709,1.709,0,0,1,.334,1.016V5.926H0V3.95A1.709,1.709,0,0,1,.334,2.935L2,.677A1.66,1.66,0,0,1,3.336,0H19.182A1.66,1.66,0,0,1,20.516.677Z"
          transform="translate(0 5.926)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M.05,1.778V0"
          transform="translate(1.728 14.222)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_7"
          data-name="Stroke 7"
          d="M.05,1.778V0"
          transform="translate(20.69 14.222)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_9"
          data-name="Stroke 9"
          d="M0,5.926V1.27A1.266,1.266,0,0,1,1.263,0H14.736A1.266,1.266,0,0,1,16,1.27V5.926"
          transform="translate(2.963)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_11"
          data-name="Stroke 11"
          d="M4.1,2.37H1.231A1.209,1.209,0,0,1,0,1.185H0A1.209,1.209,0,0,1,1.231,0H4.1A1.209,1.209,0,0,1,5.333,1.185h0A1.209,1.209,0,0,1,4.1,2.37Z"
          transform="translate(5.333 3.555)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_13"
          data-name="Stroke 13"
          d="M4.1,2.37H1.231A1.209,1.209,0,0,1,0,1.185H0A1.209,1.209,0,0,1,1.231,0H4.1A1.209,1.209,0,0,1,5.333,1.185h0A1.209,1.209,0,0,1,4.1,2.37Z"
          transform="translate(11.259 3.555)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
export default HotelBed;
