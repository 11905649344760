export const HotelsTabIcon = ({ width = 27, height = 19 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.563 19.717"
    >
      <g id="Group_2037" data-name="Group 2037" transform="translate(0.501 -0.5)">
        <g id="Group_1630" data-name="Group 1630" transform="translate(-0.001 1)">
          <path
            id="Path_350"
            data-name="Path 350"
            d="M8.1,21.344V6.551H4.586A.585.585,0,0,0,4,7.136V21.344"
            transform="translate(-1.938 -2.735)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_351"
            data-name="Path 351"
            d="M24.044,19.717V1.588A.588.588,0,0,0,23.457,1H10.546a.587.587,0,0,0-.586.588V19.717"
            transform="translate(-3.8 -1)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_352"
            data-name="Path 352"
            d="M34.543,21.344V7.136a.585.585,0,0,0-.587-.585h-3.51V21.344"
            transform="translate(-10.202 -2.735)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_353"
            data-name="Path 353"
            d="M21.483,26.744V23.831H16.788v2.913"
            transform="translate(-5.934 -8.135)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_419"
            data-name="Rectangle 419"
            width="2.934"
            height="2.936"
            transform="translate(9.094 2.933)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_420"
            data-name="Rectangle 420"
            width="2.934"
            height="2.934"
            transform="translate(9.094 8.509)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_421"
            data-name="Rectangle 421"
            width="2.935"
            height="2.936"
            transform="translate(14.375 2.933)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_422"
            data-name="Rectangle 422"
            width="2.935"
            height="2.934"
            transform="translate(14.375 8.509)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_136"
            data-name="Line 136"
            x2="26.563"
            transform="translate(0 18.717)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default HotelsTabIcon;
