import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAppAttributes } from "./useAppAttributes";
import { CommunicationType } from "../enums/CommunicationType";
import { WidgetDispatchEvents, WidgetListeningEvents } from "../enums/WidgetEvents";
import { FormikValues, useFormik } from "formik";
import { dispatchCustomEvent } from "../services/widgetEventsService";

const DISPATCH_EVENT_TIMEOUT_MS = 50;

interface Params<T extends FormikValues> {
  form: ReturnType<typeof useFormik<T>>;
  isInitialized: boolean;
}

export const useSearchFormEvents = <T extends FormikValues>({ form, isInitialized }: Params<T>) => {
  const appAttributes = useAppAttributes();

  // Listeners
  useEffect(() => {
    if (appAttributes.communicationType !== CommunicationType.Event) return;
    const setValuesHandler = ({ detail }: CustomEvent<{ values: any; touched?: any }>) => {
      form.setValues(detail.values);
      if (detail.touched) {
        form.setTouched(detail.touched);
      }
    };

    window.addEventListener<any>(WidgetListeningEvents.SetValues, setValuesHandler);
    return () => {
      window.removeEventListener<any>(WidgetListeningEvents.SetValues, setValuesHandler);
    };
  }, [appAttributes.communicationType, form]);

  const debouncedDispatchCustomEvent = useDebouncedCallback(
    dispatchCustomEvent,
    DISPATCH_EVENT_TIMEOUT_MS
  );

  // Dispatchers
  useEffect(() => {
    if (appAttributes.communicationType !== CommunicationType.Event || !isInitialized) return;
    debouncedDispatchCustomEvent({
      widgetEvent: WidgetDispatchEvents.ValuesChanged,
      details: { values: form.values, errors: form.errors, touched: form.touched },
    });
  }, [
    appAttributes.communicationType,
    debouncedDispatchCustomEvent,
    form.errors,
    form.touched,
    form.values,
    isInitialized,
  ]);
};
