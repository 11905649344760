import { useAppAttributes } from "./useAppAttributes";
import { format as formatDate } from "date-fns";
import { de, el, enGB, es, fi, fr, it, ko, nb, nl, pl, sv, tr } from "date-fns/locale";
import { useCallback } from "react";
import { DateFormat, datePatterns } from "../constants/datePatterns";
import { usePushErrorLog } from "./usePushErrorLog";

let locales: { [key: string]: Locale } = { de, el, enGB, es, fi, fr, it, ko, nb, nl, pl, sv, tr };

export function useDateFormatter() {
  const logError = usePushErrorLog();
  const appAttributes = useAppAttributes();

  return useCallback(
    (date: Date | string, format: DateFormat | Array<DateFormat>, separator: string = " ") => {
      try {
        return datePatterns
          ? formatDate(
              new Date(date),
              typeof format === "string"
                ? datePatterns[format]
                : format.map((f) => datePatterns[f]).join(separator),
              {
                locale: locales[appAttributes.language.toLowerCase()] || locales.enGB,
              }
            )
          : "";
      } catch (error) {
        logError(error, {
          customMessage: `Error while trying to parse date: ${date} `,
        });
        return "Invalid date";
      }
    },
    [logError, appAttributes]
  );
}
