// HOME/depart.svg

const DateFrom = () => {
  return (
    <svg
      id="Group_2012"
      data-name="Group 2012"
      xmlns="http://www.w3.org/2000/svg"
      width="16.342"
      height="16.487"
      viewBox="0 0 16.342 16.487"
    >
      <defs>
        <clipPath id="flight-departure-date-clip-path">
          <rect
            id="Rectangle_442"
            data-name="Rectangle 442"
            width="16.342"
            height="16.487"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_2011" data-name="Group 2011" clipPath="url(#flights-departure-date-clip-path)">
        <path
          id="Rectangle_441"
          data-name="Rectangle 441"
          d="M1.254-.5H14.088a1.756,1.756,0,0,1,1.754,1.754V13.421a1.756,1.756,0,0,1-1.754,1.754H1.254A1.756,1.756,0,0,1-.5,13.421V1.254A1.756,1.756,0,0,1,1.254-.5ZM14.088,14.175a.755.755,0,0,0,.754-.754V1.254A.755.755,0,0,0,14.088.5H1.254A.755.755,0,0,0,.5,1.254V13.421a.755.755,0,0,0,.754.754Z"
          transform="translate(0.5 1.312)"
          fill="#11100d"
        />
        <path
          id="Line_143"
          data-name="Line 143"
          d="M.5,2h-1V0h1Z"
          transform="translate(8.171 0.645)"
          fill="#11100d"
        />
        <path
          id="Line_144"
          data-name="Line 144"
          d="M.5,2.647h-1V0h1Z"
          transform="translate(12.84)"
          fill="#11100d"
        />
        <path
          id="Line_145"
          data-name="Line 145"
          d="M.5,2.647h-1V0h1Z"
          transform="translate(3.502)"
          fill="#11100d"
        />
        <path
          id="Line_146"
          data-name="Line 146"
          d="M.5,2.647h-1V0h1Z"
          transform="translate(8.171)"
          fill="#11100d"
        />
        <path
          id="Line_147"
          data-name="Line 147"
          d="M10.672.5H0v-1H10.672Z"
          transform="translate(2.834 4.981)"
          fill="#11100d"
        />
        <path
          id="Line_148"
          data-name="Line 148"
          d="M9.206.5H0v-1H9.206Z"
          transform="translate(4.056 10.651)"
          fill="#11100d"
        />
        <path
          id="Line_149"
          data-name="Line 149"
          d="M1.668.5H0v-1H1.668Z"
          transform="translate(3.001 10.651)"
          fill="#11100d"
        />
        <path
          id="Line_150"
          data-name="Line 150"
          d="M1.668.5H0v-1H1.668Z"
          transform="translate(7.337 13.319)"
          fill="#11100d"
        />
        <path
          id="Line_151"
          data-name="Line 151"
          d="M1.668.5H0v-1H1.668Z"
          transform="translate(3.001 13.319)"
          fill="#11100d"
        />
        <path
          id="Line_152"
          data-name="Line 152"
          d="M1.668.5H0v-1H1.668Z"
          transform="translate(7.337 7.983)"
          fill="#11100d"
        />
        <path
          id="Line_153"
          data-name="Line 153"
          d="M1.668.5H0v-1H1.668Z"
          transform="translate(3.001 7.983)"
          fill="#11100d"
        />
        <path
          id="Path_1236"
          data-name="Path 1236"
          d="M11.353,13.754l-.707-.707,2.4-2.4-2.4-2.4.707-.707,3.1,3.1Z"
          fill="#11100d"
        />
      </g>
    </svg>
  );
};
export default DateFrom;
