import { Fragment, HTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import { Breakpoint } from "../../enums/Breakpoint";

interface Props extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  label: string;
  name: string;
  options: {
    label: string | JSX.Element;
    value: string;
  }[];
  value: any;
  disabled?: boolean;
  hideSelection?: boolean;
}

const Switcher = ({
  id,
  label,
  name,
  value,
  options,
  onChange,
  disabled,
  hideSelection,
}: Props) => {
  const selectedIndex = useMemo(
    () => options.findIndex((option) => option.value === value),
    [options, value]
  );

  return (
    <MainContainer aria-label={label} role="group">
      {options.map((option, index) => (
        <Fragment key={index}>
          <input
            checked={option.value === value}
            id={`${id || name}${index}`}
            name={name}
            onChange={onChange}
            type="radio"
            value={option.value}
            disabled={disabled}
          />
          <Section key={index} active={selectedIndex === index} htmlFor={`${id || name}${index}`}>
            <span>{option.label}</span>
          </Section>
        </Fragment>
      ))}
      {!hideSelection && (
        <MovingSelection
          disabled={disabled}
          totalItems={options.length}
          selectedIndex={selectedIndex}
        />
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  border-radius: 11px;
  border: 4px solid ${({ theme }) => theme.defaultBackground};
  background: ${({ theme }) => theme.defaultBackground};
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;

  @media ${Breakpoint.MinTablet} {
    border-radius: 21px;
  }

  input {
    display: none;
  }
`;

const Section = styled.label<{ active: boolean }>`
  background: transparent;
  color: ${({ theme, active }) =>
    active ? theme.form.switcher.activeTextColor : theme.form.switcher.inactiveTextColor};
  transition: all 0.4s ease-out;
  cursor: pointer;
  height: 28px;
  border-radius: 8px;
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 2px 8px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  z-index: 1;
`;

const MovingSelection = styled.span<{
  disabled?: boolean;
  totalItems: number;
  selectedIndex: number;
}>`
  position: absolute;
  top: 0;
  left: ${({ totalItems, selectedIndex }) => `calc((100% / ${totalItems}) * ${selectedIndex})`};
  height: 100%;
  background: ${({ theme }) => theme.form.switcher.activeItemBackgroundColor};
  width: ${({ totalItems }) => `calc(100% / ${totalItems})`};
  transition: left 0.4s ease-out;
  border-radius: 8px;

  @media ${Breakpoint.MinTablet} {
    border-radius: 18px;
  }
`;

export default Switcher;
