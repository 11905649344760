import { DateUtils } from "react-day-picker";
import { CustomDatePickerSource } from "../enums/CustomDatePicker";

export const isSelectingFirstDay = (
  day: Date,
  source: CustomDatePickerSource,
  allowSameDaySelection: boolean,
  fromDate?: Date,
  toDate?: Date
) => {
  const isBeforeOrSameAsFirstDay =
    !!fromDate &&
    (DateUtils.isDayBefore(day, fromDate) ||
      (!allowSameDaySelection && DateUtils.isSameDay(day, fromDate)));
  const isRangeSelected = source === CustomDatePickerSource.FROM_DATE && !!fromDate && !!toDate;
  return !fromDate || isBeforeOrSameAsFirstDay || isRangeSelected;
};
