import styled from "styled-components";

interface Props {
  inputTitle?: string;
  className?: string;
}

const MobileModalInputHeader = ({ inputTitle, className }: Props) => {
  return (
    <Container className={className}>
      <Title>{inputTitle}</Title>
    </Container>
  );
};

const Container = styled.div`
  padding: 15px 40px 23px 30px;
  font-family: ${({ theme }) => theme.regularFont};
`;

const Title = styled.div`
  font-size: 17px;
  line-height: 17px;
  font-family: ${({ theme }) => theme.heavyFont};
`;

export default MobileModalInputHeader;
