import styled from "styled-components";
import CalendarDropdown from "./components/CalendarDropdown";
import DoubleMonthDayPicker from "../DayPickers/DoubleMonthDayPicker/DoubleMonthDayPicker";
import CalendarDropdownIndicator from "./components/CalendarDropdownIndicator";
import { DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";

interface Props {
  isToOpen: boolean;
  disabledDays?: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  initialMonth: Date;
  onDayClick: (day: Date) => void;
  titleLabelKey?: string;
}

const DoubleMonthDropdown = ({
  isToOpen,
  disabledDays = DEFAULT_DISABLED_DAYS,
  fromDate,
  toDate,
  shouldShowRange,
  initialMonth,
  onDayClick,
  titleLabelKey,
}: Props) => {
  return (
    <StyledDropdown>
      <StyledIndicator isToOpen={isToOpen} />
      <DoubleMonthDayPicker
        onDayClick={onDayClick}
        disabledDays={disabledDays}
        fromDate={fromDate}
        toDate={toDate}
        shouldShowRange={shouldShowRange}
        initialMonth={initialMonth}
        titleLabelKey={titleLabelKey}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(CalendarDropdown)`
  top: 66px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledIndicator = styled(CalendarDropdownIndicator)<{ isToOpen: boolean }>`
  top: -13px;
  left: calc(50% ${({ isToOpen }) => (isToOpen ? "+" : "-")} 75px);
  transform: translate(-50%);
`;

export default DoubleMonthDropdown;
