const ChevronLeft = ({ stroke = "#000", strokeWidth = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.021"
      height="12.021"
      viewBox="0 0 12.021 12.021"
    >
      <path
        id="Rectangle_Copy_22"
        data-name="Rectangle Copy 22"
        d="M8,0V8H0"
        transform="translate(12.021 6.01) rotate(135)"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default ChevronLeft;
