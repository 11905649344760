import styled from "styled-components";
import { Breakpoint } from "../../enums/Breakpoint";
import { LayoutType } from "../../enums/LayoutType";

const BaseContainer = styled.div<{ layout?: LayoutType }>`
  box-sizing: border-box;
  margin: auto;
  width: 100%;

  @media ${Breakpoint.MaxMobile} {
    ${({ layout }) =>
      layout === LayoutType.External
        ? ` width: 100%;
            max-width: 100%;`
        : ` width: calc(100% - 40px);
            max-width: calc(100% - 40px);`}
  }

  @media ${Breakpoint.MinTablet} {
    min-width: 728px;
    max-width: 728px;
  }

  @media ${Breakpoint.MinDesktop} {
    min-width: 1066px;
    max-width: 1066px;
  }

  @media ${Breakpoint.MinLargeDesktop} {
    min-width: 1240px;
    max-width: 1240px;
  }
`;

export default BaseContainer;
