// HOME/passenger.svg

const Passenger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.814"
      height="16.488"
      viewBox="0 0 15.814 16.488"
    >
      <g id="Group_5" data-name="Group 5" transform="translate(-1074.303 -325.948)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M4.04-.5A4.54,4.54,0,1,1-.5,4.04,4.545,4.545,0,0,1,4.04-.5Zm0,8.08A3.54,3.54,0,1,0,.5,4.04,3.544,3.544,0,0,0,4.04,7.58Z"
          transform="translate(1078.17 326.448)"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M14.814,7.907H0a.5.5,0,0,1-.5-.5,7.907,7.907,0,1,1,15.814,0A.5.5,0,0,1,14.814,7.907Zm-14.3-1H14.3a6.907,6.907,0,0,0-13.778,0Z"
          transform="translate(1074.803 334.529)"
        />
      </g>
    </svg>
  );
};

export default Passenger;
