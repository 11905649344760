import { useCallback } from "react";
import { request } from "./request";
import { ApiAction } from "../api/actions/ApiAction";
import { useAppAttributes } from "../hooks/useAppAttributes";
import { usePushErrorLog } from "../hooks/usePushErrorLog";

const DEFAULT_LANGUAGE = "NL";

export function useRequest() {
  const appAttributes = useAppAttributes();
  const logError = usePushErrorLog();

  return useCallback(
    <R>(action: ApiAction): Promise<R> => {
      return new Promise((resolve, reject) =>
        request(appAttributes?.language || DEFAULT_LANGUAGE)(action)
          .then((response) => resolve(response as R))
          .catch((error: any) => {
            logError(error);
            reject(error);
          })
      );
    },
    [appAttributes?.language, logError]
  );
}
