import { useCallback, useMemo } from "react";
import { useTranslation as useI18NTranslation } from "react-i18next";
import { I18NNamespace } from "../enums/I18N";
import useMedia from "../hooks/useMedia";
import { Breakpoint } from "../enums/Breakpoint";

export type TFunction = (labelKey: string, options?: object) => string;
var missingLabels: string[] = [];

export const useTranslation = (mobileBreakpoint?: Breakpoint) => {
  const { i18n, t } = useI18NTranslation();

  const properBreakpoint = useMemo(() => {
    return !!mobileBreakpoint ? mobileBreakpoint : Breakpoint.MaxMobile;
  }, [mobileBreakpoint]);

  const isMobile = useMedia(properBreakpoint);

  const enhancedT: TFunction = useCallback(
    (labelKey: string, options?: object) => {
      const loweredLabelKey = labelKey && labelKey.toLowerCase();
      if (!loweredLabelKey || loweredLabelKey.match(/[^0-9a-zA-Z_]/i)) {
        return labelKey;
      }

      const translation = t(loweredLabelKey, {
        ...options,
        defaultValue: null,
        interpolation: { escapeValue: false },
        ns: isMobile ? I18NNamespace.Mobile : I18NNamespace.General,
      });
      if (!missingLabels.includes(labelKey)) missingLabels.push(labelKey);
      return translation === null ? labelKey : translation;
    },
    [t, isMobile]
  );

  return {
    i18n,
    t: enhancedT,
  };
};
