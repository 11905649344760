import { DatePatterns } from "./../types/DatePatterns";

export const datePatterns: DatePatterns = {
  apiDate: "yyyy-MM-dd",
  date: "dd/MM/yyyy",
  dateTime: "dd/MM/yyyy HH:mm",
  shortDateTime: "dd/MM HH:mm",
  shortDate: "dd/MM",
  namedDate: "dd MMMM yyyy",
  namedDateTime: "dd MMMM yyyy HH:mm",
  namedShortDate: "dd MMM",
  namedShortDateWithDay: "ddd dd MMM",
  time: "HH:mm",
  monthName: "MMMM",
  monthNameShort: "MMM",
  weekDayName: "eeee",
  weekDayNameShort: "EEE",
  fullYear: "yyyy",
  shortDateWithYear: "dd/MM/yy",
  externalLayoutNamedShortDateWithDay: "EEE dd MMM",
  doubleLetterWeekday: "EEEEEE",
  shortMonth: "MMM",
};

export type DateFormat =
  | "apiDate"
  | "date"
  | "dateTime"
  | "shortDateTime"
  | "shortDate"
  | "namedDate"
  | "namedDateTime"
  | "namedShortDate"
  | "namedShortDate"
  | "namedShortDateWithDay"
  | "time"
  | "monthName"
  | "monthNameShort"
  | "weekDayName"
  | "weekDayName"
  | "weekDayNameShort"
  | "fullYear"
  | "shortDateWithYear"
  | "externalLayoutNamedShortDateWithDay"
  | "doubleLetterWeekday"
  | "shortMonth";
