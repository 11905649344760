import { FormikErrors, FormikTouched } from "formik";
import { lazy, Suspense, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Breakpoint } from "../../../../enums/Breakpoint";
import useMedia from "../../../../hooks/useMedia";
import { AutocompleteValue } from "./types";
import { useTranslation } from "../../../../i18n";
import { HotelsSearchParams } from "../../../../types/HotelsSearchParams";
import { useWizardContext } from "../../WizardContext";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
const DesktopAutocomplete = lazy(() => import("./DesktopAutocomplete"));
const MobileAutocomplete = lazy(() => import("./MobileAutocomplete"));

interface Props {
  errors: FormikErrors<HotelsSearchParams>;
  touched: FormikTouched<HotelsSearchParams>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  values: HotelsSearchParams;
}

const AutocompleteField = ({ errors, onChange, touched, values }: Props) => {
  const { t } = useTranslation();
  const inputLabel = useMemo(() => t("tix_search_form_location"), [t]);
  const titleLabel = useMemo(() => t("tix_search_form_hotels_autocomplete_title"), [t]);
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);

  const { startWizard, inputRefs } = useWizardContext();

  const error = useMemo(() => {
    if (touched.locationCode) {
      return errors.locationCode;
    }
  }, [errors.locationCode, touched.locationCode]);

  const selectSuggestion = useCallback(
    (suggestion: AutocompleteValue, selectedByHand?: boolean) => {
      onChange({
        locationCode: suggestion.code,
        locationType: suggestion.type,
        locationName: suggestion.name,
      });
      dispatchCustomEvent({
        widgetEvent: WidgetGATriggerEvents.HotelsDestinationSelected,
        details: { suggestion },
      });
      if ((selectedByHand || isMaxMobile) && !values.checkInDate) {
        startWizard();
        inputRefs.current.checkInDate?.focus();
      }
    },
    [inputRefs, isMaxMobile, onChange, startWizard, values.checkInDate]
  );

  const value = useMemo(() => {
    return {
      code: values.locationCode,
      name: values.locationName || "",
      type: values.locationType,
    };
  }, [values.locationCode, values.locationName, values.locationType]);

  return (
    <Container>
      <Suspense fallback={null}>
        {isMaxMobile ? (
          <MobileAutocomplete
            error={error}
            inputLabel={inputLabel}
            inputTitle={titleLabel}
            onSelect={selectSuggestion}
            value={value}
          />
        ) : (
          <DesktopAutocomplete
            error={error}
            inputLabel={inputLabel}
            inputTitle={titleLabel}
            onSelect={selectSuggestion}
            value={value}
          />
        )}
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default AutocompleteField;
