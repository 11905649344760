import { format, isBefore, isValid, parse, parseJSON, startOfDay } from "date-fns";
import qs from "qs";
import { CabinClassCode } from "../enums/CabinClassCode";

const API_DATE_FORMAT = "yyyy-MM-dd";

export interface FlightsSearchCriteriaBound {
  departureCode: string;
  departureDate?: Date;
  destinationCode: string;
}

export class FlightsSearchCriteria {
  adults: number;
  children: number;
  infants: number;
  bounds: FlightsSearchCriteriaBound[];
  cabinClass: CabinClassCode;
  campaignId?: number | string;
  affiliateId?: number;
  preferredFlightId?: string;

  constructor(searchParams: { [key: string]: any }) {
    if (searchParams.travellers?.length) {
      const [adults, children, infants] = searchParams.travellers.split(".");
      this.adults = Number(adults) || 1;
      this.children = Number(children) || 0;
      this.infants = Number(infants) || 0;
    } else {
      this.adults = Number(searchParams.adults) || 1;
      this.children = Number(searchParams.children) || 0;
      this.infants = Number(searchParams.infants) || 0;
    }
    this.bounds = Array.isArray(searchParams.bounds)
      ? searchParams.bounds.map((bound, index) => {
          if (index < searchParams.bounds.length - 1) {
            const nextDepartureDate = this.parseDateParam(
              searchParams.bounds[index + 1].departureDate
            );
            const currentDepartureDate = this.parseDateParam(bound.departureDate);
            if (
              !!currentDepartureDate &&
              !!nextDepartureDate &&
              isBefore(nextDepartureDate, currentDepartureDate)
            ) {
              bound.departureDate = undefined;
              searchParams.bounds[index + 1].departureDate = undefined;
            }
          }
          return {
            departureCode: bound.departureCode,
            departureDate: this.parseDateParam(bound.departureDate),
            destinationCode: bound.destinationCode,
          };
        })
      : [];

    this.cabinClass = (searchParams.cabinClass as CabinClassCode) || CabinClassCode.Economy;
    this.campaignId = searchParams.campaignId;
    this.affiliateId = Number(searchParams.affiliateId) || undefined;
    this.preferredFlightId = searchParams.preferredFlightId;
  }

  toQueryString(): string {
    return qs.stringify(this.getFormattedSearchCriteria());
  }

  private parseDateParam(date?: string | Date) {
    if (date) {
      if (typeof date === "string") {
        const normalParse = parse(date, API_DATE_FORMAT, new Date());
        return isValid(normalParse) ? normalParse : startOfDay(parseJSON(date));
      }
      return date;
    }
  }

  private getFormattedSearchCriteria() {
    const { bounds, ...params } = this;
    return {
      ...params,
      bounds: bounds.map(({ departureDate, ...bound }) => ({
        ...bound,
        ...(departureDate instanceof Date
          ? { departureDate: format(departureDate, API_DATE_FORMAT) }
          : {}),
      })),
    };
  }
}
