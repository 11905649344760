import { useCallback, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { AnimatedRoundedSelectSize } from "../../enums/AnimatedRoundedSelectSize";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import DropdownIndicator from "./DropdownIndicator";
import { useAppAttributes } from "../../hooks/useAppAttributes";
import { LayoutType } from "../../enums/LayoutType";

const ANIMATION_TIME = 500;

interface Props {
  className?: string;
  size?: AnimatedRoundedSelectSize;
  placeholder: string;
  leftIcon?: JSX.Element;
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  showValueIfSelected?: boolean;
  centerValue?: boolean;
  nonScrollableOptionBox?: boolean;
  layout?: LayoutType;
}

const PIXEL_VALUES_FOR_SIZE = {
  [AnimatedRoundedSelectSize.ExtraSmall]: 32,
  [AnimatedRoundedSelectSize.Small]: 44,
  [AnimatedRoundedSelectSize.Medium]: 48,
  [AnimatedRoundedSelectSize.ExternalLayoutStandard]: 40,
};

const AnimatedRoundedSelect = ({
  size = AnimatedRoundedSelectSize.Medium,
  placeholder,
  leftIcon,
  options,
  value,
  onChange,
  showValueIfSelected = true,
  centerValue = false,
  nonScrollableOptionBox = false,
  layout,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const appAttributes = useAppAttributes();
  const selectRef = useRef<HTMLDivElement>(null);
  const theme = useTheme() as any;
  const animationTimeoutRef = useRef<NodeJS.Timeout>();
  const isOpenRef = useRef(isOpen);
  const animationInProgressRef = useRef(animationInProgress);

  const changeOpenState = useCallback(() => {
    animationInProgressRef.current = true;
    setAnimationInProgress(true);
    isOpenRef.current = !isOpen;
    setIsOpen(!isOpen);
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }
    animationTimeoutRef.current = setTimeout(() => {
      animationInProgressRef.current = false;
      setAnimationInProgress(false);
    }, ANIMATION_TIME);
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [isOpen]);

  useOutsideClick(selectRef, () => {
    if (isOpenRef.current) changeOpenState();
  });

  const selectOption = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const valueToShow = useMemo(() => {
    if (!!value) {
      const foundOption = options.find((o) => o.value === value);
      return showValueIfSelected ? foundOption?.label || placeholder : placeholder;
    }
    return placeholder;
  }, [value, placeholder, options, showValueIfSelected]);

  return (
    <MainContainer
      className={className}
      ref={selectRef}
      size={size}
      onClick={() => changeOpenState()}
      layout={appAttributes.layout}
    >
      <AbsoluteContainer
        layout={layout}
        size={size}
        isOpen={isOpen}
        animationInProgress={animationInProgress}
      >
        <ValueContainer
          size={size}
          animationInProgress={animationInProgress}
          isOpen={isOpen}
          centerValue={centerValue}
          onClick={() => changeOpenState()}
        >
          <LeftContainer centerValue={centerValue}>
            {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
            {valueToShow}
          </LeftContainer>
          <IndicatorContainer isOpen={isOpen}>
            <DropdownIndicator
              animationTime={ANIMATION_TIME}
              color={theme.defaultColor}
              pointUp={isOpen}
            />
          </IndicatorContainer>
        </ValueContainer>
        <OptionsContainer
          size={size}
          animationInProgress={animationInProgress}
          isOpen={isOpen}
          numberOfOptions={options.length}
          nonScrollableOptionBox={nonScrollableOptionBox}
        >
          {options.map((v, i) => (
            <Option
              layout={layout}
              key={i}
              isSelected={value === v.value}
              onClick={() => selectOption(v.value)}
            >
              {v.label}
            </Option>
          ))}
        </OptionsContainer>
      </AbsoluteContainer>
    </MainContainer>
  );
};

const IndicatorContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => (isOpen ? "margin-bottom: -4px;" : "margin-bottom: 4px;")}
  transition: margin-bottom ${ANIMATION_TIME}ms;
`;

const IconContainer = styled.div`
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div<{ centerValue: boolean }>`
  display: flex;
  align-items: center;
  ${({ centerValue }) => centerValue && `padding-right: 10px;`}
`;

const MainContainer = styled.div<{ size: AnimatedRoundedSelectSize; layout?: LayoutType }>`
  font-size: ${({ layout }) => (layout === LayoutType.External ? "11px;" : "14px;")}
  ${({ layout, theme }) => layout === LayoutType.External && `font-family: ${theme.heavyFont};`}
  display: flex;
  position: relative;
  min-height: ${({ size }) => `${PIXEL_VALUES_FOR_SIZE[size]}px`};
  width: 100%;
  cursor: pointer;

  font-family: ${({ theme }) => theme.regularFont};
`;

const AbsoluteContainer = styled.div<{
  size: AnimatedRoundedSelectSize;
  isOpen: boolean;
  animationInProgress: boolean;
  layout?: LayoutType;
}>`
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.defaultColor};
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 3;
  border-radius: ${({ size, layout, theme }) =>
    layout === LayoutType.External
      ? theme.ovalBorderRadius
      : `${PIXEL_VALUES_FOR_SIZE[size] / 2}px`};
  ${({ layout, theme }) =>
    layout === LayoutType.External &&
    `
     border: none;
    background: ${theme.darkBackground};
    `}
`;

const ValueContainer = styled.div<{
  size: AnimatedRoundedSelectSize;
  isOpen: boolean;
  animationInProgress: boolean;
  centerValue: boolean;
}>`
  display: flex;
  align-items: center;
  height: ${({ size }) => `${PIXEL_VALUES_FOR_SIZE[size] - 2}px`};
  margin: 0 20px;
  ${({ isOpen, animationInProgress, theme }) =>
    (isOpen || animationInProgress) && `border-bottom: 1px solid ${theme.defaultColor};`}
  justify-content: ${({ centerValue }) => (centerValue ? "center" : "space-between")};
`;

const OptionsContainer = styled.div<{
  isOpen: boolean;
  size: AnimatedRoundedSelectSize;
  animationInProgress: boolean;
  nonScrollableOptionBox: boolean;
  numberOfOptions: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: ${({ isOpen, nonScrollableOptionBox, numberOfOptions, size }) =>
    isOpen
      ? nonScrollableOptionBox
        ? `${numberOfOptions * PIXEL_VALUES_FOR_SIZE[size]}px`
        : "256px"
      : "0px"};
  overflow: ${({ isOpen, animationInProgress }) =>
    isOpen && !animationInProgress ? "auto" : "hidden"};
  transition: max-height ${ANIMATION_TIME}ms;
`;

const Option = styled.div<{ isSelected: boolean; layout?: LayoutType }>`
  min-height: 32px;
  display: block;
  line-height: 32px;
  width: calc(100% - 40px);
  padding: 0 20px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: ${({ isSelected, theme }) =>
    isSelected
      ? theme.form.animatedRoundedSelect.selectedOptionBackground
      : theme.form.animatedRoundedSelect.defaultBackgroundColor};
  &:hover {
    ${({ isSelected, theme }) =>
      !isSelected && `background: ${theme.form.animatedRoundedSelect.hoveredOptionBackground};`}
  }

  ${({ layout }) =>
    layout === LayoutType.External &&
    `
    min-height: 17px;;
    line-height: 17px;
    margin: 0;
    padding: 12px 20px;
  `}
`;

export default AnimatedRoundedSelect;
