// +.svg

const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.247"
      height="12.246"
      viewBox="0 0 12.247 12.246"
    >
      <g id="Group_2271" data-name="Group 2271" transform="translate(-335.899 -138.379)">
        <path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M7.9,0,0,8"
          transform="translate(342.057 138.879) rotate(45)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_5-2"
          data-name="Stroke 5"
          d="M8,7.9,0,0"
          transform="translate(341.988 138.879) rotate(45)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Plus;
