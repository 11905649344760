import styled from "styled-components";

const CalendarDropdownIndicator = styled.div<{
  alignRight?: boolean;
  hasTwoDates?: boolean;
  className?: string;
}>`
  opacity: 1;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 14px 13px;
  border-color: transparent transparent ${({ theme }) => theme.defaultBackground} transparent;
  background: transparent;
`;

export default CalendarDropdownIndicator;
