import { useCallback, useContext } from "react";
import SearchFormWithFocusScrollContext from "../contexts/SearchFormWithFocusScrollContext";

/**
 * This hook should be used with `SearchFormWithFocusScrollContext`.
 * Hook allows to scroll to the bottom of any kind of dropdown 
 * (basically any element passed to `handleScrollToSearchFormIfNeeded` function)
 * while staying in range of fully visibility of the search form (element stored in context).
 * 
 * 1. Check if top of search form is visible
 *    - IF NOT then scroll to the top of form and END
 * 2. Check if bottom of given element is visible
 *    - IF NOT then check if scrolling to the bottom would cut the top of the search form
 *      - IF yes then scroll only to the top of the search form
 *      - IF no then scroll as much as needed to make bottom of the element visible
 */

const MARGIN_TO_EDGE = 5;

const getElementVisibilityInViewport = (
  element: HTMLElement
): { top: boolean; bottom: boolean; all: boolean } => {
  const rect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const top = rect.top >= 0;
  const bottom = rect.bottom <= viewportHeight;
  const all = top && bottom;
  return { top, bottom, all };
};

export const useSearchFormWithFocusScroll = () => {
  const context = useContext(SearchFormWithFocusScrollContext);

  const handleScrollToSearchFormIfNeeded = useCallback(
    (element: HTMLElement | null) => {
      if (!context.searchFormElementRef?.current || !element) return;
      const rect = context.searchFormElementRef.current.getBoundingClientRect();
      const topOfSearchFormPosition = window.scrollY + rect.top - MARGIN_TO_EDGE;
      if (!getElementVisibilityInViewport(context.searchFormElementRef.current).top) {
        window.scrollTo({ top: topOfSearchFormPosition });
        return;
      }

      if (!getElementVisibilityInViewport(element).bottom) {
        const elementRect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const deltaToMakeElementFullyVisible = elementRect.bottom + MARGIN_TO_EDGE - viewportHeight;
        const newOffsetTop = window.scrollY + deltaToMakeElementFullyVisible;
        window.scrollTo({
          top: Math.min(newOffsetTop, topOfSearchFormPosition),
        });
      }
    },
    [context.searchFormElementRef]
  );

  return { handleScrollToSearchFormIfNeeded };
};
