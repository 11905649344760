import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useRequest } from "../api";
import { usePushErrorLog } from "./usePushErrorLog";
import {
  getAutocompleteAirports,
  GetAutocompleteAirportsResponse,
} from "../api/actions/getAutocompleteAirports";
import { AutocompleteAirport } from "../types/Autocomplete";
import { dispatchCustomEvent } from "../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../enums/WidgetEvents";

const LOAD_DELAY = 600;

export const useAirportAutocompleteSuggestions = () => {
  const request = useRequest();
  const logError = usePushErrorLog();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<AutocompleteAirport[]>([]);

  const fetchSuggestions = useDebouncedCallback(async (query: string) => {
    setIsLoading(true);
    try {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.AutocompleteAirportRequest });
      const suggestions = await request<GetAutocompleteAirportsResponse>(
        getAutocompleteAirports({
          query,
        })
      );
      setSuggestions(suggestions);
    } catch (error) {
      logError(error, {
        customMessage:
          "Error during fetching airports autocomplete suggestions",
      });
    } finally {
      setIsLoading(false);
    }
  }, LOAD_DELAY);

  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, [setSuggestions]);

  return {
    clearSuggestions,
    fetchSuggestions,
    isLoading,
    suggestions,
  };
};
