import styled from "styled-components";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import Mk1Typography from "../../../../shared/Mk1Typography";

interface Props extends AutocompleteAirport {}

const Suggestion = ({ name, region }: Props) => {
  return (
    <Container>
      <Title isHeavy={true}>{name}</Title>
      <Description>{region}</Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  padding: 5px 0;
  font-family: ${({ theme }) => theme.regularFont};
`;

const Title = styled(Mk1Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Description = styled(Mk1Typography).attrs({ defaultVariant: "small" })`
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default Suggestion;
