import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import { I18NNamespace } from "../enums/I18N";
import ContentApiBackend from "./ContentApiBackend";
import labels from "./labels.json";
import i18next from "i18next";

async function createI18N(language: string, namespace: I18NNamespace) {
  await i18next
    .use(ChainedBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        backends: [LocalStorageBackend, ContentApiBackend],
        backendOptions: [
          {
            expirationTime: 60 * 60 * 1000,
          },
          {
            labels,
          },
        ],
      },
      ns: [I18NNamespace.General, I18NNamespace.Mobile],
      defaultNS: namespace,
      interpolation: {
        prefix: "{",
        suffix: "}",
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      fallbackLng: language,
      lng: language,
      initImmediate: false,
      react: {
        wait: true,
        useSuspense: true,
        bindI18n: "languageChanged",
      },
      saveMissing: true,
    });
  return i18next;
}

export default createI18N;
