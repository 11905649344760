import { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import Mk1Typography from "../../../shared/Mk1Typography";
import Button from "../../../shared/Button";
import ChildAgeSelect from "./ChildAgeSelect";
import {
  MAX_CHILD_AGE,
  MAX_NUMBER_OF_ADULTS,
  MAX_NUMBER_OF_CHILDREN,
  MIN_CHILD_AGE,
  MIN_NUMBER_OF_ADULTS,
  MIN_NUMBER_OF_CHILDREN,
} from "../../../../constants/hotelConstants";
import { useTranslation } from "../../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";

interface Props {
  areChildrenAgeTouched: boolean;
  onChange: (value: { adults: number; childrenAge: (number | null)[] }) => void;
  roomIndex: number;
  values: { adults: number; childrenAge: (number | null)[] };
}

const RoomField = ({ areChildrenAgeTouched, onChange, roomIndex, values }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // Adults
  const disabledMaxAdults = useMemo(() => values.adults >= MAX_NUMBER_OF_ADULTS, [values.adults]);
  const disabledMinAdults = useMemo(() => values.adults <= MIN_NUMBER_OF_ADULTS, [values.adults]);
  const incrementNumberOfAdults = useCallback(() => {
    dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchAdultAdded });
    onChange({ ...values, adults: values.adults + 1 });
  }, [onChange, values]);
  const decrementNumberOfAdults = useCallback(() => {
    dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchAdultRemoved });
    onChange({ ...values, adults: values.adults - 1 });
  }, [onChange, values]);

  // Children
  const disabledMaxChildren = useMemo(
    () => values.childrenAge.length >= MAX_NUMBER_OF_CHILDREN,
    [values.childrenAge]
  );
  const disabledMinChildren = useMemo(
    () => values.childrenAge.length <= MIN_NUMBER_OF_CHILDREN,
    [values.childrenAge]
  );
  const incrementNumberOfChildren = useCallback(() => {
    dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchChildAdded });
    onChange({ ...values, childrenAge: [...values.childrenAge, null] });
  }, [onChange, values]);
  const decrementNumberOfChildren = useCallback(() => {
    dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchChildRemoved });
    onChange({ ...values, childrenAge: values.childrenAge.slice(0, -1) });
  }, [onChange, values]);
  const changeChildAge = useCallback(
    (index: number) => (updatedAge: number | null) => {
      dispatchCustomEvent({
        widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchChildAgeSelected,
        details: { age: updatedAge },
      });
      onChange({
        ...values,
        childrenAge: values.childrenAge.map((age, childIndex) =>
          index === childIndex ? updatedAge : age
        ),
      });
    },
    [onChange, values]
  );

  const hasChildError = useCallback(
    (childAge: number | null) => {
      return areChildrenAgeTouched && childAge === null;
    },
    [areChildrenAgeTouched]
  );

  return (
    <div>
      <Row>
        <TitleBox>
          <Text isHeavy>{t("tix_search_form_adults")}</Text>
          <AgeText>{`${t("tix_search_form_age")} ${MAX_CHILD_AGE + 1}+`}</AgeText>
        </TitleBox>
        <ButtonsContainer>
          <IconButton disabled={disabledMinAdults} onlyIcon onClick={decrementNumberOfAdults}>
            <FontAwesomeIcon
              icon={faMinus}
              color={disabledMinAdults ? theme.disabledColor : theme.defaultColor}
            />
          </IconButton>
          <Value>{values.adults.toString()}</Value>
          <IconButton disabled={disabledMaxAdults} onlyIcon onClick={incrementNumberOfAdults}>
            <FontAwesomeIcon
              icon={faPlus}
              color={disabledMaxAdults ? theme.disabledColor : theme.defaultColor}
            />
          </IconButton>
        </ButtonsContainer>
      </Row>
      <ChildrenRow>
        <TitleBox>
          <Text isHeavy>{t("tix_search_form_children")}</Text>
          <AgeText>{`${t("tix_search_form_age")} ${MIN_CHILD_AGE}-${MAX_CHILD_AGE}`}</AgeText>
        </TitleBox>
        <ButtonsContainer>
          <IconButton disabled={disabledMinChildren} onlyIcon onClick={decrementNumberOfChildren}>
            <FontAwesomeIcon
              icon={faMinus}
              color={disabledMinChildren ? theme.disabledColor : theme.defaultColor}
            />
          </IconButton>
          <Value>{values.childrenAge.length.toString()}</Value>
          <IconButton disabled={disabledMaxChildren} onlyIcon onClick={incrementNumberOfChildren}>
            <FontAwesomeIcon
              icon={faPlus}
              color={disabledMaxChildren ? theme.disabledColor : theme.defaultColor}
            />
          </IconButton>
        </ButtonsContainer>
      </ChildrenRow>
      {values.childrenAge.length > 0 &&
        values.childrenAge.map((childAge, index) => {
          const hasError = hasChildError(childAge);
          return (
            <ChildAgeSelectContainer hasError={hasError} key={index}>
              <ChildAgeSelect
                childIndex={index + 1}
                hasError={hasError}
                onChange={changeChildAge(index)}
                value={childAge}
              />
            </ChildAgeSelectContainer>
          );
        })}
    </div>
  );
};

const AgeText = styled(Mk1Typography)`
  font-size: 11px;
  line-height: 17px;
  color: ${({ theme }) => theme.greyColor};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ChildrenRow = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled(Button)`
  height: 34px;
  width: 34px;
  max-height: 34px;
  max-width: 34px;
  padding: 0;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  background: ${({ theme }) => theme.darkBackground};

  &:disabled {
    opacity: 1;
    background: ${({ theme }) => theme.darkBackground};
  }

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.disabledColor};
  }

  svg {
    width: 13px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled(Mk1Typography)`
  line-height: 15px;
  && {
    font-family: ${({ theme, isHeavy }) => (isHeavy ? theme.heavyFont : theme.regularFont)};
  }
`;

const Value = styled(Mk1Typography)`
  font-family: ${({ theme }) => theme.heavyFont};
  display: inline-flex;
  justify-content: center;
  width: 40px;
`;

const ChildAgeSelectContainer = styled.div<{ hasError: boolean }>`
  &:not(:last-child) {
    margin-bottom: ${({ hasError }) => (hasError ? 0 : "10px")};
  }
`;

export default RoomField;
