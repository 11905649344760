import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { CABIN_CLASS_LABELS } from "../../../constants/flightsConstants";
import { Breakpoint } from "../../../enums/Breakpoint";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useMedia from "../../../hooks/useMedia";
import { FlightsSearchFormValues } from "../../../types/FlightsSearchFormValues";
import { capitalize } from "../../../utils/stringUtils";
import PassengerIcon from "../../../customIcons/Passenger";
import ExtendedSearchFields from "./ExtendedSearchFields";
import MobileModal from "../../shared/MobileModal";
import Button from "../../shared/Button";
import { useTranslation } from "../../../i18n";
import { InputKey, useWizardContext } from "../WizardContext";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import { useSearchFormWithFocusScroll } from "../../../hooks/useSearchFormWithFocusScroll";

interface Props {
  onChange: (valuesChanges: Record<string, any>, shouldValidate?: boolean) => any;
  onSubmit: () => void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
}

const ExtendedFlightSearch = ({ onChange, onSubmit, values, scrollControlled }: Props) => {
  const { isWizard, stopWizard, mobileModalAnimations, setInputRef } = useWizardContext();
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();

  const [isOpened, setIsOpened] = useState(false);
  const close = useCallback(() => {
    if (isOpened) {
      setIsOpened(false);
      stopWizard();
    }
  }, [isOpened, stopWizard]);

  const dropdownClose = useCallback(() => {
    if (isMinTablet) {
      close();
    }
  }, [close, isMinTablet]);
  useOutsideClick(ref, dropdownClose);

  // Button label
  const totalPassengers = useMemo(
    () => values.adults + values.children + values.infants,
    [values.adults, values.children, values.infants]
  );
  const label = useMemo(
    () =>
      `${capitalize(t("tix_search_form_passengers"))} ${totalPassengers}, ${capitalize(
        t(CABIN_CLASS_LABELS[values.cabinClass])
      )}`,
    [t, totalPassengers, values.cabinClass]
  );

  useEffect(() => {
    if (isOpened) {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.OpenFlightExtendedSearch });
      handleScrollToSearchFormIfNeeded(dropdownRef.current);
    }
  }, [handleScrollToSearchFormIfNeeded, isOpened]);

  const submitAction = useMemo(() => (isWizard ? onSubmit : close), [close, onSubmit, isWizard]);

  return (
    <Container id="extended-flight-search" ref={ref}>
      <DropdownButton
        ref={setInputRef(InputKey.ExtendedSearch)}
        onClick={() => setIsOpened(!isOpened)}
        title={label}
        type="button"
      >
        <PassengerIcon />
        <Label>{label}</Label>
      </DropdownButton>
      {isMinTablet ? (
        isOpened && (
          <Dropdown ref={dropdownRef}>
            <DropdownIndicator />
            <ExtendedSearchFields onChange={onChange} values={values} />
            <ReadyButton onClick={submitAction}>
              {t(isWizard ? "tix_search_form_search_flights" : "tix_search_form_ready")}
            </ReadyButton>
          </Dropdown>
        )
      ) : (
        <MobileModal
          action={{
            label: t(isWizard ? "tix_search_form_search_flights" : "tix_search_form_continue"),
            onClick: submitAction,
          }}
          animations={mobileModalAnimations}
          headerTitle={t("tix_search_form_passengers")}
          headerIcon={<PassengerIcon />}
          isOpened={isOpened}
          onClose={close}
          scrollControlled={scrollControlled}
          isWizard={isWizard}
        >
          <MobileFieldsContainer>
            <ExtendedSearchFields onChange={onChange} values={values} />
          </MobileFieldsContainer>
        </MobileModal>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    margin-bottom: 9px;
    width: 50%;
  }
`;

const DropdownButton = styled.button`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border-radius: 28px;
  border: none;
  color: ${({ theme }) => theme.defaultColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 14px;
  height: 44px;
  padding: 0 20px 0 47px;
  position: relative;
  text-align: left;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    font-size: 14px;
    padding: 0 20px 0 50px;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    height: 48px;
    border-radius: 0 28px 28px 0;
    padding: 0 10px 0 34.5px;
  }

  svg {
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: 10px;
    }
  }
`;

const Label = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MobileFieldsContainer = styled.div`
  padding: 20px;
`;

const Dropdown = styled.div`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  box-sizing: border-box;
  left: 18px;
  padding: 22px 20px 20px 20px;
  position: absolute;
  right: unset;
  top: calc(44px + 18px);
  width: calc(100% - 18px - 20px);
  z-index: 10;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    top: calc(48px + 18px);
  }
`;

const DropdownIndicator = styled.div`
  opacity: 1;
  position: absolute;
  top: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 14px 13px;
  border-color: transparent transparent ${({ theme }) => theme.defaultBackground} transparent;
  background: transparent;
  left: 50%;
  transform: translateX(-50%);
`;

const ReadyButton = styled(Button)`
  height: 48px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.flightSearchForm.buttonColor};
`;

export default ExtendedFlightSearch;
