import styled from "styled-components";

const DEFAULT_ANIMATION_TIME = 500;

const DropdownIndicator = styled.div<{ animationTime?: number; color: string; pointUp?: boolean }>`
  width: 6.5px;
  height: 6.5px;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0) ${({ pointUp }) => pointUp && "rotate(180deg)"};
  border-bottom: 1px solid ${({ color }) => color};
  border-right: 1px solid ${({ color }) => color};
  opacity: 1;
  transition: transform
    ${({ animationTime }) =>
      `${typeof animationTime === "number" ? animationTime : DEFAULT_ANIMATION_TIME}ms`};
`;

export default DropdownIndicator;
