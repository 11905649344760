import { ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import RadioGroupField from "../../shared/RadioGroupField";
import { CABIN_CLASS_LABELS } from "../../../constants/flightsConstants";
import { CabinClassCode } from "../../../enums/CabinClassCode";
import { AnimatedRoundedSelectSize } from "../../../enums/AnimatedRoundedSelectSize";
import { Breakpoint } from "../../../enums/Breakpoint";
import useMedia from "../../../hooks/useMedia";
import { FlightsSearchFormValues } from "../../../types/FlightsSearchFormValues";
import { capitalize } from "../../../utils/stringUtils";
import AnimatedRoundedSelect from "../../shared/AnimatedRoundedSelect";
import PassengersField from "./PassengersField";
import { useTranslation } from "../../../i18n";
import { LayoutType } from "../../../enums/LayoutType";
import Mk1Typography from "../../shared/Mk1Typography";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";

const MAX_PASSENGERS = 9;

interface Props {
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): any;
  values: FlightsSearchFormValues;
}

const ExtendedSearchFields = ({ onChange, values }: Props) => {
  const { t } = useTranslation();
  const isMinTablet = useMedia(Breakpoint.MinTablet);

  // Max passengers validator
  const maxAdults = useMemo(
    () => MAX_PASSENGERS - (values.children + values.infants),
    [values.children, values.infants]
  );
  const minAdults = useMemo(() => Math.max(values.infants, 1), [values.infants]);
  const maxChildren = useMemo(
    () => MAX_PASSENGERS - (values.adults + values.infants),
    [values.adults, values.infants]
  );
  const maxInfants = useMemo(
    () => Math.min(MAX_PASSENGERS - (values.adults + values.children), values.adults),
    [values.adults, values.children]
  );

  // Cabin class
  const cabinClassOptions = useMemo(
    () => [
      {
        label: capitalize(t(CABIN_CLASS_LABELS[CabinClassCode.Economy])),
        value: CabinClassCode.Economy,
      },
      {
        label: capitalize(t(CABIN_CLASS_LABELS[CabinClassCode.PremiumEconomy])),
        value: CabinClassCode.PremiumEconomy,
      },
      {
        label: capitalize(t(CABIN_CLASS_LABELS[CabinClassCode.Business])),
        value: CabinClassCode.Business,
      },
      {
        label: capitalize(t(CABIN_CLASS_LABELS[CabinClassCode.First])),
        value: CabinClassCode.First,
      },
      {
        label: capitalize(t(CABIN_CLASS_LABELS[CabinClassCode.NoPreference])),
        value: CabinClassCode.NoPreference,
      },
    ],
    [t]
  );

  return (
    <div>
      <PassengersContainer>
        <PassengersField
          description={t("tix_search_form_adults_age")}
          max={maxAdults}
          min={minAdults}
          name="adults"
          onChange={(value) => onChange({ adults: value })}
          title={t("tix_search_form_adults")}
          value={values.adults}
        />
        <PassengersField
          description={t("tix_search_form_children_age")}
          max={maxChildren}
          name="children"
          onChange={(value) => onChange({ children: value })}
          title={t("tix_search_form_children")}
          value={values.children}
        />
        <PassengersField
          description={t("tix_search_form_infants_age")}
          max={maxInfants}
          name="infants"
          onChange={(value) => onChange({ infants: value })}
          title={t("tix_search_form_infants")}
          value={values.infants}
        />
      </PassengersContainer>
      {isMinTablet ? (
        <CabinClassContainer>
          <LabelContainer>
            <SelectLabel>{capitalize(t("tix_search_form_cabin_class"))}</SelectLabel>
            <SelectSubLabel>{t("tix_search_form_cabin_class_subtitle")}</SelectSubLabel>
          </LabelContainer>

          <CabinClassSelect
            size={AnimatedRoundedSelectSize.ExternalLayoutStandard}
            options={cabinClassOptions}
            placeholder="cabinClass"
            value={values.cabinClass}
            layout={LayoutType.External}
            onChange={(value) => {
              dispatchCustomEvent({
                widgetEvent: WidgetGATriggerEvents.FlightExtendedSearchClassChanged,
                details: { value },
              });
              onChange({ cabinClass: value });
            }}
          />
        </CabinClassContainer>
      ) : (
        <CabinClassRadioButtons
          layout={LayoutType.External}
          label={capitalize(t("tix_search_form_cabin_class"))}
          subLabel={capitalize(t("tix_search_form_cabin_class_subtitle"))}
          name="cabinClass"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            dispatchCustomEvent({
              widgetEvent: WidgetGATriggerEvents.FlightExtendedSearchClassChanged,
              details: { value: event.target.value },
            });
            onChange({ cabinClass: event.target.value });
          }}
          options={cabinClassOptions}
          showLabel
          value={values.cabinClass}
          spaceBetweenButtons={10}
        />
      )}
    </div>
  );
};

const PassengersContainer = styled.div`
  padding-bottom: 22px;
  margin-bottom: 27px;

  @media ${Breakpoint.MinTablet} {
    border: none;
    padding: 0;
    margin-bottom: 20px;
  }

  @media ${Breakpoint.MaxMobile} {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
`;

const CabinClassSelect = styled(AnimatedRoundedSelect)`
  width: 180px;
  min-height: 34px;
  height: 34px;
`;

const CabinClassContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const LabelContainer = styled.div``;

const SelectLabel = styled.div`
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 13px;
  line-height: 17px;
`;

const SelectSubLabel = styled(Mk1Typography).attrs(() => ({
  defaultVariant: "small",
}))`
  color: ${({ theme }) => theme.greyColor};
  line-height: 17px;
`;

const CabinClassRadioButtons = styled(RadioGroupField)`
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    font-size: 11px;
  }
`;

export default ExtendedSearchFields;
