import { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../../shared/Button";
import Mk1Typography from "../../shared/Mk1Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";

interface Props {
  description?: string;
  title: string;
  max?: number;
  min?: number;
  name: "adults" | "children" | "infants";
  onChange: (newValue: number) => void;
  value: number;
}

const PassengersField = ({
  description,
  title,
  max = 9,
  min = 0,
  name,
  onChange,
  value,
}: Props) => {
  const disabledMax = useMemo(() => !Number.isNaN(Number(max)) && value >= max, [max, value]);
  const disabledMin = useMemo(() => value <= min, [min, value]);
  const theme = useTheme();

  const increment = useCallback(() => {
    const event =
      name === "adults"
        ? WidgetGATriggerEvents.FlightExtendedSearchAdultAdded
        : name === "children"
        ? WidgetGATriggerEvents.FlightExtendedSearchChildAdded
        : WidgetGATriggerEvents.FlightExtendedSearchInfantAdded;
    dispatchCustomEvent({ widgetEvent: event });
    onChange(value + 1);
  }, [onChange, value, name]);

  const decrement = useCallback(() => {
    const event =
      name === "adults"
        ? WidgetGATriggerEvents.FlightExtendedSearchAdultRemoved
        : name === "children"
        ? WidgetGATriggerEvents.FlightExtendedSearchChildRemoved
        : WidgetGATriggerEvents.FlightExtendedSearchInfantRemoved;
    dispatchCustomEvent({ widgetEvent: event });
    onChange(value - 1);
  }, [onChange, value, name]);

  return (
    <Container>
      <div>
        <Mk1Typography isHeavy={true}>{title}</Mk1Typography>
        {description && <Description defaultVariant="small">{description}</Description>}
      </div>
      <ButtonsContainer>
        <IconButton disabled={disabledMin} onlyIcon onClick={decrement}>
          <FontAwesomeIcon
            icon={faMinus}
            color={disabledMin ? theme.disabledColor : theme.defaultColor}
          />
        </IconButton>
        <Value>{value.toString()}</Value>
        <IconButton disabled={disabledMax} onlyIcon onClick={increment}>
          <FontAwesomeIcon
            icon={faPlus}
            color={disabledMax ? theme.disabledColor : theme.defaultColor}
          />
        </IconButton>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:last-child {
    padding-bottom: 20px;
    border-bottom: 1px solid;
  }
`;

const Description = styled(Mk1Typography)`
  color: ${({ theme }) => theme.greyColor};
  font-size: 11px;
  line-height: 17px;
`;

const Value = styled(Mk1Typography)`
  display: inline-flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.heavyFont};
  width: 40px;
  line-height: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled(Button)`
  height: 34px;
  width: 34px;
  max-height: 34px;
  max-width: 34px;
  padding: 0;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  background: ${({ theme }) => theme.darkBackground};

  &:disabled {
    opacity: 1;
    background: ${({ theme }) => theme.darkBackground};
  }

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.disabledColor};
  }

  svg {
    width: 13px;
  }
`;

export default PassengersField;
