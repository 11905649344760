import { ApiCustomHeader } from "../enums/ApiCustomHeader";
import axios from "axios";
import { ApiAction } from "./actions/ApiAction";

export function request(language: string, clientId?: string) {
  return async function <R>(action: ApiAction): Promise<R> {
    const requestHeaders = {
      ...action.headers,
      ...(language && { [ApiCustomHeader.AcceptLanguage]: language }),
      ...(clientId && { [ApiCustomHeader.ClientId]: clientId }),
    };
    return handleApiAction({ ...action, headers: { ...requestHeaders } });
  };
}

async function handleApiAction<R>(options: ApiAction): Promise<R> {
  try {
    const { data } = await axios(options);
    return data;
  } catch (error: any) {
    throw error;
  }
}
