import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAppAttributes } from "./useAppAttributes";
import { CommunicationType } from "../enums/CommunicationType";
import { WidgetDispatchEvents, WidgetListeningEvents } from "../enums/WidgetEvents";
import { FormikValues, setNestedObjectValues, useFormik } from "formik";
import { dispatchCustomEvent } from "../services/widgetEventsService";

const DISPATCH_EVENT_TIMEOUT_MS = 50;

interface Params<T extends FormikValues> {
  form: ReturnType<typeof useFormik<T>>;
}

export const useHotelSearchFormEvents = <T extends FormikValues>({ form }: Params<T>) => {
  const appAttributes = useAppAttributes();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (appAttributes.communicationType === CommunicationType.Event && !isInitialized) {
      dispatchCustomEvent({ widgetEvent: WidgetDispatchEvents.HotelReadyForValues });
    }
  }, [isInitialized, appAttributes.communicationType]);

  const setValuesFromEvent = useCallback(
    async ({ detail }: CustomEvent<{ values: any; touched?: any }>) => {
      form.setValues(detail.values);
      if (detail.touched) {
        form.setTouched(detail.touched);
      }
      if (!isInitialized) {
        const errors = await form.validateForm(detail.values);
        form.setTouched(setNestedObjectValues(errors, true));
        setIsInitialized(true);
      }
    },
    [form, isInitialized]
  );

  // Listeners
  useEffect(() => {
    if (appAttributes.communicationType !== CommunicationType.Event) return;
    window.addEventListener<any>(WidgetListeningEvents.SetHotelValues, setValuesFromEvent);
    return () => {
      window.removeEventListener<any>(WidgetListeningEvents.SetHotelValues, setValuesFromEvent);
    };
  }, [appAttributes.communicationType, setValuesFromEvent]);

  const debouncedDispatchCustomEvent = useDebouncedCallback(
    dispatchCustomEvent,
    DISPATCH_EVENT_TIMEOUT_MS
  );

  // Dispatchers
  useEffect(() => {
    if (appAttributes.communicationType !== CommunicationType.Event || !isInitialized) return;
    debouncedDispatchCustomEvent({
      widgetEvent: WidgetDispatchEvents.HotelValuesChanged,
      details: { values: form.values, errors: form.errors, touched: form.touched },
    });
  }, [
    appAttributes.communicationType,
    debouncedDispatchCustomEvent,
    form.errors,
    form.touched,
    form.values,
    isInitialized,
  ]);
};
