import { forwardRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import MobileModal from "../../../../shared/MobileModal";
import { AutocompleteValue } from "../types";
import TextField from "../../../../shared/TextField";
import { useTranslation } from "../../../../../i18n";
import { LayoutType } from "../../../../../enums/LayoutType";
import MobileModalInputHeader from "../../MobileModalInputHeader";
import { useAirportAutocompleteSuggestions } from "../../../../../hooks/useAirportAutocompleteSuggestions";
import Suggestions from "./Suggestions";
import CircleLoader from "../../../../shared/CircleLoader";
import { useWizardContext } from "../../../WizardContext";

interface Props {
  direction: "departure" | "destination";
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompleteAirport, selectedByHand?: boolean): void;
  value: AutocompleteValue;
  scrollControlled?: boolean;
  inputTitleLabel?: string;
}

const MobileAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ direction, error, inputLabel, onSelect, value, scrollControlled, inputTitleLabel }, ref) => {
    const [inputValue, setInputValue] = useState("");
    const { stopWizard, isWizard, mobileModalAnimations } = useWizardContext();
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const closeModal = useCallback(() => {
      setIsOpened(false);
      stopWizard();
    }, [stopWizard]);

    const { fetchSuggestions, isLoading, suggestions } = useAirportAutocompleteSuggestions();

    useEffect(() => {
      if (inputValue.length >= 3) {
        fetchSuggestions(inputValue);
      }
    }, [inputValue, fetchSuggestions]);

    const selectSuggestion = useCallback(
      (suggestion: AutocompleteAirport) => {
        onSelect(suggestion, true);
        setInputValue(suggestion.name);
        setIsOpened(false);
      },
      [onSelect]
    );

    return (
      <Container>
        {inputTitleLabel && <InputTitle>{t(inputTitleLabel)}</InputTitle>}
        <InputField
          hasError={!!error}
          label={inputLabel}
          onChange={() => {}}
          onFocus={() => setIsOpened(true)}
          name={direction}
          value={value.name}
          rounded={false}
          layout={LayoutType.External}
          ref={ref}
        />
        <MobileModal
          layout={LayoutType.External}
          action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
          headerContent={
            <MobileModalInputHeader
              inputTitle={
                direction === "departure"
                  ? t("tix_search_form_mobile_modal_flight_departure_title")
                  : t("tix_search_form_mobile_modal_flight_destination_title")
              }
            />
          }
          isOpened={isOpened}
          onClose={closeModal}
          style={{ header: { flexBasis: "56px" }, content: { overflow: "hidden" } }}
          scrollControlled={scrollControlled}
          animations={mobileModalAnimations}
          isWizard={isWizard}
        >
          <ModalContent>
            <ContentContainer>
              <>
                <InputContainer>
                  <InputLabel>
                    {direction === "departure"
                      ? t("tix_search_form_departure_title")
                      : t("tix_search_form_destination_title")}
                  </InputLabel>
                  <StyledTextField
                    autoFocus
                    label={value.name}
                    name="query"
                    onChange={(event) => setInputValue(event.currentTarget.value)}
                    value={inputValue}
                    placeholder={inputLabel}
                    rounded={false}
                  />
                </InputContainer>
                <SuggestionsContainer>
                  {isLoading ? (
                    <LoaderContainer>
                      <CircleLoader size={60} width={4} />
                    </LoaderContainer>
                  ) : (
                    suggestions.length > 0 && (
                      <Suggestions onSelect={selectSuggestion} suggestions={suggestions} />
                    )
                  )}
                </SuggestionsContainer>
              </>
            </ContentContainer>
          </ModalContent>
        </MobileModal>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
`;

const SuggestionsContainer = styled.div`
  height: 100%;
  overflow: auto;
  > ul > li {
    padding: 10px 0;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.defaultBackground};
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  font-family: ${({ theme }) => theme.regularFont};
`;

const InputContainer = styled.div`
  margin: 13px 20px 10px 20px;
  border-bottom: 1px solid;
`;

const InputLabel = styled.div`
  font-size: 11px;
  line-height: 17px;
`;

const StyledTextField = styled(TextField)`
  padding-top: 5px;
  padding-bottom: 20px;
  > input {
    height: 17px;
    font-size: 13px;
    line-height: 17px;
    padding: 0;
    font-family: ${({ theme }) => theme.heavyFont};
  }
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
  z-index: 1;
`;

const InputField = styled(TextField)`
  font-family: ${({ theme }) => theme.heavyFont};
  & > input[type="text"] {
    padding-top: 10px;
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
  }
`;

const LoaderContainer = styled.div`
  flex-grow: 1;
  padding-top: 50px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.darkBackground};
`;

export default MobileAutocomplete;
