import { AutocompleteHotel } from "./../../types/Autocomplete";
import { ApiAction, ApiActionName } from "./ApiAction";
import qs from "qs";

interface Params {
  query: string;
}

export type GetAutocompleteHotelsResponse = AutocompleteHotel[];

export function getAutocompleteHotels(params: Params): ApiAction {
  return {
    method: "get",
    name: ApiActionName.GetAutocompleteHotels,
    url: `${process.env.REACT_APP_HOTELS_AUTOCOMPLETE_API_URL}?${qs.stringify(params)}`,
  };
}
