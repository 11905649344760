import { CabinClassCode } from "../enums/CabinClassCode";

export enum FlightsSearchFormValuesType {
  OneWay = "oneway",
  Return = "return",
  MultiCity = "multicity",
}

export interface FlightsSearchFormValuesBound {
  departureCode: string;
  departureName: string;
  departureDate: Date | undefined;
  destinationCode: string;
  destinationName: string;
}

export interface FlightsSearchFormValues {
  type: FlightsSearchFormValuesType;
  adults: number;
  children: number;
  infants: number;
  bounds: FlightsSearchFormValuesBound[];
  cabinClass: CabinClassCode;
  campaignId?: number | string;
  searchId?: string;
  affiliateId?: number;
  preferredFlightId?: string;
}
