import { FormikErrors, FormikTouched } from "formik";
import { useCallback, useRef } from "react";
import { useTheme } from "styled-components";
import { FlightsSearchFormValues } from "../../../types/FlightsSearchFormValues";
import FlightDateField from "./FlightDateField";
import useMedia from "../../../hooks/useMedia";
import AirportAutocompleteField from "./AirportAutocompleteField";
import { InputKey, useWizardContext } from "../WizardContext";

interface Props {
  boundsIndexes: number[];
  errors: FormikErrors<FlightsSearchFormValues>;
  touched: FormikTouched<FlightsSearchFormValues>;
  isLastRow?: boolean;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onDateSelect?(): void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
  inline?: boolean;
  departureCodeInputKey: InputKey;
}

const SearchFormRow = ({
  boundsIndexes,
  errors,
  touched,
  onChange,
  onDateSelect,
  values,
  scrollControlled,
  inline = false,
  departureCodeInputKey,
}: Props) => {
  const { startWizard, stopWizard, setInputRef } = useWizardContext();
  const destinationInputRef = useRef<HTMLInputElement>(null);
  const departureDateInputRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMinTablet = useMedia(theme.breakpoints.minTablet);
  const isTablet = useMedia(theme.breakpoints.onlyTablet);

  const onDepartureSelect = useCallback(() => {
    // Mobile: should open destination modal if destination is empty
    // Tablet/Desktop: should focus destination field
    if (isMinTablet || (!isMinTablet && !destinationInputRef?.current?.value)) {
      startWizard();
      destinationInputRef?.current?.focus();
    }
  }, [isMinTablet, startWizard]);
  const onDestinationSelect = useCallback(() => {
    // should open departure date modal if departure date is empty
    if (!values.bounds[boundsIndexes[0]].departureDate) {
      startWizard();
      departureDateInputRef?.current?.focus();
    }
  }, [boundsIndexes, startWizard, values.bounds]);

  return (
    <>
      <AirportAutocompleteField
        boundsIndexes={boundsIndexes}
        direction="departure"
        errors={errors}
        touched={touched}
        onChange={onChange}
        values={values}
        onOptionSelect={onDepartureSelect}
        scrollControlled={scrollControlled}
        ref={setInputRef(departureCodeInputKey)}
      />
      <AirportAutocompleteField
        boundsIndexes={boundsIndexes}
        direction="destination"
        errors={errors}
        touched={touched}
        onChange={onChange}
        values={values}
        ref={destinationInputRef}
        scrollControlled={scrollControlled}
        onOptionSelect={onDestinationSelect}
      />
      <FlightDateField
        errors={errors}
        touched={touched}
        boundsIndexes={boundsIndexes}
        onChange={onChange}
        values={values}
        scrollControlled={scrollControlled}
        alignLeft={isTablet && inline}
        onDateSelect={onDateSelect}
        ref={departureDateInputRef}
        onMobileModalClose={stopWizard}
      />
    </>
  );
};
export default SearchFormRow;
