import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import Autosuggest, {
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import { AutocompleteValue } from "../types";
import { useHotelAutocompleteSuggestions } from "../../../../../hooks/useHotelAutocompleteSuggestions";
import InputComponent from "./InputComponent";
import SuggestionsContainer from "./SuggestionsContainer";
import Suggestion from "./Suggestion";

const getSuggestionValue = ({ name }: AutocompleteValue) => name;

interface Props {
  inputLabel: string;
  onSelect(suggestion: AutocompleteValue, selectedByHand?: boolean): void;
  value: AutocompleteValue;
  error?: string;
  inputTitle?: string;
}

const DesktopAutocomplete = ({ error, inputLabel, inputTitle, onSelect, value }: Props) => {
  const { clearSuggestions, fetchSuggestions, suggestions } = useHotelAutocompleteSuggestions();
  const [innerValue, setInnerValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setInnerValue(value.name);
  }, [value.name]);

  const inputProps = useMemo<InputProps<any>>(
    () => ({
      autoComplete: "off",
      autoCorrect: "off",
      name: "locationCode",
      onBlur: (event, { highlightedSuggestion }: any) => {
        if (highlightedSuggestion && innerValue) {
          onSelect(highlightedSuggestion);
        }
        setIsFocused(false);
      },
      onChange: (event, { newValue }) => {
        setInnerValue(newValue);
        if (!newValue) {
          onSelect({ code: "", name: "", type: "", description: "" });
        } else if (!!value.code && newValue !== value.name) {
          onSelect({ code: "", name: newValue, type: "", description: "" });
        }
      },
      onFocus: () => setIsFocused(true),
      required: true,
      value: innerValue,
    }),
    [innerValue, onSelect, value.code, value.name]
  );

  const handleSelect = useCallback(
    (event: FormEvent<any>, { suggestion }: SuggestionSelectedEventData<AutocompleteValue>) => {
      onSelect(suggestion, true);
    },
    [onSelect]
  );

  const fetchSuggestionsRequest = useCallback(
    async ({ value }: SuggestionsFetchRequestedParams) => {
      if (value.length >= 3) {
        fetchSuggestions(value);
      }
    },
    [fetchSuggestions]
  );

  // select after blur if fetching was in progress
  useEffect(() => {
    if (!isFocused && suggestions.length > 0 && value.name !== suggestions[0].name) {
      onSelect(suggestions[0]);
    }
  }, [isFocused, onSelect, suggestions, value]);

  const [isFirstHighlighted, setIsFirstHighlighted] = useState(false);
  const handleSuggestionHighlighted = useCallback(
    ({ suggestion }: { suggestion: AutocompleteValue | null }) => {
      setIsFirstHighlighted(
        !!suggestion && !!suggestions[0] && suggestion.code === suggestions[0].code
      );
    },
    [suggestions]
  );

  return (
    <Autosuggest
      focusInputOnSuggestionClick={false}
      highlightFirstSuggestion={true}
      suggestions={suggestions}
      onSuggestionsFetchRequested={fetchSuggestionsRequest}
      onSuggestionsClearRequested={clearSuggestions}
      onSuggestionSelected={handleSelect}
      onSuggestionHighlighted={handleSuggestionHighlighted}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={Suggestion}
      inputProps={inputProps}
      renderInputComponent={(inputProps: any) => (
        <InputComponent
          {...inputProps}
          error={error}
          inputTitle={inputTitle}
          label={inputLabel}
          placeholder={inputLabel}
        />
      )}
      renderSuggestionsContainer={({ containerProps, children, query }) => (
        <SuggestionsContainer
          containerProps={containerProps}
          isFirstHighlighted={isFirstHighlighted}
          query={query}
          title={inputTitle}
          placeholder={innerValue || inputLabel}
        >
          {children}
        </SuggestionsContainer>
      )}
    />
  );
};

export default DesktopAutocomplete;
