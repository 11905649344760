import { HTMLAttributes } from "react";
import styled from "styled-components";
import { useAppAttributes } from "../../hooks/useAppAttributes";
import { LayoutType } from "../../enums/LayoutType";

interface Props extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  inline?: boolean;
  layout?: LayoutType;
  className?: string;
  label: string;
  name: string;
  options: {
    label: string | JSX.Element;
    value: string;
    description?: string | JSX.Element | null;
    disabled?: boolean;
    hidden?: boolean;
  }[];
  showLabel?: boolean;
  subLabel?: string;
  value: any;
  spaceBetweenButtons?: number;
}

const RadioGroupField = ({
  id,
  inline,
  layout,
  className,
  label,
  name,
  options,
  value,
  onChange,
  showLabel,
  spaceBetweenButtons = 20,
  subLabel,
}: Props) => {
  const appAttributes = useAppAttributes();
  return (
    <Container
      aria-label={label}
      inline={inline}
      role="group"
      spaceBetweenButtons={spaceBetweenButtons}
      className={className}
    >
      {(showLabel || !!subLabel) && (
        <div>
          {showLabel && <GroupLabel layout={layout}>{label}</GroupLabel>}
          {!!subLabel && <SubLabel layout={layout}>{subLabel}</SubLabel>}
        </div>
      )}
      {options.map((option, index) => (
        <RadioContainer
          className={`radio-input${option.disabled ? " disabled" : ""}${
            option.value === value ? " checked" : ""
          }`}
          inline={inline}
          hidden={option.hidden}
          key={index}
          layout={appAttributes.layout}
        >
          <input
            checked={option.value === value}
            id={`${id || name}${index}`}
            name={name}
            onChange={onChange}
            type="radio"
            value={option.value}
            disabled={!!option.disabled}
          />
          <label htmlFor={`${id || name}${index}`}>
            <span>{option.label}</span>
            {option.description && <Description>{option.description}</Description>}
          </label>
        </RadioContainer>
      ))}
    </Container>
  );
};

const SubLabel = styled.div<{ layout?: LayoutType }>`
  font-size: 11px;
  line-height: 17px;
  color: ${({ theme }) => theme.greyColor};
  font-family: ${({ layout, theme }) =>
    layout === LayoutType.External ? theme.regularFont : theme.heavyFont};
`;

const Container = styled.div<{ inline?: boolean; spaceBetweenButtons: number }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};

  & > *:not(:last-child) {
    margin-right: ${({ inline, spaceBetweenButtons }) =>
      inline ? `${spaceBetweenButtons}px` : "0"};
    margin-bottom: ${({ inline, spaceBetweenButtons }) =>
      inline ? "0" : `${spaceBetweenButtons}px`};
  }
`;

const GroupLabel = styled.div<{ layout?: LayoutType }>`
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: ${({ layout }) => (layout === LayoutType.External ? "0px" : "10px")};
`;

const Description = styled.span`
  color: #9e9e9e;
  font-size: 0.75rem;
  padding-left: 10px;
`;

const RadioContainer = styled.div<{ inline?: boolean; hidden?: boolean; layout?: LayoutType }>`
  align-items: center;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  height: 20px;

  label {
    display: flex;
    width: ${({ inline }) => (inline ? "auto" : "100%")};
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
  }

  [type="radio"]:focus {
    outline-color: #dfdfdf;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-family: ${({ theme }) => theme.regularFont};
    font-size: 14px;
    line-height: 20px;

    ${({ layout }) =>
      layout === LayoutType.External &&
      `
    font-size: 11px;
    line-height: 20px;
    `}
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.flightSearchForm.flightTypeEmptyColor};
    border-radius: 100%;
    box-sizing: border-box;
    transition: border-color 0.2s ease;
  }

  [type="radio"]:checked + label:before {
    border-color: ${({ theme }) => theme.flightSearchForm.flightTypeSelectedColor};
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.flightSearchForm.flightTypeSelectedColor};
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
  }

  &.disabled {
    color: #c2c5c7;

    [type="radio"]:not(:checked) + label:before {
      cursor: unset;
      background: #f4f4f4;
      border: 1px solid ${({ theme }) => theme.form.radioGroup.disabled};
    }
  }
`;

export default RadioGroupField;
