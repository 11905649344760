import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Breakpoint } from "../../../../enums/Breakpoint";
import useMedia from "../../../../hooks/useMedia";
import Button from "../../../shared/Button";
import Fields from "./Fields";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { HotelsSearchParamsRoom } from "../../../../types/HotelsSearchParams";
import MobileModal from "../../../shared/MobileModal";
import { useTranslation } from "react-i18next";
import MobileModalInputHeader from "../../../FlightsSearchForm/ExternalLayout/MobileModalInputHeader";
import { LayoutType } from "../../../../enums/LayoutType";
import { InputKey, useWizardContext } from "../../WizardContext";
import { capitalize } from "../../../../utils/stringUtils";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { useSearchFormWithFocusScroll } from "../../../../hooks/useSearchFormWithFocusScroll";

interface Props {
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onSubmit: () => void;
  values: HotelsSearchParamsRoom[];
}

const RoomsCountField = ({ onChange, onSubmit, values }: Props) => {
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const [isOpened, setIsOpened] = useState(false);
  const [areChildrenAgeTouched, setAreChildrenAgeTouched] = useState(false);
  const { isWizard, mobileModalAnimations, setInputRef, stopWizard } = useWizardContext();
  const theme = useTheme();
  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();

  const areChildrenAgeValid = useMemo(() => {
    return values.every((room) =>
      room.childrenAge.every((childAge) => typeof childAge === "number")
    );
  }, [values]);
  const close = useCallback(() => {
    if (!isOpened) return;
    stopWizard();
    if (!areChildrenAgeValid) {
      setAreChildrenAgeTouched(true);
    } else {
      setIsOpened(false);
      setAreChildrenAgeTouched(false);
    }
  }, [areChildrenAgeValid, isOpened, stopWizard]);
  const dropdownClose = useCallback(() => {
    if (isMinTablet) {
      close();
    }
  }, [close, isMinTablet]);
  useOutsideClick(ref, dropdownClose);

  // Button label
  const label = useMemo(() => {
    const { adults, children } = values.reduce(
      (acc, room) => ({
        adults: acc.adults + room.adults,
        children: acc.children + room.childrenAge.length,
      }),
      {
        adults: 0,
        children: 0,
      }
    );
    return `${values.length} ${
      values.length > 1 ? t("tix_search_form_rooms") : t("tix_search_form_room")
    }, ${adults} ${adults > 1 ? t("tix_search_form_adults") : t("tix_search_form_adult")}${
      children > 0
        ? `, ${children} ${
            children > 1 ? t("tix_search_form_children") : t("tix_search_form_child")
          }`
        : ""
    }`;
  }, [t, values]);

  useEffect(() => {
    if (isOpened) {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.OpenHotelsExtendedSearch });
      handleScrollToSearchFormIfNeeded(dropdownRef.current);
    }
  }, [handleScrollToSearchFormIfNeeded, isOpened]);

  const submitAction = useMemo(() => (isWizard ? onSubmit : close), [close, onSubmit, isWizard]);

  return (
    <Container id="rooms-count-field" ref={ref}>
      <DropdownButton
        ref={setInputRef(InputKey.RoomCount)}
        onClick={() => (isOpened ? dropdownClose() : setIsOpened(true))}
        title={label}
        type="button"
      >
        <InputTitle>{t("tix_search_form_hotels_rooms_title")}</InputTitle>
        <Label>{label}</Label>
      </DropdownButton>
      {isMinTablet ? (
        isOpened && (
          <Dropdown ref={dropdownRef}>
            <DropdownInputTitle>{t("tix_search_form_hotels_rooms_title")}</DropdownInputTitle>
            <DropdownLabel>{label}</DropdownLabel>
            <Fields
              areChildrenAgeTouched={areChildrenAgeTouched}
              onChange={onChange}
              values={values}
            />
            <ReadyButton variant="rectangle" onClick={submitAction}>
              {t(isWizard ? "tix_search_form_search_hotels" : "tix_search_form_ready")}
            </ReadyButton>
          </Dropdown>
        )
      ) : (
        <MobileModal
          layout={LayoutType.External}
          action={{
            label: t(isWizard ? "tix_search_form_search_hotels" : "tix_search_form_continue"),
            onClick: submitAction,
          }}
          animations={mobileModalAnimations}
          headerContent={
            <MobileModalInputHeader
              inputTitle={capitalize(t("tix_search_form_mobile_modal_hotels_rooms_title"))}
            />
          }
          style={{
            content: {
              overflow: "hidden",
              backgroundColor: theme.mobileModal.backgroundColor,
              padding: "0 20px",
            },
          }}
          isOpened={isOpened}
          onClose={close}
          isWizard={isWizard}
        >
          <MobileFieldsContainer>
            <InputContainer>
              <InputLabel>{t("tix_search_form_hotels_rooms_title")}</InputLabel>
              <RoomsLabel>{label}</RoomsLabel>
            </InputContainer>
            <Fields
              areChildrenAgeTouched={areChildrenAgeTouched}
              onChange={onChange}
              values={values}
            />
          </MobileFieldsContainer>
        </MobileModal>
      )}
    </Container>
  );
};

const InputContainer = styled.div`
  padding-top: 13px;
  border-bottom: 1px solid;
`;

const InputLabel = styled.div`
  font-size: 11px;
  line-height: 17px;
`;

const RoomsLabel = styled.div`
  font-size: 13px;
  line-height: 17px;
  font-family: ${({ theme }) => theme.heavyFont};
  padding-top: 5px;
  padding-bottom: 20px;
`;

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  font-family: ${({ theme }) => theme.regularFont};

  @media ${Breakpoint.OnlyTablet} {
    width: calc(50% - 2px);
  }
`;

const DropdownButton = styled.button`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border: none;
  color: ${({ theme }) => theme.defaultColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 13px;
  height: 70px;
  padding-left: 20px;
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};

  @media ${Breakpoint.MinTablet} {
    height: 80px;
  }

  svg {
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${Breakpoint.MinDesktop} {
      left: 10px;
    }
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.defaultColor};
  max-width: 100%;
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 13px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${Breakpoint.MinDesktop} {
    padding-top: 0;
  }
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
  line-height: 17px;
`;

const DropdownInputTitle = styled(InputTitle)`
  top: 15px;
  left: 25px;
`;

const DropdownLabel = styled(Label)`
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  padding-top: 1px;

  @media ${Breakpoint.MinTablet} {
    margin-top: 20.5px;
  }
`;

const MobileFieldsContainer = styled.div`
  padding: 0 20px;
  background-color: ${({ theme }) => theme.defaultBackground};
  font-family: ${({ theme }) => theme.regularFont};
  height: 100%;
  overflow: auto;
`;

const Dropdown = styled.div`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 25px 25px 25px;
  position: absolute;
  right: unset;
  left: -5px;
  top: -5px;
  width: 304px;
  z-index: 10;
`;

const ReadyButton = styled(Button)`
  height: 48px;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  font-size: 13px;
  line-height: 17px;
  font-family: ${({ theme }) => theme.heavyFont};
`;

export default RoomsCountField;
