import { render } from "react-dom";
import { StyleSheetManager } from "styled-components";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { widgetMainDocument } from "./utils/widgetParentUtils";
import { addFontsToDocumentHead } from "./utils/fontFaceUtils";

render(
  <StyleSheetManager target={widgetMainDocument.querySelector("head")!}>
    <App />
  </StyleSheetManager>,
  widgetMainDocument.getElementById(process.env.REACT_APP_CONTAINER_ELEMENT_ID!)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
addFontsToDocumentHead();
reportWebVitals();
