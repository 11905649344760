// swap.svg

const Swap = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.707"
      height="15.674"
      viewBox="0 0 13.707 15.674"
    >
      <g id="Group_2034" data-name="Group 2034" transform="translate(-597.146 1093.32) rotate(-90)">
        <path
          id="Path_344"
          data-name="Path 344"
          d="M6.164,4.143,3.436,1.414.707,4.143,0,3.436,3.436,0,6.871,3.436Z"
          transform="translate(1077.646 597.146)"
        />
        <path
          id="Line_125"
          data-name="Line 125"
          d="M.5,12.293h-1V0h1Z"
          transform="translate(1081.082 597.853)"
        />
        <path
          id="Path_345"
          data-name="Path 345"
          d="M278.89,292.678l-3.436-3.436.707-.707,2.728,2.728,2.728-2.728.707.707Z"
          transform="translate(810.996 318.175)"
        />
        <path
          id="Line_126"
          data-name="Line 126"
          d="M.5,12.293h-1V0h1Z"
          transform="translate(1089.886 597.853)"
        />
      </g>
    </svg>
  );
};
export default Swap;
