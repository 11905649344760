const Edit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.404"
      height="14.49"
      viewBox="0 0 14.404 14.49"
    >
      <g id="Group_7_Copy" data-name="Group 7 Copy" transform="translate(0.511 0.586)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M0,8.151,8.151,0l2.512,2.512L2.512,10.663Z"
          transform="translate(1.579 1.152)"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
          <path
            id="Stroke_2"
            data-name="Stroke 2"
            d="M4.091,2.512.435,4.065a.312.312,0,0,1-.409-.409L1.579,0Z"
            transform="translate(0 9.303)"
            fill="none"
            stroke="#000"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Stroke_4"
            data-name="Stroke 4"
            d="M.884.268,0,1.152,2.512,3.664,3.4,2.781a.916.916,0,0,0,0-1.3L2.179.268A.916.916,0,0,0,.884.268Z"
            transform="translate(9.729)"
            fill="none"
            stroke="#000"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default Edit;
