import { useMemo } from "react";
import { LayoutType } from "../../enums/LayoutType";
import { FlightsSearchFormProps } from "../../types/FlightsSearchFormProps";
import { FlightsSearchForm as TixFlightsSearchForm } from "./Tix/FlightsSearchForm";
import { FlightsSearchForm as ExternalLayoutFlightsSearchForm } from "./ExternalLayout/FlightsSearchForm";
import { WizardContextProvider } from "./WizardContext";

interface Props extends FlightsSearchFormProps {
  layout: LayoutType;
}

const FlightSearchForm = ({ layout, ...searchFormProps }: Props) => {
  const Component = useMemo(() => {
    switch (layout) {
      case LayoutType.Tix:
        return TixFlightsSearchForm;
      case LayoutType.External:
        return ExternalLayoutFlightsSearchForm;
      default:
        return TixFlightsSearchForm;
    }
  }, [layout]);

  return (
    <WizardContextProvider>
      <Component {...searchFormProps} />
    </WizardContextProvider>
  );
};

export default FlightSearchForm;
