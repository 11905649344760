import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getMonth, isEqual, startOfMonth } from "date-fns";
import { useDateFormatter } from "../../../../../hooks/useDateFormatter";

interface Props {
  months: Date[];
  monthForValue: Date;
  setMonth: (month: Date) => void;
}

const DoubleMonthsSlider = ({ months, monthForValue, setMonth }: Props) => {
  const formatDate = useDateFormatter();
  const monthRefArray = useRef<Map<number, HTMLDivElement> | null>(null);
  const [sliderStyles, setSliderStyles] = useState({});
  const onMonthClick = useCallback(
    (monthIndex: number) => {
      monthIndex === months.length - 1
        ? setMonth(months[monthIndex - 1])
        : setMonth(months[monthIndex]);
    },
    [months, setMonth]
  );

  const setPositionForSlider = useCallback(() => {
    const foundMonthIndex = months.findIndex((v) => isEqual(v, startOfMonth(monthForValue)));
    if (foundMonthIndex >= 0) {
      const firstElement = getMonthsRefArray().get(0)?.getBoundingClientRect();
      const selectedElement = getMonthsRefArray().get(foundMonthIndex)?.getBoundingClientRect();
      const nextElement = getMonthsRefArray()
        .get(foundMonthIndex + 1)
        ?.getBoundingClientRect();
      setSliderStyles({
        left: `${selectedElement && firstElement ? selectedElement.left - firstElement.left : 0}px`,
        width: `${
          selectedElement && nextElement
            ? nextElement.width + (nextElement.left - selectedElement.left) + 20
            : 86
        }px`,
      });
    }
  }, [monthForValue, months]);

  useEffect(() => {
    if (!!monthRefArray.current) {
      setPositionForSlider();
    }
  }, [monthRefArray, setPositionForSlider]);

  function getMonthsRefArray() {
    if (!monthRefArray.current) {
      monthRefArray.current = new Map();
    }
    return monthRefArray.current;
  }

  const getMonthWithoutDot = useCallback(
    (month) => {
      const formattedDate = formatDate(month, "shortMonth");
      return formattedDate.endsWith(".")
        ? formattedDate.slice(0, formattedDate.length - 1)
        : formattedDate;
    },
    [formatDate]
  );

  return (
    <MainContainer>
      <SelectionMarker style={sliderStyles} />
      <MonthContainer>
        {months.map((month, index) => (
          <SingleMonth
            key={index}
            onClick={() => onMonthClick(index)}
            ref={(node) => {
              const map = getMonthsRefArray();
              if (node) {
                map.set(index, node);
              } else {
                map.delete(index);
              }
            }}
          >
            {getMonth(month) === 0 && <Year>{formatDate(month, "fullYear")}</Year>}
            {getMonthWithoutDot(month)}
          </SingleMonth>
        ))}
      </MonthContainer>
    </MainContainer>
  );
};

const Year = styled.div`
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  font-size: 10px;
`;

const SingleMonth = styled.div`
  cursor: pointer;
  position: relative;
`;

const MainContainer = styled.div`
  position: relative;
  width: 100%:
  height: 100%:
`;

const SelectionMarker = styled.div`
  height: 100%;
  position: absolute;
  transition: left 0.4s ease-out, width 0.4s ease-out;
  top: 0;
  border-radius: 11px;
  background-color: ${({ theme }) =>
    !!theme.form.calendar.slider
      ? theme.form.calendar.slider.activeMonthsBackground
      : theme.primaryColor};
`;

const MonthContainer = styled.div`
  position: relative;
  display: flex;
  padding: 0 10px;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 20px;
  justify-content: space-between;
  height: 22px;
  align-items: center;
`;

export default DoubleMonthsSlider;
