export const capitalize = (text = "", allWords = false): string => {
  if (allWords) {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => capitalize(word))
      .join(" ");
  }
  return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1);
};

export const trimParenthesesContent = (text = "") => {
  return text.replace(/ *\([^)]*\) */g, " ").trim();
};
