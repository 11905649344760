import styled from "styled-components";
import HotelBedIcon from "../../../../../customIcons/HotelBed";
import HotelLocationIcon from "../../../../../customIcons/HotelLocation";
import NexaTypography from "../../../../shared/NexaTypography";
import { LOCATION_TYPES } from "../constants";
import { AutocompleteValue } from "../types";

interface Props {
  onSelect(suggestion: AutocompleteValue): void;
  suggestions: AutocompleteValue[];
}

const Suggestions = ({ onSelect, suggestions }: Props) => {
  return (
    <Container>
      {suggestions.map((suggestion, index) => (
        <Suggestion key={index} onClick={() => onSelect(suggestion)}>
          {LOCATION_TYPES.includes(suggestion.type) ? <HotelLocationIcon /> : <HotelBedIcon />}
          <Title>{suggestion.name}</Title>
          <NexaTypography>{suggestion.description}</NexaTypography>
        </Suggestion>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow: auto;

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Suggestion = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid;
  }
`;

const Title = styled(NexaTypography).attrs({ isHeavy: true })``;

export default Suggestions;
