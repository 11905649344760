import { widgetMainDocument } from "./../utils/widgetParentUtils";
import { RefObject, useCallback, useEffect, useRef } from "react";

type HandleClickFunction = EventListenerOrEventListenerObject;

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  callback: () => void,
  checkForScrollbarClick: boolean = false,
  scrollbarRightOffset: number = 0
) {
  const handleClickRef = useRef<HandleClickFunction>();
  const handleClick: EventListenerOrEventListenerObject = useCallback(
    (event: Event) => {
      const clickedOutside = ref.current && !ref.current.contains(event.target as Node);
      const scrollbarClicked =
        (event as MouseEvent).clientX + scrollbarRightOffset >= window.innerWidth;
      if (
        clickedOutside &&
        (!checkForScrollbarClick || (checkForScrollbarClick && !scrollbarClicked))
      ) {
        callback();
      }
    },
    [callback, ref, checkForScrollbarClick, scrollbarRightOffset]
  );

  const handleOutsideShadowClick: EventListenerOrEventListenerObject = useCallback(
    (event: Event) => {
      const shadowWrapper = document.getElementById(
        process.env.REACT_APP_CONTAINER_WRAPPER_ELEMENT_ID!
      );
      const clickedOutside = event.target !== shadowWrapper;
      const scrollbarClicked =
        (event as MouseEvent).clientX + scrollbarRightOffset >= window.innerWidth;
      if (
        clickedOutside &&
        (!checkForScrollbarClick || (checkForScrollbarClick && !scrollbarClicked))
      ) {
        callback();
      }
    },
    [callback, checkForScrollbarClick, scrollbarRightOffset]
  );

  useEffect(() => {
    if (widgetMainDocument === document) {
      if (handleClickRef.current) {
        document.removeEventListener("mousedown", handleClickRef.current, true);
      }
      document.addEventListener("mousedown", handleClick, true);
      handleClickRef.current = handleClick;
      return () => {
        document.removeEventListener("mousedown", handleClick, true);
      };
    } else {
      if (handleClickRef.current) {
        widgetMainDocument.removeEventListener("mousedown", handleClickRef.current, true);
        document.removeEventListener("mousedown", handleOutsideShadowClick, true);
      }
      widgetMainDocument.addEventListener("mousedown", handleClick, true);
      document.addEventListener("mousedown", handleOutsideShadowClick, true);

      handleClickRef.current = handleClick;
      return () => {
        document.removeEventListener("mousedown", handleOutsideShadowClick, true);
        widgetMainDocument.removeEventListener("mousedown", handleClick, true);
      };
    }
  }, [handleClick, handleOutsideShadowClick]);
}
