import { useCallback, useMemo } from "react";
import styled from "styled-components";
import Button from "../../shared/Button";
import PlusIcon from "../../../customIcons/Plus";
import MinusIcon from "../../../customIcons/Minus";
import NexaTypography from "../../shared/NexaTypography";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";

interface Props {
  description?: string;
  title: string;
  max?: number;
  min?: number;
  name: "adults" | "children" | "infants";
  onChange: (newValue: number) => void;
  value: number;
}

const PassengersField = ({ description, title, max, min = 0, name, onChange, value }: Props) => {
  const disabledMax = useMemo(
    () => !Number.isNaN(Number(max)) && value >= (max as Number),
    [max, value]
  );
  const disabledMin = useMemo(() => value <= min, [min, value]);

  const increment = useCallback(() => {
    const event =
      name === "adults"
        ? WidgetGATriggerEvents.FlightExtendedSearchAdultAdded
        : name === "children"
        ? WidgetGATriggerEvents.FlightExtendedSearchChildAdded
        : WidgetGATriggerEvents.FlightExtendedSearchInfantAdded;
    dispatchCustomEvent({ widgetEvent: event });
    onChange(value + 1);
  }, [onChange, value, name]);

  const decrement = useCallback(() => {
    const event =
      name === "adults"
        ? WidgetGATriggerEvents.FlightExtendedSearchAdultRemoved
        : name === "children"
        ? WidgetGATriggerEvents.FlightExtendedSearchChildRemoved
        : WidgetGATriggerEvents.FlightExtendedSearchInfantRemoved;
    dispatchCustomEvent({ widgetEvent: event });
    onChange(value - 1);
  }, [onChange, value, name]);

  return (
    <Container>
      <div>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </div>
      <ButtonsContainer>
        <IconButton bordered color="white" disabled={disabledMin} onlyIcon onClick={decrement}>
          <MinusIcon />
        </IconButton>
        <Value>{value.toString()}</Value>
        <IconButton bordered color="white" disabled={disabledMax} onlyIcon onClick={increment}>
          <PlusIcon />
        </IconButton>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 19px;
  }
`;

const Title = styled(NexaTypography)`
  font-family: ${({ theme }) => theme.heavyFont};
`;

const Description = styled(NexaTypography)``;

const Value = styled(NexaTypography)`
  display: inline-flex;
  justify-content: center;
  width: 26px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled(Button)`
  height: 25px;
  width: 25px;
  padding: 0;

  svg {
    width: 11px;
  }
`;

export default PassengersField;
