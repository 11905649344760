const Close = ({ width = 12, height = 12, strokeWidth = 1, stroke = "#000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 11.923 11.923"
    >
      <g id="Group_580" data-name="Group 580" transform="translate(488.262 -591.749) rotate(90)">
        <path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M11.113,0,0,11.22"
          transform="translate(592.154 476.691)"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
        />
        <path
          id="Stroke_5-2"
          data-name="Stroke 5"
          d="M11.22,11.113,0,0"
          transform="translate(592.1 476.744)"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default Close;
