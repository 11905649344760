import styled from "styled-components";
import { useCallback, useMemo } from "react";
import {
  addDays,
  addMonths,
  isAfter,
  isBefore,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useDateFormatter } from "../../../../../hooks/useDateFormatter";
import { ALLOWED_DAYS_IN_FUTURE } from "../../../../../constants/flightsConstants";
import ChevronLeft from "../../../../../customIcons/ChevronLeft";
import { capitalize } from "../../../../../utils/stringUtils";
import ChevronRight from "../../../../../customIcons/ChevronRight";
import Button from "../../../../shared/Button";

interface Props {
  givenMonth: Date;
  leftmostMonth: Date;
  setMonth: (newMonth: Date) => void;
}

const DoubleMonthDayPickerCaption = ({ givenMonth, leftmostMonth, setMonth }: Props) => {
  const formatDate = useDateFormatter();

  const startOfMonthForGivenMonth = useMemo(() => {
    return startOfMonth(givenMonth);
  }, [givenMonth]);

  const startOfMonthLeftmostMonth = useMemo(() => {
    return startOfMonth(leftmostMonth);
  }, [leftmostMonth]);

  const startOfMonthForLastMonthAvailable = useMemo(() => {
    return startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
  }, []);

  const startOfMonthForCurrentMonth = useMemo(() => {
    return startOfMonth(new Date());
  }, []);

  const showLeftArrow = useMemo(() => {
    return (
      isSameMonth(startOfMonthForGivenMonth, startOfMonthLeftmostMonth) &&
      isAfter(startOfMonthForGivenMonth, startOfMonthForCurrentMonth)
    );
  }, [startOfMonthForGivenMonth, startOfMonthLeftmostMonth, startOfMonthForCurrentMonth]);

  const showRightArrow = useMemo(() => {
    return (
      isSameMonth(startOfMonthForGivenMonth, addMonths(startOfMonthLeftmostMonth, 1)) &&
      isBefore(startOfMonthForGivenMonth, startOfMonthForLastMonthAvailable)
    );
  }, [startOfMonthForGivenMonth, startOfMonthLeftmostMonth, startOfMonthForLastMonthAvailable]);

  const onPreviousClick = useCallback(() => {
    setMonth(subMonths(leftmostMonth, 1));
  }, [leftmostMonth, setMonth]);

  const onNextClick = useCallback(() => {
    setMonth(addMonths(leftmostMonth, 1));
  }, [leftmostMonth, setMonth]);

  return (
    <div className="DayPicker-Caption">
      <Container>
        {showLeftArrow && (
          <MoveButton
            onlyIcon={true}
            color="white"
            bordered={true}
            onClick={onPreviousClick}
            leftButton
          >
            <IconContainer leftIcon>
              <ChevronLeft />
            </IconContainer>
          </MoveButton>
        )}
        <Caption>{`${capitalize(formatDate(givenMonth, ["monthName", "fullYear"]))}`}</Caption>
        {showRightArrow && (
          <MoveButton onlyIcon={true} color="white" bordered={true} onClick={onNextClick}>
            <IconContainer>
              <ChevronRight />
            </IconContainer>
          </MoveButton>
        )}
      </Container>
    </div>
  );
};

const Caption = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-family: ${({ theme }) => theme.regularFont};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  position: relative;
`;

const IconContainer = styled.div<{ leftIcon?: boolean }>`
  svg {
    margin-${({ leftIcon }) => (leftIcon ? "left" : "right")}: 2px;
    margin-top: 2px;
  }
`;

const MoveButton = styled(Button)<{ leftButton?: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  ${({ leftButton }) => (leftButton ? "left" : "right")}: 0;
`;

export default DoubleMonthDayPickerCaption;
