import {
  addDays,
  addMonths,
  eachMonthOfInterval,
  getDate,
  getMonth,
  isBefore,
  isEqual,
  nextMonday,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DayPicker, { DateUtils, DayModifiers, Modifier } from "react-day-picker";
import styled from "styled-components";
import prevArrowSvg from "../../../assets/calendar_prev_arrow.svg";
import nextArrowSvg from "../../../assets/calendar_next_arrow.svg";
import { useDateFormatter } from "../../../hooks/useDateFormatter";
import useMedia from "../../../hooks/useMedia";
import { Breakpoint } from "../../../enums/Breakpoint";
import { CustomDatePickerSource } from "../../../enums/CustomDatePicker";
import { ALLOWED_DAYS_IN_FUTURE, DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";
import DoubleMonthsSlider from "../DoubleMonthsSlider";
import ExternalLayoutDayPickerCaption from "./ExternalLayoutDayPickerCaption";

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

interface Props {
  onDayClick(date: Date, wasFromSecondMonth: boolean): void;
  initialMonth: Date;
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  canChangeMonth?: boolean;
  numberOfMonths?: number;
  disabledDays?: { before: Date; after: Date };
  takeStartingMonthFrom?: CustomDatePickerSource;
  showDesktopNavbar?: boolean;
  shouldScrollOnOpen?: boolean;
}

const ExternalLayoutDayPicker = ({
  onDayClick,
  initialMonth,
  fromDate,
  toDate,
  shouldShowRange,
  canChangeMonth,
  numberOfMonths,
  disabledDays = DEFAULT_DISABLED_DAYS,
  takeStartingMonthFrom = CustomDatePickerSource.FROM_DATE,
  shouldScrollOnOpen,
}: Props) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const formatDate = useDateFormatter();
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const [manuallySelectedMonth, setManuallySelectedMonth] = useState<Date | undefined>();
  const [shownMonth, setShownMonth] = useState(initialMonth);
  const isMobile = useMedia(Breakpoint.MaxMobile);

  const monthsShownOnTop = useMemo(() => {
    const startDate = startOfMonth(new Date());
    const endDate = startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
    let dateToAdd = startDate;
    const monthsToShow = [];
    while (isBefore(dateToAdd, endDate) || isEqual(dateToAdd, endDate)) {
      monthsToShow.push(dateToAdd);
      dateToAdd = addMonths(dateToAdd, 1);
    }
    return monthsToShow;
  }, []);

  const [enteredTo, setEnteredTo] = useState<Date>();
  const handleDayMouseEnter = useCallback(
    (date: Date) => {
      const isAfterDeparture = fromDate && DateUtils.isDayAfter(date, fromDate);
      if (isAfterDeparture) {
        setEnteredTo(date);
      }
    },
    [setEnteredTo, fromDate]
  );

  const selectedDays = useMemo(() => {
    if (shouldShowRange) {
      let selectedDays: Modifier[] = fromDate ? [fromDate] : [];
      return fromDate && toDate
        ? [...selectedDays, { from: fromDate, to: toDate }]
        : fromDate && enteredTo
        ? [...selectedDays, { from: fromDate, to: enteredTo }]
        : selectedDays;
    } else {
      return fromDate;
    }
  }, [shouldShowRange, fromDate, toDate, enteredTo]);

  const modifiers = useMemo(
    () => (shouldShowRange ? { end: toDate, start: fromDate } : undefined),
    [fromDate, shouldShowRange, toDate]
  );

  const isToDatePicker = useMemo(() => {
    return takeStartingMonthFrom === CustomDatePickerSource.TO_DATE;
  }, [takeStartingMonthFrom]);

  const handleScroll = useCallback(() => {
    const start = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--start")
      ?.closest(".DayPicker-Month");

    const end = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--end")
      ?.closest(".DayPicker-Month");

    const noRange = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--selected")
      ?.closest(".DayPicker-Month");

    if (isToDatePicker && !!end) {
      end.scrollIntoView({ behavior: "smooth" });
    } else if (start) {
      start.scrollIntoView({ behavior: "smooth" });
    } else {
      noRange?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isToDatePicker]);

  // initial scroll on open
  useEffect(() => {
    setTimeout(() => {
      if (shouldScrollOnOpen) {
        handleScroll();
      }
    });
  }, [handleScroll, shouldScrollOnOpen]);

  const handleDayClick = useCallback(
    (date: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return;
      }
      const shownMonthMonth = getMonth(shownMonth);
      const dateMonth = getMonth(date);
      onDayClick(date, (shownMonthMonth + 1) % 12 === dateMonth);
    },
    [onDayClick, shownMonth]
  );

  const monthNames = useMemo(() => {
    const dates = eachMonthOfInterval({
      start: new Date(2019, 12, 1),
      end: new Date(2020, 12, 1),
    });
    return dates.map((date) => formatDate(date, "monthName")) as [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ];
  }, [formatDate]);

  const localeUtils = useMemo(
    () => ({
      formatDate: (date: Date) => formatDate(date, "date"),
      formatDay: (date: Date) => formatDate(date, "namedShortDate"),
      formatMonthTitle: (date: Date) => formatDate(date, ["monthName", "fullYear"]),
      formatWeekdayShort: (day: number) =>
        formatDate(addDays(nextMonday(new Date()), day - 1), "weekDayNameShort"),
      formatWeekdayLong: (day: number) =>
        formatDate(addDays(nextMonday(new Date()), day - 1), "weekDayName"),
      getFirstDayOfWeek: () => DayOfWeek.Monday,
      getMonths: () => monthNames,
      parseDate: (str: string) => new Date(str),
    }),
    [monthNames, formatDate]
  );

  const autoCalculatedMonth = useMemo(() => {
    if (isMobile) {
      return new Date();
    }
    return initialMonth;
  }, [initialMonth, isMobile]);

  const monthToBeShown = useMemo(
    () => manuallySelectedMonth || autoCalculatedMonth,
    [autoCalculatedMonth, manuallySelectedMonth]
  );

  useEffect(() => {
    const lastAvailableMonth = startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
    const monthForGivenDate = startOfMonth(monthToBeShown);
    const newShownMonth = isEqual(lastAvailableMonth, monthForGivenDate)
      ? subMonths(monthToBeShown, 1)
      : monthToBeShown;

    setShownMonth(newShownMonth);
  }, [monthToBeShown]);

  return (
    <Container isOneWay={!shouldShowRange} ref={calendarContainerRef}>
      {isMinTablet && (
        <DoubleMonthsSlider
          months={monthsShownOnTop}
          monthForValue={shownMonth}
          setMonth={setManuallySelectedMonth}
        />
      )}
      <DayPicker
        canChangeMonth={canChangeMonth || isMinTablet}
        disabledDays={disabledDays}
        localeUtils={localeUtils}
        captionElement={({ date }) => (
          <ExternalLayoutDayPickerCaption
            givenMonth={date}
            capitalizeMonthName={false}
            arrowsConfig={{ setMonth: setManuallySelectedMonth, leftmostMonth: shownMonth }}
          />
        )}
        initialMonth={shownMonth}
        month={shownMonth}
        numberOfMonths={numberOfMonths}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
    </Container>
  );
};

const Container = styled.div<{ isOneWay: boolean }>`
  position: relative;
  min-height: 270px;

  @media ${Breakpoint.MaxMobile} {
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.defaultBackground};
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
  }

  .DayPicker {
    color: ${({ theme }) => theme.defaultColor};
    font-family: ${({ theme }) => theme.regularFont};
    .DayPicker-NavBar {
      display: none;
    }

    &:not(.DayPicker--interactionDisabled) {
      .DayPicker-Months {
        .DayPicker-Month {
          .DayPicker-Body {
            .DayPicker-Week {
              .DayPicker-Day {
                &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--outside
                  ):hover {
                  background-color: ${({ theme }) =>
                    theme.form.calendar.hoverBackground} !important;
                }
              }
            }
          }
        }
      }
    }

    .DayPicker-Months {
      flex-wrap: nowrap;

      .DayPicker-Month {
        border-collapse: unset;
        margin-top: 0;
        height: min-content;
        &:not(:last-child) {
          margin-right: 25px;
        }

        .DayPicker-Caption {
          color: ${({ theme }) => theme.greyColor};
          font-family: ${({ theme }) => theme.heavyFont};
          font-size: 11px;
          text-align: center;
          margin: 0;
          margin-top: 25px;
          padding: 0;
          margin-bottom: 20px;

          @media ${Breakpoint.MinTablet} {
            margin: 0 0 20px 0;
          }

          @media ${Breakpoint.MaxMobile} {
            margin-top: 40px;
          }

          & > div {
            font-size: inherit;
            line-height: inherit;
          }
        }

        .DayPicker-Weekdays {
          .DayPicker-WeekdaysRow {
            .DayPicker-Weekday {
              color: ${({ theme }) => theme.greyColor};
              font-size: 11px;
              line-height: 11px;
              font-family: ${({ theme }) => theme.heavyFont};
              height: 11px;
              width: 34px;
              box-sizing: border-box;
              vertical-align: middle;
              text-align: center;
              padding-bottom: 10px;
            }
          }
        }

        .DayPicker-Body {
          .DayPicker-Week {
            .DayPicker-Day {
              color: ${({ theme }) => theme.greyColor};
              border-radius: 2px;
              box-sizing: border-box;
              font-family: ${({ theme }) => theme.regularFont};
              font-size: 13px;
              height: 46px;
              width: 46px;

              &.DayPicker-Day--today:not(.DayPicker-Day--outside):not(:hover) {
                font-weight: unset;
                border: 1px solid ${({ theme }) => theme.form.calendar.activeBackground};
                border-radius: ${({ theme }) => theme.form.calendar.currentDateBorderRadius};
                border-collapse: unset;
              }

              &.DayPicker-Day--disabled {
                opacity: 1;
                color: ${({ theme }) => theme.disabledColor};
                background: ${({ theme }) => theme.darkBackground};

                @media ${Breakpoint.MinTablet} {
                  color: inherit;
                  opacity: 25%;
                }
              }

              &.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
                background-color: ${({ isOneWay, theme }) =>
                  isOneWay
                    ? theme.form.calendar.activeBackground
                    : theme.form.calendar.hoverBackground} !important;

                ${({ isOneWay, theme }) =>
                  isOneWay && `color: ${theme.form.calendar.selectedColor};`}

                &.DayPicker-Day--start,
                &.DayPicker-Day--end {
                  background-color: ${({ theme }) =>
                    theme.form.calendar.activeBackground} !important;
                  color: ${({ theme }) => theme.form.calendar.selectedColor};
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${Breakpoint.MaxTablet} {
    .DayPicker {
      .DayPicker-wrapper {
        .DayPicker-Months {
          .DayPicker-Month {
            .DayPicker-Caption {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .DayPicker {
    .DayPicker-wrapper {
      padding: 0;

      .DayPicker-NavBar {
        .DayPicker-NavButton--prev {
          background-image: url(${prevArrowSvg});
          left: 0;
          top: -2px;
          margin: 0;
        }

        .DayPicker-NavButton--next {
          background-image: url(${nextArrowSvg});
          right: 0;
          top: -2px;
          margin: 0;
        }
      }

      .DayPicker-Months {
        @media ${Breakpoint.MaxMobile} {
          flex-direction: column;
        }
        .DayPicker-Month {
          margin: 0;
          &:not(:last-child) {
            @media ${Breakpoint.MinTablet} {
              margin-right: 25px;
            }
          }

          .DayPicker-Weekdays {
            margin-top: 0;
            .DayPicker-WeekdaysRow {
              .DayPicker-Weekday {
                height: 16px;
                width: 34px;
                padding: 0;
                padding-bottom: 10px;
              }
            }
          }
          .DayPicker-Body {
            .DayPicker-Week {
              max-height: 34px;
              height: 34px;
              .DayPicker-Day {
                height: 34px;
                width: 34px;
                min-height: 34px;
                min-width: 34px;
                background: ${({ theme }) => theme.form.calendar.dayRectBackground};
              }
            }
          }
        }
      }
    }
  }
`;

export default ExternalLayoutDayPicker;
