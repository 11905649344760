import styled from "styled-components";

interface Props {
  alignRight?: boolean;
  hasTwoDates?: boolean;
  children?: React.ReactChild[] | undefined;
  className?: string;
}

const CalendarDropdown = ({ alignRight, hasTwoDates, children, className }: Props) => {
  return (
    <Dropdown alignRight={alignRight} hasTwoDates={hasTwoDates} className={className}>
      {children}
    </Dropdown>
  );
};

const Dropdown = styled.div<{
  alignRight?: boolean;
  hasTwoDates?: boolean;
}>`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  box-sizing: border-box;
  padding: 22px 20px 20px 20px;
  position: absolute;
  z-index: 10;

  @media ${({ theme }) => theme.breakpoints.maxTablet} {
    padding-top: 21px;
    display: flex;
    justify-content: center;
  }
`;

export default CalendarDropdown;
