import axios from "axios";

export async function getLabels(
  language: string,
  keys: string[],
  isMobile: boolean,
  agentDomain: string
) {
  const { data } = await axios.get<{ [key: string]: string }>(
    `${process.env.REACT_APP_CONTENT_API_URL}/${keys
      .toString()
      .toLowerCase()}?languageCode=${language}&agentDomain=${agentDomain}&useShort=${isMobile}`,
    {
      headers: {
        "Accept-Language": language,
        "x-api-key": process.env.REACT_APP_CONTENT_API_KEY,
      },
    }
  );
  // labels with label key as fallback value
  const labels = Object.entries(data)
    .map(([key, value]) => [key, value || key])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return labels;
}
