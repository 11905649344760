// swap mobile.svg

const SwapMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_2052" data-name="Group 2052" transform="translate(-281 -232)">
        <g id="Group_266" data-name="Group 266" transform="translate(-242 -212)">
          <g
            id="Rectangle"
            transform="translate(523 444)"
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          >
            <rect width="32" height="32" rx="16" stroke="none" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
          </g>
        </g>
        <g id="Group_1579" data-name="Group 1579" transform="translate(289.505 241.831)">
          <path
            id="Path_344"
            data-name="Path 344"
            d="M.338,3.354l3-3,3,3"
            transform="translate(-0.342 -0.684)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Line_125"
            data-name="Line 125"
            y2="13"
            transform="translate(2.995 -0.331)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_345"
            data-name="Path 345"
            d="M281.988,289.324l-3,3-3-3"
            transform="translate(-266.993 -279.655)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Line_126"
            data-name="Line 126"
            y1="13"
            transform="translate(11.995 -0.331)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
export default SwapMobile;
