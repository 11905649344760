import {
  addDays,
  addMonths,
  eachMonthOfInterval,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDateFormatter } from "../../../../../hooks/useDateFormatter";
import { ALLOWED_DAYS_IN_FUTURE } from "../../../../../constants/flightsConstants";
import ChevronLeft from "../../../../../customIcons/ChevronLeft";
import AnimatedRoundedSelect from "../../../../shared/AnimatedRoundedSelect";
import { AnimatedRoundedSelectSize } from "../../../../../enums/AnimatedRoundedSelectSize";
import { capitalize } from "../../../../../utils/stringUtils";
import ChevronRight from "../../../../../customIcons/ChevronRight";
import Button from "../../../../shared/Button";
interface Props {
  month: Date;
  setMonth: (month: Date) => void;
}

const AnimatedMonthSelect = ({ month, setMonth }: Props) => {
  const formatDate = useDateFormatter();
  const monthsToRender = useMemo(() => {
    return eachMonthOfInterval({
      start: new Date(),
      end: addDays(new Date(), ALLOWED_DAYS_IN_FUTURE),
    });
  }, []);

  const parsedCurrentDate = useMemo(() => {
    return startOfMonth(month).toDateString();
  }, [month]);

  const isCurrentMonthFirstAvailable = useMemo(() => {
    return isSameMonth(month, new Date());
  }, [month]);

  const isCurrentMonthLastAvailable = useMemo(() => {
    return isSameMonth(month, addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
  }, [month]);

  const onPreviousClick = useCallback(() => {
    setMonth(subMonths(month, 1));
  }, [month, setMonth]);

  const onNextClick = useCallback(() => {
    setMonth(addMonths(month, 1));
  }, [month, setMonth]);

  return (
    <Container className="DayPicker-Navbar">
      <ButtonContainer>
        {!isCurrentMonthFirstAvailable && (
          <MoveButton onlyIcon={true} color="white" bordered={true} onClick={onPreviousClick}>
            <IconContainer leftIcon>
              <ChevronLeft />
            </IconContainer>
          </MoveButton>
        )}
      </ButtonContainer>
      <SelectContainer>
        <AnimatedRoundedSelect
          size={AnimatedRoundedSelectSize.ExtraSmall}
          placeholder="Date"
          value={parsedCurrentDate}
          options={monthsToRender.map((m) => {
            return {
              label: `${capitalize(formatDate(m, ["monthName", "fullYear"]))}`,
              value: m.toDateString(),
            };
          })}
          onChange={(value) => setMonth(new Date(value))}
          centerValue
          nonScrollableOptionBox
        />
      </SelectContainer>
      <ButtonContainer>
        {!isCurrentMonthLastAvailable && (
          <MoveButton onlyIcon={true} color="white" bordered={true} onClick={onNextClick}>
            <IconContainer>
              <ChevronRight />
            </IconContainer>
          </MoveButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MoveButton = styled(Button)`
  width: 32px;
  height: 32px;
`;

const IconContainer = styled.div<{ leftIcon?: boolean }>`
  svg {
    margin-${({ leftIcon }) => (leftIcon ? "left" : "right")}: 2px;
    margin-top: 2px;
  }
`;

const SelectContainer = styled.div`
  width: 196px;
`;

const ButtonContainer = styled.div`
  min-width: 32px;
`;
export default AnimatedMonthSelect;
