export function hasArraySameValues(first: any[], second: any[]) {
  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (!second.includes(first[i])) {
      return false;
    }
  }
  return true;
}

export const chunkArray = (array: any[], size: number) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export const numbersRangeArray = (from: number, to: number) => {
  const length = to - from + 1;
  var arr = new Array(length);
  for (let i = 0; i < length; i++) {
    arr[i] = from + i;
  }
  return arr;
};

export const countArrayOccurences = (array: any[]): { [key: string | number | symbol]: number } => {
  return array.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
};

export const listOfArrayElementIndexes = (array: any[], element: any) => {
  return array.map((e, i) => (e === element ? i : "")).filter(String);
};
