import { isSafari } from "./browsersUtils";

export function disableScroll(selectors = ["html", "body"]) {
  if (isSafari() && (selectors.includes("html") || selectors.includes("body"))) {
    const topOffset = document.documentElement.scrollTop || document.body.scrollTop;
    document.body.style.top = `-${topOffset}px`;
  }

  const elements = selectors.map((selector) => document.querySelector(selector) as HTMLElement);
  elements.forEach((element) => {
    if (element) {
      element.classList.add("lock-position");
      if (element.tagName.toLowerCase() === "html" && navigator.appVersion.includes("Win")) {
        document.body.style.paddingRight = `17px`;
      }
    }
  });
}

export function enableScroll(selectors = ["html", "body"]) {
  if (isSafari() && (selectors.includes("html") || selectors.includes("body"))) {
    const topOffset = parseInt(document.body.style.top?.replace("px", "") || "0");
    if (topOffset < 0) {
      document.body.style.removeProperty("top");
      setTimeout(() => {
        window.scrollTo({ top: -topOffset });
      });
    }
  }

  const elements = selectors.map((selector) => document.querySelector(selector) as HTMLElement);
  elements.forEach((element) => {
    if (element) {
      element.classList.remove("lock-position");
      // in future: change navigator.appVersion to navigator.userAgentData.platform once it is not experimental anymore
      if (element.tagName.toLowerCase() === "html" && navigator.appVersion.includes("Win")) {
        document.body.style.paddingRight = "0";
      }
    }
  });
}
