import styled from "styled-components";
import {
  addDays,
  addMonths,
  isAfter,
  isBefore,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { useDateFormatter } from "../../../hooks/useDateFormatter";
import { ALLOWED_DAYS_IN_FUTURE } from "../../../constants/flightsConstants";
import { capitalize } from "../../../utils/stringUtils";
import ChevronLeft from "../../../customIcons/ChevronLeft";
import ChevronRight from "../../../customIcons/ChevronRight";
import Button from "../../shared/Button";
import useMedia from "../../../hooks/useMedia";
import { Breakpoint } from "../../../enums/Breakpoint";

interface Props {
  givenMonth: Date;
  capitalizeMonthName?: boolean;
  arrowsConfig?: {
    leftmostMonth: Date;
    setMonth: (newMonth: Date) => void;
  };
}

const ExternalLayoutDayPickerCaption = ({
  givenMonth,
  arrowsConfig,
  capitalizeMonthName = true,
}: Props) => {
  const formatDate = useDateFormatter();
  const isMinTablet = useMedia(Breakpoint.MinTablet);

  const startOfMonthForGivenMonth = useMemo(() => {
    return startOfMonth(givenMonth);
  }, [givenMonth]);

  const startOfMonthLeftmostMonth = useMemo(() => {
    return !!arrowsConfig ? startOfMonth(arrowsConfig.leftmostMonth) : undefined;
  }, [arrowsConfig]);

  const startOfMonthForLastMonthAvailable = useMemo(() => {
    return startOfMonth(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE));
  }, []);

  const startOfMonthForCurrentMonth = useMemo(() => {
    return startOfMonth(new Date());
  }, []);

  const showLeftArrow = useMemo(() => {
    return (
      isMinTablet &&
      !!arrowsConfig &&
      isSameMonth(startOfMonthForGivenMonth, startOfMonthLeftmostMonth!) &&
      isAfter(startOfMonthForGivenMonth, startOfMonthForCurrentMonth)
    );
  }, [
    startOfMonthForGivenMonth,
    arrowsConfig,
    startOfMonthLeftmostMonth,
    startOfMonthForCurrentMonth,
    isMinTablet,
  ]);

  const showRightArrow = useMemo(() => {
    return (
      isMinTablet &&
      !!arrowsConfig &&
      isSameMonth(startOfMonthForGivenMonth, addMonths(startOfMonthLeftmostMonth!, 1)) &&
      isBefore(startOfMonthForGivenMonth, startOfMonthForLastMonthAvailable)
    );
  }, [
    startOfMonthForGivenMonth,
    startOfMonthLeftmostMonth,
    startOfMonthForLastMonthAvailable,
    arrowsConfig,
    isMinTablet,
  ]);

  const onPreviousClick = useCallback(() => {
    if (!!arrowsConfig) arrowsConfig.setMonth(subMonths(arrowsConfig.leftmostMonth, 1));
  }, [arrowsConfig]);

  const onNextClick = useCallback(() => {
    if (!!arrowsConfig) arrowsConfig.setMonth(addMonths(arrowsConfig.leftmostMonth, 1));
  }, [arrowsConfig]);

  const monthNameToShow = useMemo(() => {
    return capitalizeMonthName
      ? capitalize(formatDate(givenMonth, ["monthName", "fullYear"]))
      : formatDate(givenMonth, ["monthName", "fullYear"]);
  }, [capitalizeMonthName, formatDate, givenMonth]);

  return (
    <div className="DayPicker-Caption">
      <Container>
        {showLeftArrow && (
          <MoveButton onlyIcon={true} color="white" onClick={onPreviousClick} leftButton>
            <IconContainer leftIcon>
              <ChevronLeft strokeWidth={2} />
            </IconContainer>
          </MoveButton>
        )}
        <Caption>{monthNameToShow}</Caption>
        {showRightArrow && (
          <MoveButton onlyIcon={true} color="white" onClick={onNextClick}>
            <IconContainer>
              <ChevronRight strokeWidth={2} />
            </IconContainer>
          </MoveButton>
        )}
      </Container>
    </div>
  );
};

const Caption = styled.div`
  font-size: 11px;
  line-height: 11px;
  font-family: ${({ theme }) => theme.heavyFont};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${Breakpoint.MinTablet} {
    height: 34px;
  }
`;

const IconContainer = styled.div<{ leftIcon?: boolean }>`
  display: flex;
  position: absolute;
  ${({ leftIcon }) => (leftIcon ? "left: 13px;" : "right: 13px;")}
  svg {
    width: 12px;
    height: 12px;
    stroke-width: 2px;
  }
`;

const MoveButton = styled(Button)<{ leftButton?: boolean }>`
  width: 34px;
  height: 34px;
  position: absolute;
  ${({ leftButton }) => (leftButton ? "left" : "right")}: 0;
  background-color: ${({ theme }) => theme.darkBackground};
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
`;

export default ExternalLayoutDayPickerCaption;
