import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useRequest } from "../api";
import {
  getAutocompleteHotels,
  GetAutocompleteHotelsResponse,
} from "../api/actions/getAutocompleteHotels";
import { HotelLocationType } from "../enums/HotelLocationType";
import { useTranslation } from "../i18n";
import { usePushErrorLog } from "./usePushErrorLog";
import { AutocompleteHotel } from "../types/Autocomplete";
import { AutocompleteValue } from "../components/HotelsSearchForm/Tix/AutocompleteField/types";
import { dispatchCustomEvent } from "../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../enums/WidgetEvents";

const LOAD_DELAY = 100;

const TYPE_LABELS = {
  [HotelLocationType.Airport]: null,
  [HotelLocationType.City]: "tix_search_form_autocomplete_city",
  [HotelLocationType.Country]: "tix_search_form_autocomplete_country",
  [HotelLocationType.Hotel]: null,
  [HotelLocationType.Region]: "tix_search_form_autocomplete_region",
};

export const useHotelAutocompleteSuggestions = () => {
  const { t } = useTranslation();
  const request = useRequest();
  const logError = usePushErrorLog();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<AutocompleteValue[]>([]);

  const createDescription = useCallback(
    (suggestion: AutocompleteHotel) => {
      const typeLabel = TYPE_LABELS[suggestion.type];
      const typePart = suggestion.accommodationType?.name || (!!typeLabel ? t(typeLabel) : null);
      return [typePart, suggestion.city?.name, suggestion.region?.name, suggestion.country?.name]
        .filter(Boolean)
        .join(" - ");
    },
    [t]
  );

  const fetchSuggestions = useDebouncedCallback(async (query: string) => {
    setIsLoading(true);
    try {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.AutocompleteHotelsRequest });
      const locations = await request<GetAutocompleteHotelsResponse>(
        getAutocompleteHotels({ query })
      );
      const suggestions = locations.map((suggestion) => ({
        code: suggestion.referenceId,
        name: suggestion.name,
        type: suggestion.type,
        description: createDescription(suggestion),
      }));
      setSuggestions(suggestions);
    } catch (error) {
      logError(error, {
        customMessage: "Error during fetching hotels autocomplete suggestions",
      });
    } finally {
      setIsLoading(false);
    }
  }, LOAD_DELAY);

  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, []);

  return {
    clearSuggestions,
    fetchSuggestions,
    isLoading,
    suggestions,
  };
};
