import qs from "qs";
import { useCallback, useMemo } from "react";

export function useQueryParams<T>() {
  const getQueryParams = useCallback(() => {
    return qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as Partial<T>;
  }, []);

  const queryParams = useMemo(() => getQueryParams(), [getQueryParams]);

  const patchQueryParams = useCallback(
    (params: Partial<T>) => {
      const updatedQueryParams = { ...getQueryParams(), ...params };
      const newUrl = `${window.location.pathname}?${qs.stringify(
        updatedQueryParams
      )}`;
      window.history.replaceState(window.history.state, "", newUrl);
    },
    [getQueryParams]
  );

  return {
    queryParams,
    patchQueryParams,
  };
}
