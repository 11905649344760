import styled from "styled-components";
import NexaTypography from "../../../../shared/NexaTypography";
import HotelBedIcon from "../../../../../customIcons/HotelBed";
import HotelLocationIcon from "../../../../../customIcons/HotelLocation";
import { AutocompleteValue } from "../types";
import { LOCATION_TYPES } from "../constants";

type Props = AutocompleteValue;

const Suggestion = (props: Props) => {
  return (
    <Container>
      {LOCATION_TYPES.includes(props.type) ? <HotelLocationIcon /> : <HotelBedIcon />}
      <Text isHeavy>{props.name}</Text>
      <Text>{props.description}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 9px 20px 9px 38px;

  svg {
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Text = styled(NexaTypography)`
  height: 16px;
  overflow-x: clip;
  text-overflow: ellipsis;
`;

export default Suggestion;
