import { FormikErrors, FormikTouched, getIn } from "formik";
import { useCallback, useMemo, useRef } from "react";
import styled, { useTheme } from "styled-components";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesBound,
  FlightsSearchFormValuesType as FlightType,
} from "../../../types/FlightsSearchFormValues";
import ExtendedFlightSearch from "./ExtendedFlightSearch";
import FlightDateField from "./FlightDateField";
import Button from "../../shared/Button";
import ErrorMessage from "../../shared/ErrorMessage";
import useMedia from "../../../hooks/useMedia";
import AirportAutocompleteField from "./AirportAutocompleteField";
import { useTranslation } from "../../../i18n";
import { InputKey, useWizardContext } from "../WizardContext";

interface Props {
  boundsIndexes: number[];
  errors: FormikErrors<FlightsSearchFormValues>;
  touched: FormikTouched<FlightsSearchFormValues>;
  isLastRow?: boolean;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onDateSelect?(): void;
  onSubmit(): void;
  showExtendedFlightSearch?: boolean;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
  departureCodeInputKey: InputKey;
}

const SearchFormRow = ({
  boundsIndexes,
  errors,
  touched,
  isLastRow,
  onChange,
  onDateSelect,
  onSubmit,
  showExtendedFlightSearch,
  values,
  scrollControlled,
  departureCodeInputKey,
}: Props) => {
  const { t } = useTranslation();
  const { setInputRef, startWizard } = useWizardContext();
  const destinationInputRef = useRef<HTMLInputElement>(null);
  const departureDateInputRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMinTablet = useMedia(theme.breakpoints.minTablet);
  const isMinDesktop = useMedia(theme.breakpoints.minDesktop);

  const swapAirports = useCallback(async () => {
    const copiedBounds = [...values.bounds];
    const baseBound = {
      departureCode: values.bounds[boundsIndexes[0]]?.destinationCode,
      departureName: values.bounds[boundsIndexes[0]]?.destinationName,
      departureDate: values.bounds[boundsIndexes[0]]?.departureDate,
      destinationCode: values.bounds[boundsIndexes[0]]?.departureCode,
      destinationName: values.bounds[boundsIndexes[0]]?.departureName,
    };
    copiedBounds[boundsIndexes[0]] = baseBound;
    if (values.type === FlightType.Return && boundsIndexes.length > 1) {
      const secondBound = {
        departureCode: values.bounds[boundsIndexes[1]]?.destinationCode,
        departureName: values.bounds[boundsIndexes[1]]?.destinationName,
        departureDate: values.bounds[boundsIndexes[1]]?.departureDate,
        destinationCode: values.bounds[boundsIndexes[1]]?.departureCode,
        destinationName: values.bounds[boundsIndexes[1]]?.departureName,
      };
      copiedBounds[boundsIndexes[1]] = secondBound;
    }
    onChange({ bounds: copiedBounds });
  }, [boundsIndexes, onChange, values.bounds, values.type]);

  const airportsError = useMemo(() => {
    if (errors.bounds) {
      const [error] = (errors.bounds as FormikErrors<FlightsSearchFormValuesBound[]>)
        .map((boundErrors, index) => {
          if (boundErrors?.departureCode && getIn(touched, `bounds[${index}].departureCode`)) {
            return boundErrors.departureCode;
          }
          if (boundErrors?.destinationCode && getIn(touched, `bounds[${index}].destinationCode`)) {
            return boundErrors.destinationCode;
          }
          return undefined;
        })
        .filter(Boolean);
      return error;
    }
  }, [errors, touched]);
  const datesError = useMemo(() => {
    if (errors.bounds) {
      const [error] = (errors.bounds as FormikErrors<FlightsSearchFormValuesBound[]>)
        .map((boundErrors, index) => {
          if (boundErrors?.departureDate && getIn(touched, `bounds[${index}].departureDate`)) {
            return boundErrors.departureDate;
          }
          return undefined;
        })
        .filter(Boolean);
      return error;
    }
  }, [errors, touched]);
  const showErrors = useMemo(
    () => isMinDesktop && isLastRow && (airportsError || datesError),
    [airportsError, datesError, isLastRow, isMinDesktop]
  );

  const onDepartureSelect = useCallback(() => {
    // Mobile: should open destination modal if destination is empty
    // Tablet/Desktop: should focus destination field
    if (isMinTablet || (!isMinTablet && !destinationInputRef?.current?.value)) {
      startWizard();
      destinationInputRef?.current?.focus();
    }
  }, [isMinTablet, startWizard]);
  const onDestinationSelect = useCallback(() => {
    // should open departure date modal if departure date is empty
    if (!values.bounds[boundsIndexes[0]].departureDate) {
      startWizard();
      departureDateInputRef?.current?.focus();
    }
  }, [boundsIndexes, startWizard, values.bounds]);

  return (
    <Container>
      <Row>
        <AirportAutocompleteField
          ref={setInputRef(departureCodeInputKey)}
          boundsIndexes={boundsIndexes}
          direction="departure"
          errors={errors}
          onChange={onChange}
          onOptionSelect={onDepartureSelect}
          onSwap={swapAirports}
          scrollControlled={scrollControlled}
          touched={touched}
          values={values}
        />
        <AirportAutocompleteField
          ref={destinationInputRef}
          boundsIndexes={boundsIndexes}
          direction="destination"
          errors={errors}
          touched={touched}
          onChange={onChange}
          onOptionSelect={onDestinationSelect}
          values={values}
          scrollControlled={scrollControlled}
        />
      </Row>
      <Row>
        <DatesContainer>
          <FlightDateField
            ref={departureDateInputRef}
            errors={errors}
            touched={touched}
            boundsIndexes={boundsIndexes}
            onChange={onChange}
            values={values}
            scrollControlled={scrollControlled}
            onDateSelect={onDateSelect}
          />
        </DatesContainer>
        {showExtendedFlightSearch && (
          <ExtendedFlightSearch
            onChange={onChange}
            values={values}
            scrollControlled={scrollControlled}
            onSubmit={onSubmit}
          />
        )}
        {isMinTablet && !showExtendedFlightSearch && isLastRow && (
          <SubmitButton type="submit" onClick={onSubmit}>
            {t("tix_search_form_search_flights")}
          </SubmitButton>
        )}
      </Row>
      {showErrors && (
        <>
          <Row>
            {airportsError && (
              <AirportsErrorMessage>
                <ErrorMessage error={airportsError} />
              </AirportsErrorMessage>
            )}
          </Row>
          <Row>
            {datesError && (
              <DatesErrorMessage>
                <ErrorMessage error={datesError} />
              </DatesErrorMessage>
            )}
            {showExtendedFlightSearch && (
              <SubmitButton type="submit" onClick={onSubmit}>
                {t("tix_search_form_search_flights")}
              </SubmitButton>
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

const DatesContainer = styled.div`
  @media ${({ theme }) => theme.breakpoints.minTablet} {
    margin-right: 9px;
    width: calc(50% - 4.5px);
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    margin: 0 -1px 9px 0;
    flex-shrink: 0;
    width: 50%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    flex-basis: 50%;
    flex-shrink: 0;
    width: 50%;
  }
`;

const AirportsErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const DatesErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 10px;
`;

const SubmitButton = styled(Button)`
  margin: 0 0 0 auto;
  width: 200px;
  height: 44px;
  background-color: ${({ theme }) => theme.flightSearchForm.buttonColor};
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 20px;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    height: 48px;
  }
  @media ${({ theme }) => theme.breakpoints.maxTablet} {
    font-size: 16px;
  }
`;

export default SearchFormRow;
