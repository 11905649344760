import styled from "styled-components";
import CustomDayPickerNavbar from "./components/AnimatedMonthSelect";
import GenericDayPicker from "../GenericDayPicker";
import { useMemo, useState } from "react";

interface Props {
  disabledDays: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  initialMonth: Date;
  onDayClick: (date: Date) => void;
}

const SingleMonthDayPicker = ({
  disabledDays,
  fromDate,
  toDate,
  onDayClick,
  initialMonth,
  shouldShowRange,
}: Props) => {
  const [manuallySelectedMonth, setManuallySelectedMonth] = useState<Date | undefined>();
  const shownMonth = useMemo(() => {
    return manuallySelectedMonth || initialMonth;
  }, [manuallySelectedMonth, initialMonth]);
  return (
    <Container>
      <CustomDayPickerNavbar month={shownMonth} setMonth={setManuallySelectedMonth} />
      <GenericDayPicker
        canChangeMonth={true}
        numberOfMonths={1}
        disabledDays={disabledDays}
        initialMonth={shownMonth}
        monthToShow={shownMonth}
        fromDate={fromDate}
        toDate={toDate}
        onDayClick={onDayClick}
        shouldShowRange={shouldShowRange}
      />
    </Container>
  );
};

const Container = styled.div`
  .DayPicker {
    .DayPicker-wrapper {
      padding-bottom: 0px;
    }
  }
  .DayPicker-Months {
    .DayPicker-Month {
      margin: 0;
      .DayPicker-Caption {
        display: none;
      }

      .DayPicker-Weekdays {
        margin: 0;
        .DayPicker-WeekdaysRow {
          .DayPicker-Weekday {
            height: 40px;
            width: 40px;
            padding-top: 8px;
            padding-bottom: 4px;
          }
        }
      }

      .DayPicker-Body {
        .DayPicker-Week {
          .DayPicker-Day {
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
`;

export default SingleMonthDayPicker;
