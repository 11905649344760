import { Breakpoint } from "./../enums/Breakpoint";
import { useEffect, useState } from "react";

export default function useMedia(breakpoint: Breakpoint | string) {
  const matchMedia = window.matchMedia(`${breakpoint}`);
  const [isMatchingMedia, setIsMatchingMedia] = useState(matchMedia.matches);

  useEffect(() => {
    const handler = () => {
      setIsMatchingMedia(matchMedia.matches);
    };
    try {
      matchMedia.addEventListener("change", handler);
    } catch (e) {
      matchMedia.addListener(handler);
    }
    return () => {
      try {
        matchMedia.removeEventListener("change", handler);
      } catch (e) {
        matchMedia.removeListener(handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMatchingMedia;
}
