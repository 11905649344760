import { FC, useEffect, useRef } from "react";
import { RenderSuggestionsContainerParams } from "react-autosuggest";
import styled from "styled-components";
import { useSearchFormWithFocusScroll } from "../../../../../hooks/useSearchFormWithFocusScroll";

interface Props extends RenderSuggestionsContainerParams {
  isFirstHighlighted: boolean;
}

const SuggestionsContainer: FC<Props> = ({ children, containerProps, isFirstHighlighted }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();
  useEffect(() => {
    if (children) {
      if (ref.current) {
        handleScrollToSearchFormIfNeeded(ref.current.childNodes[0] as HTMLElement);
      }
    }
  }, [children, handleScrollToSearchFormIfNeeded]);

  return (
    <div ref={ref}>
      <Container isFirstHighlighted={isFirstHighlighted} {...containerProps}>
        {children}
      </Container>
    </div>
  );
};

const Container = styled.div<{ isFirstHighlighted: boolean }>`
  box-sizing: border-box;
  top: calc(44px + 6px);
  position: absolute;
  left: 0;
  max-height: calc(250px + 13px + 20px);
  min-width: 100%;
  overflow: auto;
  white-space: nowrap;
  z-index: 9;

  &::before {
    content: "";
    opacity: 1;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent
      ${({ isFirstHighlighted, theme }) =>
        isFirstHighlighted
          ? theme.form.selectField.defaultItemHoverBackground
          : theme.defaultBackground}
      transparent;
    background: transparent;
    left: 50%;
    transform: translateX(-50%);
  }

  & > ul.react-autosuggest__suggestions-list {
    background: ${({ theme }) => theme.defaultBackground};
    box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
    list-style: none;
    margin: 13px 24px 20px 26px;
    padding: 0;
    overflow: auto;
    max-height: 250px;

    li.react-autosuggest__suggestion {
      background: ${({ theme }) => theme.defaultBackground};
      cursor: pointer;

      &[aria-selected="true"] {
        background: ${({ theme }) => theme.form.selectField.defaultItemHoverBackground};
      }
    }
  }
`;

export default SuggestionsContainer;
