import { useCallback, useMemo } from "react";
import { isWithinInterval } from "date-fns";
import { CustomDatePickerSource } from "../enums/CustomDatePicker";
import { DEFAULT_DISABLED_DAYS } from "../constants/flightsConstants";

export default function useInitialMonth(
  source: CustomDatePickerSource,
  disabledDays: { before: Date; after: Date },
  fromDate?: Date,
  toDate?: Date
) {
  const isDateInValidBound = useCallback(
    (date: Date) => {
      return isWithinInterval(date, {
        start: disabledDays.before,
        end: DEFAULT_DISABLED_DAYS.after,
      });
    },
    [disabledDays]
  );
  const isToDatePicker = useMemo(() => {
    return source === CustomDatePickerSource.TO_DATE;
  }, [source]);

  const initialMonthForDeparture = useMemo(() => {
    if (!fromDate) return disabledDays.before;
    if (!isDateInValidBound(fromDate)) return disabledDays.before;
    return fromDate;
  }, [fromDate, isDateInValidBound, disabledDays]);

  const initialMonthForReturn = useMemo(() => {
    if (!toDate) return initialMonthForDeparture;
    if (!isDateInValidBound(toDate)) return initialMonthForDeparture;
    return toDate;
  }, [toDate, initialMonthForDeparture, isDateInValidBound]);

  const monthToShow = useMemo(() => {
    return isToDatePicker ? initialMonthForReturn : initialMonthForDeparture;
  }, [initialMonthForDeparture, initialMonthForReturn, isToDatePicker]);

  return monthToShow;
}
