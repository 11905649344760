import { useMemo } from "react";
import styled from "styled-components";
import { Breakpoint } from "../../../enums/Breakpoint";
import useMedia from "../../../hooks/useMedia";
import ErrorMessage from "../../shared/ErrorMessage";
import Button from "../../shared/Button";
import AutocompleteField from "./AutocompleteField";
import DatesRangeField from "./HotelDateField";
import RoomsCountField from "./RoomsCountField";
import { useTranslation } from "../../../i18n";
import { HotelsSearchFormProps } from "../../../types/HotelsSearchFormProps";

export const HotelsSearchForm = ({
  errors,
  hasErrors,
  touched,
  onChange,
  onSubmit,
  values,
}: HotelsSearchFormProps) => {
  const { t } = useTranslation();
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const isMaxTablet = useMedia(Breakpoint.MaxTablet);
  const isMinDesktop = useMedia(Breakpoint.MinDesktop);

  const autocompleteError = useMemo(() => {
    if (touched.locationCode && errors.locationCode) {
      return errors.locationCode;
    }
  }, [errors.locationCode, touched.locationCode]);
  const datesError = useMemo(() => {
    if (touched.checkInDate && errors.checkInDate) {
      return errors.checkInDate;
    }
    if (touched.checkOutDate && errors.checkOutDate) {
      return errors.checkOutDate;
    }
  }, [errors.checkInDate, errors.checkOutDate, touched.checkInDate, touched.checkOutDate]);
  const showErrors = useMemo(
    () => isMinDesktop && (autocompleteError || datesError),
    [autocompleteError, datesError, isMinDesktop]
  );

  return (
    <form onSubmit={onSubmit} noValidate>
      <FieldsContainer>
        <AutocompleteContainer>
          <AutocompleteField
            errors={errors}
            onChange={onChange}
            touched={touched}
            values={values}
          />
        </AutocompleteContainer>
        <DatesContainer>
          <DatesRangeField errors={errors} onChange={onChange} touched={touched} values={values} />
        </DatesContainer>
        <RoomsCountField onChange={onChange} values={values.rooms} onSubmit={onSubmit} />
        {showErrors && (
          <>
            <AutocompleteContainer>
              {autocompleteError && (
                <AutocompleteErrorMessage>
                  <ErrorMessage error={autocompleteError} />
                </AutocompleteErrorMessage>
              )}
            </AutocompleteContainer>
            <div>
              {datesError && (
                <DatesErrorMessage>
                  <ErrorMessage error={datesError} />
                </DatesErrorMessage>
              )}
            </div>
            <SubmitButton type="submit" onClick={onSubmit}>
              {t("tix_search_form_search_hotels")}
            </SubmitButton>
          </>
        )}
      </FieldsContainer>
      {isMaxMobile && hasErrors && (
        <FormErrorMessage>
          <ErrorMessage error="tix_search_form_err_hotels_search_form_global" />
        </FormErrorMessage>
      )}
      <SubmitButtonRow>
        {!showErrors && (
          <SubmitButton type="submit" onClick={onSubmit} size={isMinDesktop ? "medium" : "small"}>
            {t("tix_search_form_search_hotels")}
          </SubmitButton>
        )}
        <div>
          {isMinTablet && isMaxTablet && hasErrors && (
            <FormErrorMessage>
              <ErrorMessage error="tix_search_form_err_hotels_search_form_global" />
            </FormErrorMessage>
          )}
        </div>
      </SubmitButtonRow>
    </form>
  );
};

const DatesContainer = styled.div`
  @media ${Breakpoint.MinTablet} {
    margin-right: 9px;
    width: calc(50% - 4.5px);
    flex-basis: calc(50% - 4.5px);
  }

  @media ${Breakpoint.MinDesktop} {
    margin: 0 -1px 9px 0;
    flex-shrink: 0;
    flex-basis: 34.5%;
    width: 34.5%;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Breakpoint.MinTablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const AutocompleteContainer = styled.div`
  flex-shrink: 0;
  flex-basis: 100%;
  width: 100%;

  @media ${Breakpoint.MinDesktop} {
    flex-basis: 34.5%;
    width: 34.5%;
  }
`;

const AutocompleteErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const DatesErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 10px;
`;

const FormErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const SubmitButtonRow = styled.div`
  @media ${Breakpoint.MinTablet} {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media ${Breakpoint.MinDesktop} {
    margin-top: 1px;

    & > div {
      margin: 0 auto 0 0;
    }
  }
`;

const SubmitButton = styled(Button)`
  margin: 0 0 0 auto;
  width: 160px;
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 20px;

  @media ${Breakpoint.MaxTablet} {
    font-size: 16px;
  }

  @media ${Breakpoint.MinDesktop} {
    width: 200px;
    height: 48px;
  }
`;

export default HotelsSearchForm;
