import { BrowserType } from "../enums/BrowserType";

export const browserType = (): BrowserType => {
  // in future: change navigator.userAgent to navigator.userAgentData.brands once it is not experimental anymore
  const lowerredUserAgent = navigator.userAgent.toLowerCase();

  if (lowerredUserAgent.includes(BrowserType.Chrome)) return BrowserType.Chrome;
  if (lowerredUserAgent.includes(BrowserType.Firefox)) return BrowserType.Firefox;
  if (lowerredUserAgent.includes(BrowserType.Safari)) return BrowserType.Safari;
  if (lowerredUserAgent.includes(BrowserType.Edge)) return BrowserType.Edge;
  if (lowerredUserAgent.includes(BrowserType.Opera)) return BrowserType.Opera;
  if (lowerredUserAgent.includes(BrowserType.InternetExplorer)) return BrowserType.InternetExplorer;
  return BrowserType.Unknown;
};

export const isSafari = () => {
  return browserType() === BrowserType.Safari;
};

export const isFirefox = () => {
  return browserType() === BrowserType.Firefox;
};

export const isChrome = () => {
  return browserType() === BrowserType.Chrome;
};

export const isEdge = () => {
  return browserType() === BrowserType.Edge;
};

export const isOpera = () => {
  return browserType() === BrowserType.Opera;
};

export const isInternetExplorer = () => {
  return browserType() === BrowserType.Firefox;
};
