import { FC, useEffect, useRef } from "react";
import { RenderSuggestionsContainerParams } from "react-autosuggest";
import styled from "styled-components";
import { Breakpoint } from "../../../../../enums/Breakpoint";
import { isSafari } from "../../../../../utils/browsersUtils";
import { useSearchFormWithFocusScroll } from "../../../../../hooks/useSearchFormWithFocusScroll";

interface Props extends RenderSuggestionsContainerParams {
  isFirstHighlighted: boolean;
  placeholder: string;
  title?: string;
}

const SuggestionsContainer: FC<Props> = ({
  children,
  placeholder,
  title,
  containerProps,
  isFirstHighlighted,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();
  useEffect(() => {
    if (children) {
      if (ref.current) {
        handleScrollToSearchFormIfNeeded(ref.current.childNodes[0] as HTMLElement);
      }
    }
  }, [children, handleScrollToSearchFormIfNeeded]);

  return (
    <div ref={ref}>
      {children && (
        <Container isFirstHighlighted={isFirstHighlighted} {...containerProps}>
          <ContainerHeader>
            {title && <Title>{title}</Title>}
            <Placeholder>{placeholder}</Placeholder>
          </ContainerHeader>
          {children}
        </Container>
      )}
    </div>
  );
};

const Container = styled.div<{ isFirstHighlighted: boolean }>`
  position: absolute;
  box-sizing: border-box;
  border-radius: 10px;
  top: -5px;
  left: -5px;
  min-width: 400px;
  overflow: auto;
  white-space: nowrap;
  z-index: 9;
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};

  @media ${Breakpoint.MaxTablet} {
    min-width: 375px;
  }

  & > ul.react-autosuggest__suggestions-list {
    border-radius: 10px;
    list-style: none;
    padding: 0;
    overflow: auto;
    max-height: 220px;
    min-height: 220px;
    margin: 15px 0 5px 0;

    @media ${Breakpoint.MinTablet} {
      height: 80px;
    }

    li.react-autosuggest__suggestion {
      background: ${({ theme }) => theme.defaultBackground};
      cursor: pointer;

      &[aria-selected="true"] {
        background: ${({ theme }) => theme.form.selectField.defaultItemHoverBackground};
      }
    }
  }
`;

const ContainerHeader = styled.div`
  position: relative;
  border-bottom: 1px solid;
  padding: 37px 0 0 0px;
  margin: 0 25px;
`;

const Placeholder = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.heavyFont};
  line-height: 17px;
  padding-bottom: 20px;
  margin-top: -1px;
  ${() => (isSafari() ? "margin-top: -0.5px" : "")};
`;

const Title = styled.div`
  position: absolute;
  font-size: 11px;
  line-height: 17px;
  z-index: 1;
  top: 15px;
`;

export default SuggestionsContainer;
