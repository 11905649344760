import { useEffect, useRef } from "react";
import GenericDayPicker from "../GenericDayPicker";
import styled from "styled-components";

interface Props {
  disabledDays: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  onDayClick: (date: Date) => void;
  shouldShowRange?: boolean;
  scrollToElement?: "start" | "end";
}

const MonthListDayPicker = ({
  disabledDays,
  fromDate,
  toDate,
  onDayClick,
  shouldShowRange,
  scrollToElement,
}: Props) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const start = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--start")
      ?.closest(".DayPicker-Month");

    const end = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--end")
      ?.closest(".DayPicker-Month");

    const noRange = calendarContainerRef?.current
      ?.querySelector(".DayPicker-Day--selected")
      ?.closest(".DayPicker-Month");

    if (scrollToElement === "end" && !!end) {
      end.scrollIntoView({ behavior: "smooth" });
    } else if (scrollToElement === "start" && !!start) {
      start.scrollIntoView({ behavior: "smooth" });
    } else {
      noRange?.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToElement]);

  return (
    <Container ref={calendarContainerRef}>
      <GenericDayPicker
        onDayClick={onDayClick}
        fromDate={fromDate}
        toDate={toDate}
        shouldShowRange={shouldShowRange}
        canChangeMonth={false}
        disabledDays={disabledDays}
        numberOfMonths={13}
        initialMonth={new Date()}
        monthToShow={new Date()}
      />
    </Container>
  );
};

const Container = styled.div`
  .DayPicker {
    .DayPicker-wrapper {
      .DayPicker-Months {
        .DayPicker-Month {
          .DayPicker-Caption {
            font-size: 14px;
          }
          .DayPicker-Body {
            .DayPicker-Week {
              .DayPicker-Day {
                height: 48px;
                width: 48px;
              }
            }
          }
        }
      }
    }
  }
`;

export default MonthListDayPicker;
