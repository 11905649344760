import styled from "styled-components";
import { Breakpoint } from "../../enums/Breakpoint";

type TypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "large"
  | "normal"
  | "small"
  | "extremelySmall";

interface TypographyConfig {
  fontSize: string;
  lineHeight: string;
  isHeavy?: boolean;
}

export type BreakpointsVariants = Partial<Record<Breakpoint, TypographyVariant>>;

const CONFIGS: Record<TypographyVariant, TypographyConfig> = {
  h1: {
    fontSize: "48px",
    lineHeight: "48px",
    isHeavy: true,
  },
  h2: {
    fontSize: "32px",
    lineHeight: "32px",
    isHeavy: true,
  },
  h3: {
    fontSize: "28px",
    lineHeight: "28px",
    isHeavy: true,
  },
  h4: {
    fontSize: "22px",
    lineHeight: "22px",
    isHeavy: true,
  },
  h5: {
    fontSize: "18px",
    lineHeight: "18px",
    isHeavy: true,
  },
  large: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  normal: {
    fontSize: "14px",
    lineHeight: "18px",
  },
  small: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  extremelySmall: {
    fontSize: "9px",
    lineHeight: "10px",
  },
};

interface Props {
  defaultVariant?: TypographyVariant;
  breakpointsVariants?: BreakpointsVariants;
  isHeavy?: boolean;
}

const NexaTypography = styled.div<Props>`
  font-family: ${({ defaultVariant = "normal", isHeavy, theme }) =>
      isHeavy || CONFIGS[defaultVariant].isHeavy ? theme.heavyFont : theme.regularFont},
    sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 0px;
  font-weight: normal;
  font-size: ${({ defaultVariant = "normal" }) => CONFIGS[defaultVariant].fontSize};
  line-height: ${({ defaultVariant = "normal" }) => CONFIGS[defaultVariant].lineHeight};

  ${({ breakpointsVariants }) => {
    if (!breakpointsVariants) return "";
    return Object.entries(breakpointsVariants).map(([breakpoint, variant]) => {
      return `
        @media ${breakpoint} {
          font-size: ${CONFIGS[variant].fontSize};
          line-height: ${CONFIGS[variant].lineHeight};
        }
      `;
    });
  }}
`;

export default NexaTypography;
