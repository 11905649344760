import { useCallback, useState } from "react";
import styled from "styled-components";
import EditIcon from "../../../../customIcons/Edit";
import Mk1Typography from "../../../shared/Mk1Typography";
import ErrorMessage from "../../../shared/ErrorMessage";
import Button from "../../../shared/Button";
import { MAX_CHILD_AGE, MIN_CHILD_AGE } from "../../../../constants/hotelConstants";
import { useTranslation } from "../../../../i18n";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";

const CHILDREN_AGE_OPTIONS = Array(MAX_CHILD_AGE - MIN_CHILD_AGE + 1)
  .fill(null)
  .map((_, index) => MIN_CHILD_AGE + index);

interface Props {
  childIndex: number;
  hasError?: boolean;
  onChange(age: number | null): void;
  value: number | null;
}

const ChildAgeSelect = ({ childIndex, hasError, onChange, value }: Props) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const handleChange = useCallback(
    (value: number | null) => {
      onChange(value);
      setIsHovered(false);
    },
    [onChange]
  );

  return typeof value === "number" ? (
    <ChildAgeLabel>
      <Text>{`${t("tix_search_form_child")} ${childIndex} ${t("tix_search_form_age")}`}</Text>
      <EditAgeButton
        fontType="Regular"
        color="white"
        onClick={() => {
          dispatchCustomEvent({
            widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchChildAgeEdit,
          });
          handleChange(null);
        }}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? <EditIcon /> : value.toString()}
      </EditAgeButton>
    </ChildAgeLabel>
  ) : (
    <>
      <SelectAgeContainer hasError={hasError}>
        <Text>{t("tix_search_form_select_child_age", { index: childIndex })}</Text>
        <AgeButtons>
          {CHILDREN_AGE_OPTIONS.map((age) => (
            <AgeButton
              key={age}
              bordered
              variant="rectangle"
              fontType="Regular"
              color="white"
              onClick={() => handleChange(age)}
            >
              {age.toString()}
            </AgeButton>
          ))}
        </AgeButtons>
      </SelectAgeContainer>
      {hasError && (
        <ErrorMessageContainer>
          <ErrorMessage
            error="tix_search_form_hotels_search_form_child_age_err"
            options={{ index: childIndex }}
          />
        </ErrorMessageContainer>
      )}
    </>
  );
};

const ChildAgeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectAgeContainer = styled.div<{ hasError?: boolean }>``;

const AgeButtons = styled.div`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;
`;

const EditAgeButton = styled(Button)`
  font-size: 11px;
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  padding: 0;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  margin: 0;
  border: 0;
  background-color: ${({ theme }) => theme.defaultBackground};
  color: ${({ theme }) => theme.greyColor};
  svg {
    height: 11px;
    width: 11px;
  }
`;

const AgeButton = styled(Button)`
  font-size: 13px;
  font-family: ${({ theme }) => theme.heavyFont};
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  padding: 0;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  margin: 0;
  border: 0;
  background-color: ${({ theme }) => theme.darkBackground};
  svg {
    height: 11px;
    width: 11px;
  }
`;

const Text = styled(Mk1Typography)`
  font-size: 11px;
  line-height: 17px;
  color: ${({ theme }) => theme.greyColor};
`;

const ErrorMessageContainer = styled.div`
  & > * {
    border: none;
    padding: 10px 0;
    min-height: auto;
  }

  & * {
    font-size: 11px;
  }
`;

export default ChildAgeSelect;
