import { vliegticketsTheme } from "./vliegticketsTheme";
import { tixYellowTheme } from "./tixYellowTheme";
import { DefaultTheme } from "styled-components";
import { ThemeType } from "../enums/ThemeType";
import { bdaTheme } from "./bdaTheme";
import { gvtTheme } from "./gvtTheme";

export const themes: { [key: string]: DefaultTheme } = {
  [ThemeType.Tix]: tixYellowTheme,
  [ThemeType.Vliegtickets]: vliegticketsTheme,
  [ThemeType.BDA]: bdaTheme,
  [ThemeType.GVT]: gvtTheme,
};
