const Alert = ({ fill = "#c00051" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15.15" viewBox="0 0 17 15.15">
      <defs>
        <clipPath id="alert-clip-path">
          <rect id="Rectangle_17" data-name="Rectangle 17" width="17" height="15.15" fill={fill} />
        </clipPath>
      </defs>
      <g id="Group_126" data-name="Group 126" clipPath="url(#alert-clip-path)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M15.383,15.149H1.616a1.614,1.614,0,0,1-1.4-2.421L7.1.806a1.614,1.614,0,0,1,2.8,0l6.883,11.922a1.614,1.614,0,0,1-1.4,2.421M8.5.988a.614.614,0,0,0-.541.312L1.075,13.223a.625.625,0,0,0,.541.937H15.383a.625.625,0,0,0,.541-.937L9.041,1.3A.614.614,0,0,0,8.5.988"
          transform="translate(0 0.001)"
          fill={fill}
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M158.677,100.936a.723.723,0,0,1-1.447,0,.723.723,0,0,1,1.447,0m-.121-1.249h-1.184V93.911h1.184Z"
          transform="translate(-149.453 -89.266)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Alert;
