import styled from "styled-components";
import Alert from "../../customIcons/Alert";
import { useTranslation } from "../../i18n";
import NexaTypography from "./NexaTypography";
import { LayoutType } from "../../enums/LayoutType";
import { useMemo } from "react";
import Mk1Typography from "./Mk1Typography";

interface Props {
  error: string;
  layout?: LayoutType;
  options?: object;
}

const ErrorMessage = ({ error, layout, options }: Props) => {
  const { t } = useTranslation();

  const Typography = useMemo(
    () => (layout === LayoutType.External ? Mk1Typography : NexaTypography),
    [layout]
  );

  return (
    <Container>
      <Alert />
      <Typography>{t(error, options)}</Typography>
    </Container>
  );
};

const Container = styled.span.attrs({ className: "error-message" })`
  align-items: center;
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.errorColor};
  box-sizing: border-box;
  color: ${({ theme }) => theme.errorColor};
  display: flex;
  line-height: 16px;
  min-height: 36px;
  padding: 9px;

  svg {
    height: 15px;
    width: 17px;
    margin-right: 9px;
  }
`;

export default ErrorMessage;
