import { WidgetDispatchEvents } from "./../enums/WidgetEvents";
import { dispatchCustomEvent } from "./../services/widgetEventsService";
import { useCallback } from "react";

export function usePushErrorLog() {
  return useCallback(
    (error: Error | any, options?: { traceId?: string; customMessage?: string }) => {
      console.error(error);
      dispatchCustomEvent({
        widgetEvent: WidgetDispatchEvents.WidgetError,
        details: {
          traceId: options?.traceId,
          customMessage: options?.customMessage,
          errorMessage: error.message,
        },
      });
    },
    []
  );
}
