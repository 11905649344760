import { forwardRef, useCallback } from "react";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  icon: JSX.Element;
  label: string | null;
  shouldShowRange: boolean;
  hasError: boolean;
  isPlaceholder: boolean;
  isLastFieldInRow?: boolean;
  onFocus?: () => void;
  onClick?: () => void;
}

const OpeningCalendarButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      isOpen,
      setIsOpen,
      icon,
      label,
      shouldShowRange,
      hasError,
      isPlaceholder,
      isLastFieldInRow,
      onFocus,
      onClick,
    }: Props,
    ref
  ) => {
    const changeState = useCallback(
      (event: React.FocusEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(!isOpen);
      },
      [isOpen, setIsOpen]
    );
    return (
      <ButtonContainer shouldShowRange={shouldShowRange}>
        <Button
          hasError={hasError}
          isPlaceholder={isPlaceholder}
          isLastFieldInRow={isLastFieldInRow}
          ref={ref}
          onFocus={(event) => {
            if (!!onFocus) onFocus();
            changeState(event);
          }}
          onMouseDown={(event) => {
            if (!!onClick) onClick();
            changeState(event);
          }}
          type="button"
        >
          {icon}
          <span>{label}</span>
        </Button>
      </ButtonContainer>
    );
  }
);

const ButtonContainer = styled.div<{ shouldShowRange: boolean }>`
  position: relative;
  width: ${({ shouldShowRange }) => (shouldShowRange ? "calc(50% - 5px)" : "100%")};

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    width: ${({ shouldShowRange }) => (shouldShowRange ? "50%" : "100%")};

    && {
      margin: 0;
    }

    &:not(:last-child)::after {
      border-right: 1px solid ${({ theme }) => theme.defaultColor};
      content: "";
      height: 20px;
      position: absolute;
      right: 1px;
      top: 14px;
    }
  }
`;

const Button = styled.button<{
  hasError?: boolean;
  isPlaceholder: boolean;
  isLastFieldInRow?: boolean;
}>`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border-radius: 100px;
  border: none;
  color: ${({ hasError, isPlaceholder, theme }) =>
    hasError
      ? theme.errorColor
      : isPlaceholder
      ? theme.form.textField.placeholderColor
      : theme.defaultColor};
  cursor: text;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 14px;
  height: 44px;
  padding: 0 20px 0 47px;
  position: relative;
  text-align: left;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    padding: 0 20px 0 50px;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    border-radius: ${({ theme, isLastFieldInRow }) => (isLastFieldInRow ? `0 100px 100px 0` : 0)};
    font-size: 14px;
    height: 48px;
    padding: 0 20px 0 35.5px;
  }

  svg {
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: 10px;
    }
  }
`;

export default OpeningCalendarButton;
