import { ApiAction, ApiActionName } from "./ApiAction";
import { AutocompleteAirport } from "./../../types/Autocomplete";
import qs from "qs";

interface Params {
  query: string;
}

export interface GetAutocompleteAirportsResponse extends Array<AutocompleteAirport> {}

export function getAutocompleteAirports(params: Params): ApiAction {
  return {
    method: "get",
    name: ApiActionName.GetAutocompleteAirports,
    url: `${process.env.REACT_APP_AIRPORTS_AUTOCOMPLETE_API_URL}?${qs.stringify(params)}`,
  };
}
