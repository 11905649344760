import { FormikErrors, FormikTouched } from "formik";
import { useCallback } from "react";
import { CustomDatePickerSource } from "../../../../enums/CustomDatePicker";
import { HotelsSearchParams } from "../../../../types/HotelsSearchParams";
import ExternalLayoutInputDateFields from "../../../CustomDayPicker/ExternalLayout/ExternalLayoutInputDateFields";
import useMedia from "../../../../hooks/useMedia";
import { Breakpoint } from "../../../../enums/Breakpoint";
import { InputKey, useWizardContext } from "../../WizardContext";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";

interface Props {
  errors: FormikErrors<HotelsSearchParams>;
  touched: FormikTouched<HotelsSearchParams>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  values: HotelsSearchParams;
}

const HotelDateField = ({ errors, touched, onChange, values }: Props) => {
  const { inputRefs, isWizard, mobileModalAnimations, setInputRef, stopWizard } =
    useWizardContext();
  const isTablet = useMedia(Breakpoint.OnlyTablet);
  const handleDayClick = useCallback(
    (date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean) => {
      if (source === CustomDatePickerSource.FROM_DATE || isSelectingFirstDay) {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.HotelsFromDateSelected,
          details: { date },
        });
        onChange(
          {
            checkInDate: date,
            checkOutDate: undefined,
          },
          false
        );
      } else {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.HotelsToDateSelected,
          details: { date },
        });
        onChange({ checkOutDate: date });
        if (isWizard) {
          inputRefs.current.roomCount?.click();
        }
      }
    },
    [inputRefs, isWizard, onChange]
  );
  return (
    <ExternalLayoutInputDateFields
      ref={setInputRef(InputKey.CheckInDate)}
      onChange={handleDayClick}
      shouldShowRange={true}
      errors={errors}
      touched={touched}
      values={values}
      fromPlaceholderLabel="tix_search_form_from_date"
      toPlaceholderLabel="tix_search_form_to_date"
      inputTitleLabel="tix_search_form_hotels_calendar_title"
      fromFieldName="checkInDate"
      toFieldName="checkOutDate"
      alignLeftPx={isTablet ? -203 : -5}
      mobileModalAnimations={mobileModalAnimations}
      onMobileModalClose={stopWizard}
      fromMobileTitleLabelKey="tix_search_form_mobile_modal_hotel_calendar_from_title"
      toMobileTitleLabelKey="tix_search_form_mobile_modal_hotel_calendar_to_title"
      isWizard={isWizard}
    />
  );
};

export default HotelDateField;
