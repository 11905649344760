const HotelLocation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.11" height="22" viewBox="0 0 18.111 23">
      <g id="Group_5" data-name="Group 5" transform="translate(0.5 0.5)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M17.111,8.378A8.468,8.468,0,0,0,8.556,0,8.468,8.468,0,0,0,0,8.378C0,13.328,5.009,19.07,7.362,21.494a1.66,1.66,0,0,0,2.387,0C12.1,19.07,17.111,13.328,17.111,8.378Z"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M8.556,4.278A4.278,4.278,0,1,0,4.278,8.556,4.278,4.278,0,0,0,8.556,4.278Z"
          transform="translate(4.278 3.667)"
          fill="none"
          stroke="#11100d"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default HotelLocation;
