import { FC, useCallback, useMemo } from "react";
import styled from "styled-components";
import FlightsTabIcon from "../customIcons/FlightsTabIcon";
import HotelsTabIcon from "../customIcons/HotelsTabIcon";
import { Breakpoint } from "../enums/Breakpoint";
import { LayoutType } from "../enums/LayoutType";
import { SearchFormTabValues } from "../enums/SearchFormTabValues";
import useMedia from "../hooks/useMedia";
import { useTranslation } from "../i18n";
import { WidgetGATriggerEvents } from "../enums/WidgetEvents";
import { dispatchCustomEvent } from "../services/widgetEventsService";
import { SearchWidgetType } from "../enums/SearchWidgetType";

interface Props {
  activeTab?: SearchFormTabValues;
  layout?: LayoutType;
  onChange: (newValue: SearchFormTabValues) => void;
}

const SearchFormTabs: FC<Props> = ({ activeTab, layout, children, onChange }) => {
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);
  const isMaxTablet = useMedia(Breakpoint.MaxTablet);
  const { t } = useTranslation();

  const onTabClick = useCallback(
    (newActiveTab) => {
      const eventToSend =
        newActiveTab === SearchFormTabValues.Flights
          ? WidgetGATriggerEvents.FlightTabSelected
          : WidgetGATriggerEvents.HotelTabSelected;
      dispatchCustomEvent({ widgetEvent: eventToSend });
      onChange(newActiveTab);
    },
    [onChange]
  );

  const flightIconSize = useMemo(() => {
    if (isMaxMobile) {
      return {
        width: 15.21,
        height: 16.21,
      };
    }
    if (isMaxTablet) {
      return {
        width: 21.4,
        height: 22.4,
      };
    }
    return {
      width: 23.35,
      height: 23.07,
    };
  }, [isMaxMobile, isMaxTablet]);

  const hotelIconSize = useMemo(() => {
    if (isMaxMobile) {
      return {
        width: 18.26,
        height: 13.35,
      };
    }
    if (isMaxTablet) {
      return {
        width: 25.23,
        height: 18.44,
      };
    }
    return {
      width: 26,
      height: 19,
    };
  }, [isMaxMobile, isMaxTablet]);

  return (
    <MainContainer>
      <TabsContainer>
        <TabHeader
          layout={layout}
          onClick={() => onTabClick(SearchFormTabValues.Flights)}
          isActive={activeTab === SearchFormTabValues.Flights || !activeTab}
        >
          {layout !== LayoutType.External && (
            <FlightsTabIcon width={flightIconSize.width} height={flightIconSize.height} />
          )}
          <span>{t("tix_search_form_flight_tickets")}</span>
        </TabHeader>
        <TabHeader
          layout={layout}
          onClick={() => onTabClick(SearchFormTabValues.Hotels)}
          isActive={activeTab === SearchFormTabValues.Hotels}
        >
          {layout !== LayoutType.External && (
            <HotelsTabIcon width={hotelIconSize.width} height={hotelIconSize.height} />
          )}
          <span>{t("tix_search_form_hotels")}</span>
        </TabHeader>
      </TabsContainer>
      <ChildrenContainer layout={layout}>{children}</ChildrenContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  margin-bottom: 20px;
  z-index: 1;

  @media ${Breakpoint.MinTablet} {
    margin-bottom: 11px;
  }

  @media ${Breakpoint.MinDesktop} {
    margin-bottom: 40px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
`;

const TabHeader = styled.div<{ isActive: boolean; layout?: LayoutType }>`
  margin-right: 4px;
  cursor: pointer;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.searchFormTabs.default.activeBackgroundColor
      : theme.transparentDefaultBackground};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => `${theme.sharpBorderRadius} ${theme.sharpBorderRadius} 0 0`};

  span {
    padding-left: 8px;
  }

  @media ${Breakpoint.MaxTablet} {
    height: 44px;
    font-size: 14px;

    span {
      padding-left: 10px;
    }
  }

  @media ${Breakpoint.MaxMobile} {
    font-size: 12px;
    padding: 0 10px;

    span {
      padding-left: 5px;
    }

    ${({ theme, isActive }) =>
      !!theme.searchFormTabs.mobile &&
      `background-color: ${
        isActive
          ? theme.searchFormTabs.mobile.activeBackgroundColor
          : theme.transparentDefaultBackground
      };`}
  }

  ${({ layout, theme }) =>
    layout === LayoutType.External &&
    `
    font-size: 13px;
    font-family: ${theme.heavyFont};
    width: 120px;
    padding: 0;

    span {
      padding: 0;
    }
  `}

  @media ${Breakpoint.MinDesktop} {
    ${({ layout }) =>
      layout === LayoutType.External &&
      `
      width: 160px;
      `}
  }
`;

const ChildrenContainer = styled.div<{ layout?: LayoutType }>`
  height: fit-content;
  background: ${({ theme }) => theme.darkBackground};
  min-height: 106px;
  box-sizing: border-box;
  border-radius: ${({ theme }) =>
    `0 ${theme.sharpBorderRadius} ${theme.sharpBorderRadius} ${theme.sharpBorderRadius}`};
  @media ${Breakpoint.MaxTablet} {
    min-height: 181px;
  }
  ${({ layout }) => layout === LayoutType.Tix && ` padding: 20px;`}

  @media ${Breakpoint.MaxMobile} {
    min-height: 258px;
    ${({ layout }) => layout !== LayoutType.External && ` padding: 10px;`}
    ${({ theme }) =>
      !!theme.form.container?.mobileBackgroundColor &&
      `background: ${theme.form.container?.mobileBackgroundColor};`}
  }
`;

export default SearchFormTabs;
