import { startOfDay, endOfDay, addDays } from "date-fns";
import { CabinClassCode } from "../enums/CabinClassCode";

export const CABIN_CLASS_LABELS = {
  [CabinClassCode.Business]: "tix_search_form_class_business",
  [CabinClassCode.Economy]: "tix_search_form_class_economy",
  [CabinClassCode.First]: "tix_search_form_class_first",
  [CabinClassCode.NoPreference]: "tix_search_form_no_preference",
  [CabinClassCode.PremiumEconomy]: "tix_search_form_class_premiumeconomy",
  [CabinClassCode.PremiumFirst]: "tix_search_form_class_premiumfirst",
};

export const ALLOWED_DAYS_IN_FUTURE = 361;

export const DEFAULT_DISABLED_DAYS = {
  before: startOfDay(new Date()),
  after: endOfDay(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE)),
};
