export enum Breakpoint {
  MaxTheSmallesMobile = "(max-width: 420px)",
  MaxTinyMobile = "(max-width: 539px)",
  MaxSmallMobile = "(max-width: 579px)",
  MinMobile = "(min-width: 580px)",
  OnlyMobile = "(min-width: 580px) and (max-width: 767px)",
  MaxMobile = "(max-width: 767px)",
  MinTablet = "(min-width: 768px)",
  OnlyTablet = "(min-width: 768px) and (max-width: 1105px)",
  MinMidTablet = "(min-width: 820px)",
  OnlyMidTablet = "(min-width: 820px) and (max-width: 1105px)",
  MaxTablet = "(max-width: 1105px)",
  MinDesktop = "(min-width: 1106px)",
  OnlyDesktop = "(min-width: 1106px) and (max-width: 1280px)",
  MaxDesktop = "(max-width: 1280px)",
  MinLargeDesktop = "(min-width: 1281px)",
  MinHugeDesktop = "(min-width: 1494px)",
}

export enum ExceptionsBreakpoints {
  HomepageAssuranceMobileLabelChange = "(max-width: 539px)",
}
