import styled from "styled-components";
import CalendarDropdown from "./components/CalendarDropdown";
import CalendarDropdownIndicator from "./components/CalendarDropdownIndicator";
import SingleMonthDayPicker from "../DayPickers/SingleMonthDayPicker/SingleMonthDayPicker";
import { DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";

interface Props {
  alignRight: boolean;
  onDayClick(date: Date): void;
  disabledDays?: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  initialMonth: Date;
}

const SingleMonthDropdown = ({
  alignRight,
  onDayClick,
  disabledDays = DEFAULT_DISABLED_DAYS,
  fromDate,
  toDate,
  shouldShowRange,
  initialMonth,
}: Props) => {
  return (
    <StyledDropdown>
      <StyledIndicator alignRight={alignRight} />
      <SingleMonthDayPicker
        onDayClick={onDayClick}
        disabledDays={disabledDays}
        fromDate={fromDate}
        toDate={toDate}
        shouldShowRange={shouldShowRange}
        initialMonth={initialMonth}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(CalendarDropdown)`
  top: 62px;
  left: calc((100% - 320px) / 2);
  width: 320px;
`;

const StyledIndicator = styled(CalendarDropdownIndicator)<{ alignRight: boolean }>`
  ${({ alignRight }) => (alignRight ? "right" : "left")}: 62px;
  top: -13px;
`;

export default SingleMonthDropdown;
