import { createContext, MutableRefObject } from "react";

interface ContextType {
  searchFormElementRef: MutableRefObject<HTMLDivElement | null> | null;
}

const SearchFormWithFocusScrollContext = createContext<ContextType>({
  searchFormElementRef: null,
});

export default SearchFormWithFocusScrollContext;
