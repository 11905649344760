import { FormEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import Autosuggest, {
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import { AutocompleteValue } from "../types";
import InputComponent from "./InputComponent";
import SuggestionsContainer from "./SuggestionsContainer";
import Suggestion from "./Suggestion";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import { useAirportAutocompleteSuggestions } from "../../../../../hooks/useAirportAutocompleteSuggestions";

const getSuggestionValue = ({ name }: AutocompleteAirport) => name;

interface Props {
  direction: "departure" | "destination";
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompleteAirport, selectedByHand?: boolean): void;
  onSwap?(): void;
  value: AutocompleteValue;
}

const DesktopAutocomplete = forwardRef(
  ({ direction, error, inputLabel, onSelect, onSwap, value }: Props, ref) => {
    const { clearSuggestions, fetchSuggestions, suggestions } = useAirportAutocompleteSuggestions();
    const [innerValue, setInnerValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      setInnerValue(value.name);
    }, [value.name]);

    const inputProps = useMemo<InputProps<any>>(
      () => ({
        autoComplete: "off",
        autoCorrect: "off",
        name: direction,
        onBlur: (event, { highlightedSuggestion }: any) => {
          if (highlightedSuggestion && innerValue) {
            onSelect(highlightedSuggestion);
          }
          setIsFocused(false);
        },
        onChange: (event, { newValue }) => {
          setInnerValue(newValue);
          if (!newValue) {
            onSelect({ code: "", name: "", region: "", type: "" });
          } else if (!!value.code && newValue !== value.name) {
            onSelect({ code: "", name: newValue, region: "", type: "" });
          }
        },
        onFocus: () => {
          setIsFocused(true);
        },
        required: true,
        value: innerValue,
      }),
      [direction, innerValue, onSelect, value]
    );

    const handleSelect = useCallback(
      (event: FormEvent<any>, { suggestion }: SuggestionSelectedEventData<AutocompleteAirport>) => {
        onSelect(suggestion, true);
      },
      [onSelect]
    );

    const fetchSuggestionsRequest = useCallback(
      async ({ value, reason }: SuggestionsFetchRequestedParams) => {
        if (reason !== "input-focused" && value.length >= 3) {
          fetchSuggestions(value);
        }
      },
      [fetchSuggestions]
    );

    // select after blur if fetching was in progress
    useEffect(() => {
      if (!isFocused && suggestions.length > 0 && value.name !== suggestions[0].name) {
        onSelect(suggestions[0]);
      }
    }, [isFocused, onSelect, suggestions, value]);

    const [isFirstHighlighted, setIsFirstHighlighted] = useState(false);
    const handleSuggestionHighlighted = useCallback(
      ({ suggestion }: { suggestion: AutocompleteAirport | null }) => {
        setIsFirstHighlighted(
          !!suggestion && !!suggestions[0] && suggestion.code === suggestions[0].code
        );
      },
      [suggestions]
    );

    return (
      <Autosuggest
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetchSuggestionsRequest}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleSelect}
        onSuggestionHighlighted={handleSuggestionHighlighted}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={Suggestion}
        inputProps={inputProps}
        renderInputComponent={(inputProps: any) => (
          <InputComponent
            {...inputProps}
            direction={direction}
            error={error}
            label={inputLabel}
            placeholder={inputLabel}
            onSwap={onSwap}
            ref={ref}
          />
        )}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <SuggestionsContainer
            containerProps={containerProps}
            isFirstHighlighted={isFirstHighlighted}
            query={query}
          >
            {children}
          </SuggestionsContainer>
        )}
      />
    );
  }
);

export default DesktopAutocomplete;
